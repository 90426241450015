import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import config from '../../../../../config';
import {
  currencyFormatter, formatDecimalToFixedTwoPercent,
} from '../../../../../shared/components/table/functions';
import { doFetch } from '../../../../../helpers';
import PpcReportGrid from './PpcReportGrid';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

const itemColumnDefs = [
  {
    field: 'itemId',
    headerName: 'Item ID',
    enableRowGroup: true,
  },
  {
    field: 'brand',
    headerName: 'Brand',
  },
  {
    field: 'product_name',
    headerName: 'Product Name',
  },
];

const brandColumnDefs = [
  {
    field: 'brand',
    headerName: 'Brand',
  },
];

const statsColumnDefs = [
  {
    field: 'noof_ad_groups',
    headerName: '# Of Ad Groups',
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
  },
  {
    field: 'spend',
    headerName: 'PPC Spend',
    valueFormatter: currencyFormatter,
  },
  {
    field: 'sales',
    headerName: 'PPC Sales',
    valueFormatter: currencyFormatter,
  },
  {
    field: 'orders',
    headerName: 'Orders',
  },
  {
    field: 'units',
    headerName: 'Units',
  },
  {
    field: 'acos',
    headerName: 'PPC ACoS',
    valueFormatter: formatDecimalToFixedTwoPercent,
  },
  {
    field: 'ctr',
    headerName: 'CTR',
    valueFormatter: formatDecimalToFixedTwoPercent,
    headerTooltip: 'Click-through rate',
  },
  {
    field: 'cpc',
    headerName: 'CPC',
    valueFormatter: currencyFormatter,
    headerTooltip: 'Cost per-click',
  },
  {
    field: 'cvr',
    headerName: 'CVR',
    valueFormatter: formatDecimalToFixedTwoPercent,
    headerTooltip: 'Conversion rate',
  },
  {
    field: 'roas',
    headerName: 'PPC ROAS',
    valueFormatter: currencyFormatter,
  },
  {
    field: 'spot',
    headerName: 'Spend % of total',
    valueFormatter: formatDecimalToFixedTwoPercent,
  },
];

const segmentsColumnDefs = [
  {
    field: 'segment_one',
    headerName: 'Segment 1',
  },
  {
    field: 'segment_two',
    headerName: 'Segment 2',
  },
  {
    field: 'segment_three',
    headerName: 'Segment 3',
  },
  {
    field: 'segment_four',
    headerName: 'Segment 4',
  },
  {
    field: 'segment_five',
    headerName: 'Segment 5',
  },
];

const defaultColDef = {
  sortable: true,
  filter: false,
  resizable: true,
  aggFunc: 'none',
  cellStyle: (params) => {
    if (params.value < 0.00) {
      return { color: 'red' };
    }
    return null;
  },
};

const pivotButtons = [
  { buttonName: 'Item ID', buttonAction: 'itemId' },
  { buttonName: 'Brand', buttonAction: 'brand' },
  { buttonName: 'Segment 1', buttonAction: 'segment_one' },
  { buttonName: 'Segment 2', buttonAction: 'segment_two' },
  { buttonName: 'Segment 3', buttonAction: 'segment_three' },
  { buttonName: 'Segment 4', buttonAction: 'segment_four' },
  { buttonName: 'Segment 5', buttonAction: 'segment_five' },
];

function createServer({
  sellerSelect, user, startDate, endDate, pageSize, pivotName,
}) {
  return {
    getData: async (request) => {
      const apiEndPoint = `${apiUrl}/walmart/ppc-report`;
      const { startRow, sortModel } = request;
      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };

      let sort = '';
      if (sortModel && sortModel.length > 0) {
        sort = `${sortModel[0]?.colId} ${sortModel[0]?.sort}`;
      }

      const url = `${apiEndPoint}?accountId=${sellerSelect.value.toString()}&startDate=${moment(startDate).format('YYYYMMDD')}&endDate=${moment(endDate).format('YYYYMMDD')}&limit=${pageSize}&offset=${startRow}&pivot=${pivotName}&sort=${sort}`;
      const data = await doFetch(url, requestOptions);
      return data;
    },
  };
}

function createDatasource(server) {
  return {
    getRows: async (params) => {
      const {
        request, successCallback: success, failCallback: fail,
      } = params;
      try {
        const response = await server.getData(request);
        success(response.results, response.rowCount);
      } catch (error) {
        fail();
      }
    },
  };
}

export default function WmPpcReportMain() {
  const user = useSelector(state => state.authentication.user);
  const sellerSelect = useSelector(state => state.sellerSelect);
  const [reportStartDate, setReportStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [reportEndDate, setReportEndDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const [pivotName, setPivotName] = useState('itemId');
  const [dataSource, setDataSource] = useState(null);
  const paginationPageSize = 10000;

  useEffect(() => {
    const server = createServer({
      sellerSelect, user, startDate: reportStartDate, endDate: reportEndDate, pageSize: paginationPageSize, pivotName,
    });
    setDataSource(createDatasource(server));
  }, [sellerSelect, user, reportStartDate, reportEndDate, paginationPageSize, pivotName]);

  let columnDefs = [];
  switch (pivotName) {
    case 'itemId':
      columnDefs = itemColumnDefs.concat(statsColumnDefs, segmentsColumnDefs);
      break;
    case 'brand':
      columnDefs = brandColumnDefs.concat(statsColumnDefs);
      break;
    default:
      columnDefs = [segmentsColumnDefs.find(obj => obj.field === pivotName)].concat(statsColumnDefs);
  }

  function onDateChange(startDate, endDate) {
    setReportStartDate(moment(startDate).format('YYYY-MM-DD'));
    setReportEndDate(moment(endDate).format('YYYY-MM-DD'));
  }

  return (
    <PpcReportGrid
      gridInfo={{
        columnDefs,
        defaultColDef,
        dataSource,
        paginationPageSize,
      }}
      setPivotName={setPivotName}
      datePickerInfo={{
        inputStart: moment(reportStartDate).toDate(),
        inputEnd: moment(reportEndDate).toDate(),
        onChange: onDateChange,
      }}
      pivotButtons={pivotButtons}
    />
  );
}
