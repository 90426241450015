import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DashboardContextProvider, useDashboardContext } from './components/DashboardContext';
import VendorDashboard from './indexAmzonVendor';
import SellerDashboard from './indexAmzonSeller';
import SystemDashboard from './indexSystem';
import NoAccessDashboard from './indexNoAccess';
import SubscriptionDashboard from '../Subscription/DashboardPage';
import SellerProfitReport from '../Profits/index';
import { isAdRev } from '../../../shared/components/domainSupport';

function AccountDashboard() {
  const selectedSeller = useSelector(store => store.sellerSelect);
  const { state, actions } = useDashboardContext();
  const { isVendor } = state;
  const { setAccount } = actions;

  useEffect(() => {
    if (selectedSeller && Object.keys(selectedSeller).length > 0 && selectedSeller.value > 0) {
      setAccount(selectedSeller);
    }
  }, [selectedSeller]);

  /* eslint-disable-next-line */
  let Dashboard = <></>;
  if (selectedSeller.value > 0) {
    if (isAdRev()) {
      Dashboard = SubscriptionDashboard;
    } else if (selectedSeller.type === 0) {
      Dashboard = SystemDashboard;
    } else if (selectedSeller.parentAccountId === 514) {
      Dashboard = SellerProfitReport;
    } else if (isVendor) {
      Dashboard = VendorDashboard;
    } else if (!isVendor) {
      Dashboard = SellerDashboard;
    }
  } else {
    Dashboard = NoAccessDashboard;
  }

  return (
    <Dashboard />
  );
}

export default function AccountDashboardWithContext() {
  return (
    <DashboardContextProvider>
      <AccountDashboard />
    </DashboardContextProvider>
  );
}
