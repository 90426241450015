import React, { PureComponent, useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import PropTypes from 'prop-types';
import NotificationSystem from 'rc-notification';
import moment from 'moment';
import Picker from 'rsuite/DatePicker'
import lodash from 'lodash'
import { BasicNotification } from '../../../../../shared/components/Notification';
import config from '../../../../../config';
import { SellerSelect, User } from '../../../../../shared/prop-types/MainProps';
import renderSelectField from '../../../../../shared/components/form/Select';
import AddSettingPanel from './AddSettingPanel';
import {
  typeOptions,
  statusOptions,
  editRoleOptions,
  viewRoleOptions,
  apiStatusOptions,
  acctStatusOptions,
  findOption,
  fetchGroupOptions,
} from './utils';
import GroupSettingsPanel from './GroupSettingsPanel';
import MainSettingsPanel from './MainSettingsPanel';
import AccountAccessPanel from './AccountAccessPanel';

let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

// eslint-disable-next-line
const renderTextArea = ({ input, meta: { touched, error, warning } }) => (
  <div style={{ width: '100%' }}>
    <label htmlFor="account_settings_form" style={{ width: '100%' }}>
      <div style={{ width: '100%' }}>
        <textarea
          {...input}
          rows="10"
          style={{
            width: '100%',
          }}
        />
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </label>
  </div>
);

// eslint-disable-next-line
const renderDatePicker = ({ input, meta: { touched, error, warning } }) => {
  // eslint-disable-next-line
  const [pickerId, setPickerId] = useState(lodash.uniqueId('date-picker-'));
  return (
    <div style={{ position: 'absolute' }}>
      <div id={pickerId}>
        <Picker
          name={input.name}
          value={input.value ? moment(input.value).toDate() : null}
          onChange={date => { input.onChange(date ? moment(date).format('YYYY-MM-DD') : null) }}
          container={document.getElementById(pickerId)}
          oneTap
        />
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
};

class AccountSettingsPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    initialize: PropTypes.func.isRequired,
    formData: PropTypes.instanceOf(Object).isRequired,
  }

  constructor() {
    super();
    this.toggle = this.toggle.bind(this);
    this.state = {
      apiData: [],
      firstClassApiData: [],
      accountAcessData: [],
      initialLoad: false,
      activeTab: 'Default',
      groupArr: [],
      initialValues: {
        newKey: '',
        newValue: '',
        newType: findOption('string', typeOptions),
        newStatus: findOption(true, statusOptions),
        newEditRole: findOption('actmgr', editRoleOptions),
        newViewRole: findOption('actmgr', viewRoleOptions),
        newGroup: {},
      },
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const { initialValues } = this.state;

    // This IIFE needs to happen before the call to this.getData()
    // because we need things set in state prior to initilizing the form
    // data.  This prevents rendering oddities.
    (async () => {
      const groups = await fetchGroupOptions(user);
      const newGroup = findOption('', groups);
      this.setState({
        groupArr: groups,
        initialValues: {
          ...initialValues,
          newGroup,
        },
      });
    })();

    this.getData();
    this.getFirstClassData();
    this.getAccountAccessData();
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationLU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationRU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationTC = n);    
  }

  componentDidUpdate(prevState) {
    const { user, sellerSelect } = this.props;
    const { initialValues } = this.state;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      // This IIFE needs to happen before the call to this.getData()
      // because we need things set in state prior to initilizing the form
      // data.  This prevents rendering oddities.
      (async () => {
        const groups = await fetchGroupOptions(user);
        const newGroup = findOption('', groups);
        this.setState({
          groupArr: groups,
          initialValues: {
            ...initialValues,
            newGroup,
          },
        });
      })();

      this.getData();
      this.getFirstClassData();
      this.getAccountAccessData();
    }
  }

  componentWillUnmount() {
    if (notificationLU) {
      notificationLU.destroy();
    }
    if (notificationLU) {
      notificationRU.destroy();
    }
    if (notificationLU) {
      notificationTC.destroy();
    }
  }

  // eslint-disable-next-line
  show = (color, title, msg) => {
    return config.showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={msg}
      />,
      position: 'right-up',
      notificationLU,
      notificationRU,
      notificationTC,
    });
  };

  dynamicValueField = (name, rowId) => {
    const { formData } = this.props;
    const type = formData ? (formData[`${rowId}Type`] || '').value || 'String' : 'String';

    if (type.toLowerCase() === 'textarea') {
      return (
        <Field
          name={name}
          component={renderTextArea}
        />
      );
    }

    if (type.toLowerCase() === 'number') {
      const numberFormat = value => value.replace(/[^\d.-]/g, '');

      return (
        <Field
          name={name}
          component="input"
          normalize={numberFormat}
        />
      );
    }

    if (type.toLowerCase() === 'date') {
      return (
        <Field
          style={{ zIndex: '80' }}
          name={name}
          component={renderDatePicker}
        />
      );
    }

    // default
    return (
      <Field
        name={name}
        component="input"
      />
    );
  };

  copyAccountSettings = () => {
    const { user, sellerSelect } = this.props;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const postUrl = `${config.serverUrl}/accounts/account-setting/copy`;

    if (sellerSelect.value > 0) {
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountId: sellerSelect.value.toString(),
        }),
      };

      fetch(postUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then(() => {
          this.show('primary', 'Success', 'Account Setting have been copied to this Account.');
          this.getData();
        }).catch((err) => {
          this.show('danger', 'Error', `Unable to copy Account Settings. Reason: ${err}`);
        });
    } else {
      this.show('danger', 'Error', 'Unable to copy Account Settings. Reason: account ID not found.');
    }
  }

  activateSqsSubscriptions = () => {
    const { user, sellerSelect } = this.props;
    this.show('warning', 'Initializing', 'Requesting SQS Subscriptions.');

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const postUrl = `${config.serverUrl}/accounts/activate-sqs-subs`;

    if (sellerSelect.value > 0) {
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountId: sellerSelect.value.toString(),
        }),
      };

      fetch(postUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((response) => {
          const datasetIds = response.map(obj => `\n\n ${obj.dataset_id.toString()} (${obj.request_status})`);
          this.show('primary', 'Success', `Requested SQS Subscriptions. ${datasetIds.toString()}`);
        }).catch((err) => {
          this.show('danger', 'Error', `Unable to reqeust SQS Subscriptions. Reason: ${err}`);
        });
    } else {
      this.show('danger', 'Error', 'Unable to reqeust SQS Subscriptions. Reason: account ID not found.');
    }
  }


  getData = () => {
    const { user, sellerSelect } = this.props;

    const apiEndPoint = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const getUrl = `${apiEndPoint}/accounts/setting?accountId=${sellerSelect.value.toString()}`;

    if (sellerSelect.value > 0) {
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };
      this.setState({
        initialLoad: true,
        apiData: [],
      });

      fetch(getUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          this.setState({
            initialLoad: false,
            apiData: data,
          });
          this.setLoad(data);
          return data;
        }).catch(() => {
          this.setState({
            initialLoad: false,
          });
        });
    }
  };

  getFirstClassData = () => {
    const { user, sellerSelect } = this.props;

    const apiEndPoint = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const getUrl = `${apiEndPoint}/accounts/${sellerSelect.value.toString()}`;

    if (sellerSelect.value > 0) {
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };
      this.setState({
        initialLoad: true,
        firstClassApiData: [],
      });

      fetch(getUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          const settingsNameValue = Object.keys(data[0]).map(key => ({ key, value: data[0][key] }));
          this.setState({
            initialLoad: false,
            firstClassApiData: settingsNameValue,
          });
        }).catch(() => {
          this.setState({
            initialLoad: false,
          });
        });
    }
  };

  getAccountAccessData = () => {
    const { user, sellerSelect } = this.props;

    const apiEndPoint = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const getUrl = `${apiEndPoint}/accounts/active-users-access?accountId=${sellerSelect.value.toString()}`;

    if (sellerSelect.value > 0) {
      const requestOptions = {
        method: 'GET',
        headers: headerWithAuth,
      };
      this.setState({
        initialLoad: true,
        accountAcessData: [],
      });

      fetch(getUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then((data) => {
          this.setState({
            initialLoad: false,
            accountAcessData: data,
          });
        }).catch(() => {
          this.setState({
            initialLoad: false,
          });
        });
    }
  };

  resetRow = (rowId) => {
    const { apiData, groupArr } = this.state;
    const { formData } = this.props;
    if (!rowId || rowId === 0) {
      formData.newKey = '';
      formData.newValue = '';
      formData.newType = findOption('string', typeOptions);
      formData.newStatus = findOption(true, statusOptions);
      formData.newEditRole = findOption('actmgr', editRoleOptions);
      formData.newViewRole = findOption('actmgr', viewRoleOptions);
      formData.newGroup = findOption('', groupArr);
    } else {
      const origRow = apiData.find(item => item.account_setting_id === rowId);
      if (origRow) {
        formData[`${rowId}Key`] = origRow.setting_key;
        formData[`${rowId}Value`] = origRow.setting_value;
        formData[`${rowId}Type`] = findOption(origRow.setting_type, typeOptions);
        formData[`${rowId}Status`] = findOption(origRow.setting_status, statusOptions);
        formData[`${rowId}EditRole`] = findOption(origRow.edit_role, editRoleOptions);
        formData[`${rowId}ViewRole`] = findOption(origRow.view_role, viewRoleOptions);
        formData[`${rowId}Group`] = findOption(origRow.setting_group, groupArr);
      }
    }
    const { initialize } = this.props;
    initialize({
      ...formData,
    });
  }

  firstClassDropdown = (line, options) => {
    const { formData } = this.props;
    if (formData && formData[line.key] === undefined) {
      formData[line.key] = findOption(line.value.toString(), options);
      const { initialize } = this.props;
      initialize({
        ...formData,
      });
    }
  }

  udpateFirstClassStatus = () => {
    const { user, sellerSelect } = this.props;
    const { formData } = this.props;
    const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;
    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };
    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify({
        amzAdApiStatus: formData.amz_ad_api_status.value.toString(),
        amzMwsApiStatus: formData.amz_mws_api_status.value.toString(),
        amzSpApiStatus: formData.amz_sp_api_status.value.toString(),
        status: formData.status.value.toString(),
        accountId: sellerSelect.value.toString(),
      }),
    };

    // Call via API (would like to update to " await ")
    fetch(`${apiUrl}/accounts/status`, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then(() => {
        // success message
        // eslint-disable-next-line
        this.show('primary', 'Success', `Account ${sellerSelect.value.toString()} Updated Status.`);
      }).catch(() => {
        this.show('danger', 'Error', `Account ${sellerSelect.value.toString()} was not saved.`);
      });
  }

  deleteRow = (rowId, settingKey) => {
    // eslint-disable-next-line
    const result = confirm(`You want to delete setting ${rowId} - ${settingKey}?`);
    if (result) {
      const { user, sellerSelect } = this.props;

      const apiEndPoint = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

      const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

      const deleteUrl = `${apiEndPoint}/accounts/setting?accountId=${sellerSelect.value.toString()}&accountSettingId=${rowId}`;

      if (sellerSelect.value > 0) {
        const requestOptions = {
          method: 'DELETE',
          headers: headerWithAuth,
        };

        fetch(deleteUrl, requestOptions)
          .then((results) => {
            if (!results.ok) {
              throw Error(results.statusText);
            }
            return results.json();
          }).then(() => {
            this.show('primary', 'Success', `Setting "${settingKey}" has been removed.`);
            this.getData();
          }).catch((err) => {
            this.show('danger', 'Error', `Setting was not removed. Reason: ${err}`);
          });
      } else {
        this.show('danger', 'Error', 'Setting was not removed. Reason: account ID not found.');
      }
    }
  }

  setLoad = (data) => {
    const dynamicData = {};
    const { initialValues, groupArr } = this.state;
    data.forEach((row) => {
      dynamicData[`${row.account_setting_id}Key`] = row.setting_key;
      dynamicData[`${row.account_setting_id}Value`] = row.setting_value;
      dynamicData[`${row.account_setting_id}Type`] = findOption(row.setting_type, typeOptions);
      dynamicData[`${row.account_setting_id}Status`] = findOption(row.setting_status, statusOptions);
      dynamicData[`${row.account_setting_id}EditRole`] = findOption(row.edit_role, editRoleOptions);
      dynamicData[`${row.account_setting_id}ViewRole`] = findOption(row.view_role, viewRoleOptions);
      dynamicData[`${row.account_setting_id}Group`] = findOption(row.setting_group, groupArr);
    });

    const { initialize } = this.props;
    initialize({
      ...initialValues,
      ...dynamicData,
    });
  }

  createUpdate = (rowId) => {
    const { formData } = this.props;
    const { user, sellerSelect } = this.props;

    const apiEndPoint = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const postUrl = `${apiEndPoint}/accounts/setting`;

    const rowName = rowId === 0 ? 'new' : rowId.toString();

    if (sellerSelect.value > 0) {
      const requestOptions = {
        method: 'POST',
        headers: headerWithAuth,
        body: JSON.stringify({
          accountSettingId: rowId.toString(), // 0 = New
          accountId: sellerSelect.value.toString(),
          settingKey: formData[`${rowName}Key`].toString(),
          settingValue: formData[`${rowName}Value`].toString(),
          settingType: formData[`${rowName}Type`].value.toString(),
          settingStatus: formData[`${rowName}Status`].value.toString(),
          editRole: formData[`${rowName}EditRole`].value.toString(),
          viewRole: formData[`${rowName}ViewRole`].value.toString(),
          settingGroup: formData[`${rowName}Group`].value.toString(),
        }),
      };

      fetch(postUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw Error(results.statusText);
          }
          return results.json();
        }).then(() => {
          if (rowId > 0) {
            this.show('primary', 'Success', `Setting ${formData.newKey.toString()} has been updated.`);
          } else {
            this.show('primary', 'Success', `Setting ${formData.newKey.toString()} added to ${sellerSelect.value.toString()}.`);
          }
          this.getData();
        }).catch((err) => {
          this.show('danger', 'Error', `Setting "${formData.newKey.toString()}" was not saved. Reason: ${err}`);
        });
    } else {
      this.show('danger', 'Error', `Setting "${formData.newKey.toString()}" was not saved. Reason: account ID not found.`);
    }
  }

  lineEditor = (line) => {
    if (line.key === 'amz_ad_api_status' || line.key === 'amz_mws_api_status' || line.key === 'amz_sp_api_status') {
      this.firstClassDropdown(line, apiStatusOptions);
      return (
        <tr key={line.key}>
          <td>{line.key}</td>
          <td>
            <Field
              name={line.key}
              component={renderSelectField}
              type="text"
              options={apiStatusOptions}
            />
          </td>
        </tr>
      );
    }

    if (line.key === 'status') {
      this.firstClassDropdown(line, acctStatusOptions);
      return (
        <tr key={line.key}>
          <td>{line.key}</td>
          <td>
            <Field
              name={line.key}
              component={renderSelectField}
              type="text"
              options={acctStatusOptions}
            />
          </td>
        </tr>
      );
    }

    return (
      <tr key={line.key}>
        <td>{line.key}</td>
        <td>{line.value}</td>
      </tr>
    );
  }

  accountAccessLine = line => (
    <tr key={line.id}>
      <td>{line.first_name}</td>
      <td>{line.last_name}</td>
      <td>{line.email}</td>
      <td>{line.is_active === 1 ? 'Active' : 'Inactive'}</td>
      <td>{line.login_seven_day}</td>
      <td>{line.access_at}</td>
    </tr>
  );

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      apiData, firstClassApiData, accountAcessData, initialLoad, activeTab, groupArr,
    } = this.state;
    return (
      <>
        <AddSettingPanel
          initialLoad={initialLoad}
          getData={this.getData}
          dynamicValueField={this.dynamicValueField}
          groups={groupArr}
          onCreate={rowId => this.createUpdate(rowId)}
          onReset={rowId => this.resetRow(rowId)}
        />
        <GroupSettingsPanel
          initialLoad={initialLoad}
          getData={this.getData}
          dynamicValueField={this.dynamicValueField}
          onCreate={rowId => this.createUpdate(rowId)}
          onReset={rowId => this.resetRow(rowId)}
          onDelete={(rowId, settingKey) => this.deleteRow(rowId, settingKey)}
          onToggle={tab => this.toggle(tab)}
          groups={groupArr}
          activeTab={activeTab}
          apiData={apiData}
          copyAccountSettings={this.copyAccountSettings}
          activateSqsSubscriptions={this.activateSqsSubscriptions}
        />
        <MainSettingsPanel
          initialLoad={initialLoad}
          getData={this.getFirstClassData}
          mapLine={line => this.lineEditor(line)}
          onUpdate={() => this.udpateFirstClassStatus()}
          apiData={firstClassApiData}
        />
        <AccountAccessPanel
          initialLoad={initialLoad}
          getData={this.getAccountAccessData}
          mapLine={line => this.accountAccessLine(line)}
          apiData={accountAcessData}
        />
      </>
    );
  }
}

const accountSettingsPage = reduxForm({
  form: 'account_settings_form',
})(AccountSettingsPage);


const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;
  const formData = getFormValues('account_settings_form')(state);

  return {
    sellerSelect,
    user,
    formData,
  };
};

export default connect(mapStateToProps)(accountSettingsPage);
