import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import md5 from 'md5';
import ProfileActivity from './ProfileActivity';
import config from '../../../../config';
import { User } from '../../../../shared/prop-types/MainProps';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class ProfileActivities extends PureComponent {
  static propTypes = {
    user: User.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      activitiesResponse: 'Loading Activities...',
      refreshColor: '#E87B50',
    };
  }

  componentDidMount() {
    this.getActivity();
  }

  getActivity = () => {
    this.setState({
      refreshColor: '#C3485F',
    });


    // eslint-disable-next-line
    const { user } = this.props;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const getUrl = `${apiUrl}/events/activity/default`;

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    let activitiesResponse = 'No Activities Found.';

    // Call via API (would like to update to " await ")
    fetch(getUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const arr = [];
        Object.keys(data).forEach((key) => {
          arr.push(data[key]);
        });

        function getGravatarHash(userEmail) {
          return `https://www.gravatar.com/avatar/${md5(userEmail)}`;
        }

        activitiesResponse = <div>{arr.map(item => <ProfileActivity img={getGravatarHash(item.user_email)} date={moment(item.activity_date).format('MMMM Do YYYY, h:mm A')} name={item.user_name}><p>{item.message}</p></ProfileActivity>)}</div>;
        this.setState({
          activitiesResponse,
          refreshColor: '#E87B50',
        });
      }).catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { activitiesResponse, refreshColor } = this.state;
    const asText = {
      background: 'none',
      border: 'none',
      margin: '0',
      padding: '0',
      cursor: 'pointer',
      color: refreshColor,
    };
    return (
      <p>
        Last 7 days: <button type="button" className="lnr lnr-sync" style={asText} onClick={this.getActivity}> Refresh</button><br />
        {activitiesResponse}
      </p>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;

  return {
    user,
  };
};

export default connect(mapStateToProps)(ProfileActivities);
