import { useFetchReturnsArray as useFetch } from './useFetch';
import config from '../../../configWrapper';
import { DateViewOptions } from '../widgets/DateViewFilter';

function formatUrl(account, start, end, marketplace, view) {
  if (account && account.value && start && end && marketplace && view) {
    const url = `${config.serverUrl}/dashboard/sales-combo?sellerAccountId=${account.value}&parentAccountId=${account.parentAccountId}&startDate=${start.format('YYYYMMDD')}&endDate=${end.format('YYYYMMDD')}&currencyCode=${(account.currencyCode || 'USD')}&accountType=${account.type}&marketPlace=${(marketplace || 'amazon.com')}&dataGroup=${(view.value || DateViewOptions.DAY.value)}`;
    return url;
  }
  return undefined;
}

export default function useSalesComboData(account, start, end, marketplace, view) {
  return useFetch(formatUrl(account, start, end, marketplace, view));
}
