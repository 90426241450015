import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  currencyFormatter, formatNumberDefaultToZero, formatDecimalToFixedTwoPercent, numberTwoPlaces, formatNumberOnePlace,
} from '../../../../shared/components/table/functions';

class AccountViewTable extends PureComponent {
  constructor() {
    super();
    this.state = {
      columnDefs: [
        {
          field: 'account_id',
          headerName: 'Account ID',
        },
        {
          field: 'seller_account_name',
          headerName: 'Account Name',
        },
        {
          field: 'marketplace_currency_code',
          headerName: 'Currency Code',
        },
        {
          field: 'rate',
          headerName: 'FX Rate',
        },
        {
          field: 'orderCharges',
          headerName: 'Order Charges',
          valueFormatter: currencyFormatter,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            footerValueGetter: params => `$${numberTwoPlaces(params)}`,
          },
          aggFunc: 'sum',
        },
        {
          field: 'orderedRevenue',
          headerName: 'Order Revenue',
          valueFormatter: currencyFormatter,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            footerValueGetter: params => `$${numberTwoPlaces(params)}`,
          },
          aggFunc: 'sum',
        },
        {
          field: 'impressions',
          headerName: 'Impressions',
          valueFormatter: formatNumberDefaultToZero,
          aggFunc: 'sum',
        },
        {
          field: 'clicks',
          headerName: 'Clicks',
          valueFormatter: formatNumberDefaultToZero,
          aggFunc: 'sum',
        },
        {
          field: 'spend',
          headerName: 'Spend',
          valueFormatter: currencyFormatter,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            footerValueGetter: params => `$${numberTwoPlaces(params)}`,
          },
          aggFunc: 'sum',
        },
        {
          field: 'sales',
          headerName: 'Sales',
          valueFormatter: currencyFormatter,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            footerValueGetter: params => `$${numberTwoPlaces(params)}`,
          },
          aggFunc: 'sum',
        },
        {
          field: 'acos',
          headerName: 'ACoS',
          valueFormatter: (params) => {
            if (params) {
              return formatDecimalToFixedTwoPercent(params);
            }
            return '';
          },
        },
        {
          field: 'roas',
          headerName: 'ROAS',
          valueFormatter: (params) => {
            if (params && params.value) {
              return formatNumberOnePlace(params.value);
            }
            return '';
          },
        },
        {
          field: 'ctr',
          headerName: 'CTR',
          valueFormatter: (params) => {
            if (params) {
              return formatDecimalToFixedTwoPercent(params);
            }
            return '';
          },
        },
        {
          field: 'cpc',
          headerName: 'CPC',
          valueFormatter: (params) => {
            if (params) {
              return formatDecimalToFixedTwoPercent(params);
            }
            return '';
          },
        },
        {
          field: 'units',
          headerName: 'Units',
          valueFormatter: formatNumberDefaultToZero,
          aggFunc: 'sum',
        },
        {
          field: 'orders',
          headerName: 'Orders',
          valueFormatter: formatNumberDefaultToZero,
          aggFunc: 'sum',
        },
        {
          field: 'avg_ac_ct_all',
          headerName: 'Avg AC CT All',
          valueFormatter: (params) => {
            if (params) {
              return numberTwoPlaces(params);
            }
            return '';
          },
        },
        {
          field: 'avg_ac_ct_cvr',
          headerName: 'Avg AC CT CVR',
          valueFormatter: (params) => {
            if (params) {
              return numberTwoPlaces(params);
            }
            return '';
          },
        },
        {
          field: 'client_tier',
          headerName: 'Client Tier',
        },
        {
          field: 'agency',
          headerName: 'Agency',
        },
        {
          field: 'customer_id',
          headerName: 'Customer ID',
        },
        {
          field: 'customer_name',
          headerName: 'Customer Name',
        },
        {
          field: 'seller_account_type_name',
          headerName: 'Account Type',
        },
        {
          field: 'marketplace_name',
          headerName: 'Marketplace',
        },
        {
          field: 'trial_start_date',
          headerName: 'Trial Start Date',
        },
        {
          field: 'target_ad_kpi',
          headerName: 'Target Ad KPI',
        },
        {
          field: 'target_cost_driver',
          headerName: 'Target Cost Driver',
        },
        {
          field: 'target_cost_value',
          headerName: 'Target Cost Value',
        },
        {
          field: 'status',
          headerName: 'Status',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            values: ['active', 'active hold', 'active data', 'active terminated', 'dead', 'test'],
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showDatePicker: true,
      showFooter: true,
      enableRangeSelection: false,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker, showFooter, enableRangeSelection,
    } = this.state;

    const apiEndPoint = `${config.serverUrl}/accounts/view`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        showFooter={showFooter}
        enableRangeSelection={enableRangeSelection}
        defaultStartDays={1}
        confirmLogic
      />
    );
  }
}

export default AccountViewTable;
