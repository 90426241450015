import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Container, Row, Col,
} from 'reactstrap';
import 'ag-grid-enterprise/chartsModule';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import config from '../../../../../configWrapper';
import { columnDefs, defaultColumnDefs, simpleColumnDefs } from './vendor_columns';
import CardPanel from '../../../../../shared/components/widgets/CardPanel';
import DateRangePicker from '../../../../../shared/components/widgets/DateRangePicker';
import PortfolioFilter, { ALL_PORTFOLIOS } from '../../../../../shared/components/widgets/PortfolioFilter';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';
import { imageCellRendererComponent } from '../../../../../shared/components/table/ImageCellRenderer';
import { isAdRev } from '../../../../../shared/components/domainSupport';

function buildQueryParams(sellerSelect, start, end, portfolio) {
  let result = '';
  if (sellerSelect) {
    result = `sellerAccountId=${sellerSelect.value.toString()}&parentAccountId=${sellerSelect.parentAccountId}&currencyCode=${(sellerSelect.currencyCode || 'USD')}&accountType=${sellerSelect.type}`;
  }
  if (start) {
    result = `${result}&startDate=${start.format('YYYY-MM-DD')}`;
  }
  if (end) {
    result = `${result}&endDate=${end.format('YYYY-MM-DD')}`;
  }
  if (portfolio) {
    result = `${result}&portfolioFilter=${portfolio.value.toString()}`;
  }
  return result;
}

export default function VendorPpcReportMain() {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'days'));
  const [portfolio, setPortfolio] = useState(ALL_PORTFOLIOS);
  const apiEndPoint = `${config.serverUrl}/vendors/ppc-report`;
  const apiQueryParams = useCallback(buildQueryParams(sellerSelect, startDate, endDate, portfolio), [sellerSelect, startDate, endDate, portfolio]);
  const pivotButtons = [
    { buttonName: 'ASIN', buttonAction: '' },
    { buttonName: 'Parent ASIN', buttonAction: 'parent_asin' },
    { buttonName: 'Brand', buttonAction: 'brand' },
    { buttonName: 'Category', buttonAction: 'category' },
    { buttonName: 'Subcategory', buttonAction: 'subcategory' },
    { buttonName: 'Segment 1', buttonAction: 'segment_one' },
    { buttonName: 'Segment 2', buttonAction: 'segment_two' },
    { buttonName: 'Segment 3', buttonAction: 'segment_three' },
    { buttonName: 'Segment 4', buttonAction: 'segment_four' },
    { buttonName: 'Segment 5', buttonAction: 'segment_five' },
  ];
  const simplePivotButtons = [
    { buttonName: 'ASIN', buttonAction: '' },
    { buttonName: 'Brand', buttonAction: 'brand' },
  ];
  const frameworkComponents = {
    ...imageCellRendererComponent,
  };

  function portfolioChange(obj) {
    setPortfolio(obj);
  }

  function dateChange(start, end) {
    setStartDate(moment(start));
    setEndDate(moment(end));
  }

  function onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  function getGroupRowAgg(params) {
    const result = {
      number_of_campaigns: 0,
      impressions: 0,
      clicks: 0,
      spend: 0,
      sales: 0,
      orders: 0,
      units: 0,
      aov: 0,
      CALCPacos: 0,
      CALCCpc: 0,
      CR: 0,
      roas: 0,
      CALCSpendPercentOfTotal: 0,
    };
    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data;
      if (typeof data.number_of_campaigns === 'number') {
        result.number_of_campaigns += data.number_of_campaigns;
      }
      if (typeof data.sales === 'number') {
        result.sales += data.sales;
      }
      if (typeof data.spend === 'number') {
        result.spend += data.spend;
      }
      if (typeof data.clicks === 'number') {
        result.clicks += data.clicks;
      }
      if (typeof data.impressions === 'number') {
        result.impressions += data.impressions;
      }
      if (typeof data.orders === 'number') {
        result.orders += data.orders;
      }
      if (typeof data.units === 'number') {
        result.units += data.units;
      }
      if (result.clicks > 0) {
        result.CR = result.orders / result.clicks;
        result.CALCCpc = result.spend / result.clicks;
      }
      if (result.spend > 0) {
        result.roas = result.sales / result.spend;
      }
      if (result.sales > 0) {
        result.CALCPacos = result.spend / result.sales;
      }
      if (result.orders > 0) {
        result.aov = result.sales / result.orders;
      }
      if (!node.group && data && data.total_spend && data.total_spend > 0) {
        result.CALCSpendPercentOfTotal = (result.spend / data.total_spend);
      } else if (node.group) {
        result.CALCSpendPercentOfTotal = 1;
      }
    });
    return result;
  }

  return (
    <Container className="dashboard">
      <Col>
        <Row>
          <Col xs={12}>
            <CardPanel
              showButtons={false}
            >
              <Row>
                <Col xs={12} md={4}>
                  <DateRangePicker
                    startDate={startDate.toDate()}
                    endDate={endDate.toDate()}
                    onDateChange={dateChange}
                  />
                </Col>
                {!isAdRev() && (
                  <Col xs={12} md={3}>
                    <PortfolioFilter
                      onChange={obj => portfolioChange(obj)}
                    />
                  </Col>
                )}
              </Row>
            </CardPanel>
          </Col>
        </Row>
      </Col>
      <TableBuilder
        columnDefs={isAdRev() ? simpleColumnDefs : columnDefs}
        defaultColumnDefs={defaultColumnDefs}
        frameworkComponents={frameworkComponents}
        apiEndPoint={apiEndPoint}
        apiQueryParams={apiQueryParams}
        pivotButtons={isAdRev() ? simplePivotButtons : pivotButtons}
        getGroupRowAgg={nodes => getGroupRowAgg(nodes)}
        suppressAggFuncInHeader
        rowHeight={75}
        enableCharts
        showFooter
        autoSizeAll
        onFirstDataRendered={onFirstDataRendered}
      />
    </Container>
  );
}
