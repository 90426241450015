const agGridKey = 'Using_this_AG_Grid_Enterprise_key_( AG-044275 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Limitless )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Dashboard )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( Dashboard )_need_to_be_licensed___( Dashboard )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 19 August 2024 )____[v2]_MTcyNDAyMjAwMDAwMA==c64260afb0c3dfcd8d968cc887f6ec51';

const jsonHeader = { 'content-type': 'application/json' };
const gzipHeader = { 'accept-encoding': 'gzip', 'user-agent': 'gzip', 'content-type': 'application/json' };

const isProdEnv = process.env.NODE_ENV === 'production';

const serverProdUrl = 'https://server-dot-anine-backend.appspot.com';
const serverDevUrl = 'http://localhost:4000';
const serverUrl = isProdEnv ? serverProdUrl : serverDevUrl;

const uploadProdUrl = 'https://upload-dot-anine-backend.appspot.com';
const uploadDevUrl = 'http://localhost:5000';

const showNotification = ({
  notification, position, notificationLU, notificationRU, notificationTC,
}) => {
  switch (position) {
    case 'left-up':
      notificationLU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 'calc(100vw - 100%)' },
        className: position,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 5,
        closable: true,
        style: { top: 0, left: 0 },
        className: position,
      });
      break;
  }
};

module.exports = {
  agGridKey,
  jsonHeader,
  gzipHeader,
  isProdEnv,
  serverProdUrl,
  serverDevUrl,
  serverUrl,
  uploadProdUrl,
  uploadDevUrl,
  showNotification,
};
