import {
  useState, useEffect,
} from 'react';
import useAccountMarketplace from '../../../../shared/components/hooks/useMarketPlace';
import {
  withArray, when, arrayToMap,
} from '../../../../shared/functions';
import calcPpcTotalsByCampaignType from './calcPpcTotalsByCampaignType';
import useAccountOverTime from '../../../../shared/components/hooks/useAccountOverTime';
import useSalesComboData from '../../../../shared/components/hooks/useSalesComboData';
import useTopAsins from '../../../../shared/components/hooks/useTopAsins';
import useRankForDaysEnding from '../../../../shared/components/hooks/useRankForDaysEnding';

export default function useSellerData(accountSelect, startSelectionDate, endSelectionDate, dateView) {
  const marketplace = useAccountMarketplace(accountSelect);
  const dataSet = { value: '' };
  const [ppcLoading, ppcResults, ppcError] = useAccountOverTime(accountSelect, startSelectionDate, endSelectionDate, dateView, dataSet);
  const [salesComboLoading, salesComboResults, salesComboError] = useSalesComboData(accountSelect, startSelectionDate, endSelectionDate, marketplace, dateView);
  const [rankLoading, rankResults, rankError] = useRankForDaysEnding(accountSelect, 30, endSelectionDate);
  const [topAsinsAcosLoading, topAsinsAcosResults, topAsinsAcosError] = useTopAsins(accountSelect, startSelectionDate, endSelectionDate, 'acos');
  const [topAsinsRankLoading, topAsinsRankResults, topAsinsRankError] = useTopAsins(accountSelect, startSelectionDate, endSelectionDate, 'rank');
  const loading = ppcLoading || salesComboLoading || rankLoading || topAsinsAcosLoading || topAsinsRankLoading;
  const [results, setResults] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const data = withArray(ppcResults, () => {
      const salesComboMap = arrayToMap(salesComboResults, 'report_date');
      return ppcResults.map((result) => {
        const temp = result;
        const salesCombo = salesComboMap.get(result.rankDate);
        when(salesCombo, () => {
          temp.gross_sales = salesCombo.total_sales || 0;
          temp.ppc_percent = (result.gross_sales && result.gross_sales > 0) ? result.sales / result.gross_sales : 0;
        });
        return temp;
      });
    }, () => []);

    const pieData = calcPpcTotalsByCampaignType(data);

    setResults({
      ...results,
      data,
      pieData,
      topAsinsAcos: topAsinsAcosResults,
      topAsinsRank: topAsinsRankResults,
      rankData: rankResults,
    });

    return (() => setResults({
      ...results,
      data: null,
      pieData: null,
      topAsinsAcos: null,
      topAsinsRank: null,
      rankData: null,
    }));
  }, [ppcResults, salesComboResults, setResults]);

  useEffect(() => {
    when(ppcError, () => {
      setError(ppcError);
    });
  }, [ppcError, setError]);

  useEffect(() => {
    when(salesComboError, () => {
      setError(salesComboError);
    });
  }, [salesComboError, setError]);

  useEffect(() => {
    when(topAsinsAcosError, () => {
      setError(topAsinsAcosError);
    });
  }, [topAsinsAcosError, setError]);

  useEffect(() => {
    when(topAsinsRankError, () => {
      setError(topAsinsRankError);
    });
  }, [topAsinsRankError, setError]);

  useEffect(() => {
    when(rankError, () => {
      setError(rankError);
    });
  }, [rankError, setError]);

  return [loading, results, error];
}
