import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import SellerCatalog from './components/SellerCatalog';
import VendorCatalog from './components/VendorCatalog';
import SimpleCatalog from './components/SimpleCatalog';

export default function CatalogIndexPage() {
  const sellerSelect = useSelector(state => state.sellerSelect);
  const title = (sellerSelect.type === 1) ? 'SKU Catalog' : 'ASIN Catalog';
  const BaseCatalog = (sellerSelect.type === 1) ? SellerCatalog : VendorCatalog;
  const Report = (sellerSelect.platform === 'adrev') ? SimpleCatalog : BaseCatalog;
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{title}</h3>
        </Col>
      </Row>
      <Row>
        <Report />
      </Row>
    </Container>
  );
}
