import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserTable from './components/UserTable';
import BasicAccountTable from './components/BasicAccountTable';
import Panel from '../../../shared/components/Panel';


const UserTablePage = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Users</h3>
        <h3 className="page-subhead subhead">Current User List</h3>
      </Col>
    </Row>
    <Row>
      <UserTable />
    </Row>
    <Row>
      <Panel lg={6} title="Create User Notes">
        <p>
          Access Levels:
          <li>admin - full site access, only limited by scope and roles</li>
          <li>agency - access to customerIdList and accountIdList, designated as a agency, can use roles </li>
          <li>customer - access to customerIdList and accountIdList, can use roles</li>
          <li>account - access to accountIdList, can use roles</li>
          <li>none - no access</li>
        </p>
        <p>
          Access Control Examples:
          <li>&quote;agency&quote; access control: <code>{'{"scope": "agency", "customerIdList": [281, 285], "accountIdList": [266, 267, 268]}'}</code></li>
          <li>&quote;customer&quote; access control: <code>{'{"scope": "customer", "customerIdList": [281, 285], "accountIdList": [266, 267, 268]}'}</code></li>
          <li>&quote;account&quote; access control: <code>{'{"scope": "account", "accountIdList": [316]}'}</code></li>
        </p>
        <p>
          Role Flags:
          <li>batchLoadRole - (internal role) allows access to upload batch files needed for data processing. note: depecrated</li>
          <li>accountControlRole - (agency role) allows access to Account Controls in the gutter</li>
          role example: <code>{'{"scope": "all", "accountIdList": [444, 518, 519], "batchLoadRole": true, "accountControlRole": true}'}</code>
        </p>
        <br />
        <br />
        <p>
          Create a password with <a href="https://www.lastpass.com/features/password-generator-a" rel="noopener noreferrer" target="_blank">Password Generator</a><br />
        </p>
      </Panel>
      <Panel lg={6} title="Account and Customer ID's Reference">
        <BasicAccountTable />
      </Panel>
    </Row>
  </Container>
);

export default UserTablePage;
