import React from 'react';
import { useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { SiteName, setFavicon, isDevelopment } from '../../../shared/components/domainSupport';
import ClientSidebarContent from './ClientSidebarContent';

const amazonIcon = require('../../../shared/img/logo/amazonIcon.png');
const dspIcon = require('../../../shared/img/logo/dspIcon.png');
const walmartIcon = require('../../../shared/img/logo/walmartIcon.png');

export default function SidebarContent({
  changeToDark, changeToLight, changeToLimitless, changeDevPlatform, sidebar, clientMenuData,
}) {
  const user = useSelector(state => state.authentication.user);
  const accessControl = JSON.parse(user.access_control);
  const sectionControl = clientMenuData?.sectionControl;
  const textAlignCenter = { paddingLeft: '20px' };
  return (
    <div className="sidebar__content">
      <ul className="sidebar__block">
        {user.access === 'admin' && (accessControl.superRole || accessControl.adminAccountRole) && (
          <SidebarCategory title="Admin Account" scope="admin" icon="cog" isAdmin>
            <SidebarLink title="Account API Status" route="/admin/account/status" />
            <SidebarLink title="Account Settings" route="/admin/account-setting" />
            <SidebarLink title="Account View" route="/admin/account-view" />
            <SidebarLink title="Calendar" route="/admin/event-calendar" />
            <SidebarLink title="Customers" route="/admin/customers" />
            <SidebarLink title="Customer Accounts" route="/admin/accounts" />
            <SidebarLink title="Users" route="/admin/users" />
          </SidebarCategory>
        )}
        {accessControl.accountControlRole && (
          <SidebarCategory title="Account Controls" scope="admin" icon="lock" isAdmin>
            <SidebarLink title="Budget Control" route="/account-management/budget" />
            <SidebarLink title="AMZ Catalog Manager" route="/account-management/catalog-amazon" />
            <SidebarLink title="WMC Catalog Manager" route="/account-management/catalog-walmart" />
          </SidebarCategory>
        )}
        {user.access === 'admin' && (accessControl.superRole || accessControl.adminDataRole) && (
          <SidebarCategory title="Admin Data" scope="admin" icon="database" isAdmin>
            <SidebarLink title="Activity" route="/admin/event-activity" />
            <SidebarLink title="Data Check" route="/admin/data-check" />
            <SidebarLink title="File Loader" route="/admin/file-loader" />
            <SidebarLink title="Account Settings Data" route="/admin/account-settings" />
            <SidebarLink title="Insight Cards" route="/insight" betaLink newLink />
          </SidebarCategory>
        )}
        {user.access === 'admin' && (
          <SidebarCategory title="Account Team" scope="admin" icon="flag" isAdmin>
            <SidebarLink title="Budget Control" route="/account-management/budget" />
            <SidebarLink title="Budget Stats" route="/account-management/budget-stats" />
            <SidebarLink title="Calendar" route="/customer/calendar" />
            <SidebarLink title="AMZ Catalog Manager" route="/account-management/catalog-amazon" />
            <SidebarLink title="WMC Catalog Manager" route="/account-management/catalog-walmart" />
            <SidebarLink title="Contact Center" route="/account-management/contact-center" />
            <SidebarLink title="Product Metrics" route="/account-management/product-metrics" betaLink />
            <SidebarLink title="Todo Lists" route="/account-management/todo" />
          </SidebarCategory>
        )}
        {(user.access === 'admin' || user.email === 'dan@charbs.com') && (
          <SidebarCategory title="Launch Central" scope="demo" icon="rocket" isAdmin isDev>
            <SidebarLink title="Launch Results" route="/customer/launch-results" />
            <SidebarLink title="Launch Settings" route="/customer/launch-settings" />
          </SidebarCategory>
        )}
        {(user.access === 'admin' || user.email === 'dan@charbs.com') && (
          <SidebarLink title="Onboarding" adminLink route="/onboarding" icon="magic-wand" />
        )}
        {user.access === 'admin' && (accessControl.superRole || accessControl.devRole) && (
          <SidebarCategory title="In Development" scope="admin" icon="cloud-sync" isAdmin isDev>
            <SidebarLink title="Product Ads" betaLink route="/customer/product-ads" icon="layers" />
            <SidebarLink title="Vendor Inventory Health" devLink route="/vendor/inventory-health" />
            <SidebarLink title="JTSR Asin" devLink route="/jtsr/asin" />
            <SidebarLink title="Vendor PPC Stats" devLink route="/vendor/ppc-stats" />
            <SidebarLink title="Seller Asin Brand Performance" devLink route="/customer/seller-asin-brand-performance" />
          </SidebarCategory>
        )}
        {user.access === 'admin' && isDevelopment() && (
          <SidebarCategory title="Platform" scope="development" icon="store" isAdmin isDev isNew>
            <button type="button" className="sidebar__link" onClick={() => changeDevPlatform('limitless')}>
              <p className="sidebar__link-title">Limitless</p>
            </button>
            <button type="button" className="sidebar__link" onClick={() => changeDevPlatform('adjuggernaut')}>
              <p className="sidebar__link-title">AdJuggernaut</p>
            </button>
            <button type="button" className="sidebar__link" onClick={() => changeDevPlatform('adrev')}>
              <p className="sidebar__link-title">AdRev</p>
            </button>
          </SidebarCategory>
        )}
      </ul>
      <ul className="sidebar__block">
        <SidebarCategory title="Layout" scope="all" icon="layers">
          <button type="button" className="sidebar__link" onClick={() => changeToLight()}>
            <p className="sidebar__link-title">Light Theme</p>
          </button>
          <button type="button" className="sidebar__link" onClick={() => changeToDark()}>
            <p className="sidebar__link-title">Dark Theme</p>
          </button>
          <button type="button" className="sidebar__link" onClick={() => changeToLimitless()}>
            {setFavicon()}
            <p className="sidebar__link-title"><SiteName /> Theme</p>
          </button>
        </SidebarCategory>
      </ul>
      {user.access === 'basic' && (
        <ul className="sidebar__block">
          <SidebarLink title="Get Started" route="/customer/get-started" icon="key" />
          <SidebarLink title="Account Onboarding" route="/onboarding/ad-api/start" icon="key" />
        </ul>
      )}
      {sectionControl && sectionControl.amazon && (
        <ul className="sidebar__block">
          {sidebar && !sidebar.collapse && (
            <h4 style={textAlignCenter}>Amazon Performance</h4>
          )}
          {sidebar && sidebar.collapse && (
            <>
              <div
                type="button"
                className="sidebar__link"
                style={{
                  display: 'flex', margin: 'auto', width: '50px', background: `url(${amazonIcon})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', cursor: 'default',
                }}
              />
              <br />
            </>
          )}
          <ClientSidebarContent scope="amazon" menuDataFromServer={clientMenuData?.menuDetails} />
        </ul>
      )}
      {sectionControl && sectionControl.dsp && (
        <ul className="sidebar__block">
          {sidebar && !sidebar.collapse && (
            <h4 style={textAlignCenter}>DSP Performance</h4>
          )}
          {sidebar && sidebar.collapse && (
            <>
              <div
                type="button"
                className="sidebar__link"
                style={{
                  display: 'flex', margin: 'auto', width: '50px', background: `url(${dspIcon})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', cursor: 'default',
                }}
              />
              <br />
            </>
          )}
          <ClientSidebarContent scope="dsp" menuDataFromServer={clientMenuData?.menuDetails} />
        </ul>
      )}
      {sectionControl && sectionControl.walmart && (
        <ul className="sidebar__block">
          {sidebar && !sidebar.collapse && (
            <h4 style={textAlignCenter}>Walmart Performance</h4>
          )}
          {sidebar && sidebar.collapse && (
            <>
              <div
                type="button"
                className="sidebar__link"
                style={{
                  display: 'flex', margin: 'auto', width: '50px', background: `url(${walmartIcon})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', cursor: 'default',
                }}
              />
              <br />
            </>
          )}
          <ClientSidebarContent scope="walmart" menuDataFromServer={clientMenuData?.menuDetails} />
        </ul>
      )}
      {user.access === 'admin' && (
        <ul className="sidebar__block">
          <SidebarCategory title="Key Info" scope="development" icon="question-circle" isDev>
            <div style={{ paddingLeft: '15px', width: '100%' }}>
              <p><span className="sidebar__category-admin" style={{ display: 'inline-block' }} /> Admin Catagory</p>
              <p><span className="sidebar__category-beta" style={{ display: 'inline-block' }} /> Beta Catagory</p>
              <p><span className="sidebar__category-dev" style={{ display: 'inline-block' }} /> Development Catagory</p>
              <p><span className="sidebar__category-new" style={{ display: 'inline-block' }} /> New Catagory</p>
              <p><Badge className="sidebar__link-badge-admin"><span>ADMIN</span></Badge> Admin Link</p>
              <p><Badge className="sidebar__link-badge"><span>BETA</span></Badge> Beta Link</p>
              <p><Badge className="sidebar__link-badge-dev"><span>DEV</span></Badge> Development Link</p>
              <p><Badge className="sidebar__link-badge-new"><span>NEW</span></Badge> New Link</p>
            </div>
          </SidebarCategory>
        </ul>
      )}
    </div>
  );
}
