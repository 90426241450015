import React, { PureComponent } from 'react';
import {
  Container, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import Panel from '../../../../../shared/components/Panel';
import DateRangePickerPanel from '../../../../../shared/components/widgets/DateRangePickerPanel';
import { SellerSelect } from '../../../../../shared/prop-types/MainProps';
import {
  currencyFormatter, formatDecimalToFixedTwoPercent, formatNumberDefaultToZero, formatNumberOnePlaceDefaultToZero,
} from '../../../../../shared/components/table/functions';
import config from '../../../../../config';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';
import PortfolioFilter, { ALL_PORTFOLIOS } from '../../../../../shared/components/widgets/PortfolioFilter';
import DataSetFilter, { DataSets } from '../../../../../shared/components/widgets/DataSetFilter';
import BccFilter, { BccOptions } from '../../../../../shared/components/widgets/BccFilter';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class BccSearchTerms extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
  };

  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.table = React.createRef();

    this.state = {
      reportStartDate: moment().subtract(34, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      initalLoad: false,
      columnDefs: [
        {
          field: 'search_term',
          headerName: 'Search Term',
        },
        {
          field: 'impressions',
          headerName: 'Impressions',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'clicks',
          headerName: 'Clicks',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'ctr',
          headerName: 'CTR',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'spend',
          headerName: 'Spend',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'sales',
          headerName: 'Sales',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'acos',
          headerName: 'AcOS',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'roas',
          headerName: 'ROAS',
          valueFormatter: formatNumberOnePlaceDefaultToZero,
        },
        {
          field: 'orders',
          headerName: 'Orders',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'cvr',
          headerName: 'CVR',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showDatePicker: false,
      showFooter: false,
      apiEndPoint: `${apiUrl}/customers/bcc-search-terms`,
      apiQueryParams: '',
      parentControlledLoad: true,
      dataOption: DataSets.ALL,
      portfolioOption: ALL_PORTFOLIOS,
      filterOption: BccOptions.ALL,
      loading: false,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.refreshData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.refreshData);
  }

  isLoading = (loading) => {
    this.setState({ loading });
  }

  refreshData = () => {
    const { sellerSelect } = this.props;
    const {
      reportStartDate, reportEndDate, dataOption, filterOption, portfolioOption,
    } = this.state;

    const apiQueryParams = `sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}&dataSet=${dataOption.value}&dataFilter=${filterOption.value}&portfolioFilter=${portfolioOption.value}`;

    this.setState({
      apiQueryParams,
    });
  }

  handleChange = (option, select) => {
    this.setState({
      [select.name]: option,
    }, () => {
      this.refreshData();
    });
  }

  render() {
    const {
      initalLoad, dataOption, reportStartDate, reportEndDate, parentControlledLoad, loading,
      filterOption, columnDefs, defaultColDef, showDatePicker, showFooter, apiEndPoint, apiQueryParams,
    } = this.state;

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <DateRangePickerPanel
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              onDateChange={this.onDateChange}
            />
          </Row>
        </Col>
        <Panel
          lg={12}
          md={12}
          title="Filters"
          subhead=""
          parentRefresh={initalLoad}
          ref={this.child}
        >
          <Row>
            <Col lg={4}>
              <DataSetFilter
                initialValue={dataOption}
                onChange={value => this.handleChange(value, { name: 'dataOption' })}
                disable={loading}
              />
            </Col>
            <Col lg={4}>
              <PortfolioFilter
                onChange={value => this.handleChange(value, { name: 'portfolioOption' })}
                disable={loading}
              />
            </Col>
            <Col lg={4}>
              <BccFilter
                initialValue={filterOption}
                onChange={value => this.handleChange(value, { name: 'filterOption' })}
                disable={loading}
              />
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title="Search Terms - TOP 50"
          subhead={`${filterOption.label} - ${moment(reportStartDate).format('MMMM DD, YYYY')} to ${moment(reportEndDate).format('MMMM DD, YYYY')}`}
          parentRefresh={initalLoad}
          ref={this.table}
        >
          <Row>
            <Col md={12}>
              <TableBuilder
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                apiEndPoint={apiEndPoint}
                apiQueryParams={apiQueryParams}
                parentControlledLoad={parentControlledLoad}
                showDatePicker={showDatePicker}
                showFooter={showFooter}
                isLoading={this.isLoading}
              />
            </Col>
          </Row>
        </Panel>

      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(BccSearchTerms);
