import React, { PureComponent } from 'react';
import {
  Container, Col, Row, Table,
} from 'reactstrap';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Panel from '../../../../shared/components/Panel';
import DateRangePickerPanel from '../../../../shared/components/widgets/DateRangePickerPanel';
import { SellerSelect, User, Theme } from '../../../../shared/prop-types/MainProps';
import { formatNumberWhole } from '../../../../shared/components/table/functions';
import style, { DROPDOWN_COLORS } from '../../../../shared/components/themeSupport';

import config from '../../../../config';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class RankDistribution extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      reportStartDate: moment().subtract(34, 'days').format('YYYY-MM-DD'),
      reportEndDate: moment().subtract(4, 'days').format('YYYY-MM-DD'),
      initalLoad: false,
      salesRankRankGroup: [],
      filterTypeOption: { label: 'Select Type', value: '' },
      filterTypeOptions: [{ label: 'Select Type', value: '' }, { label: 'ASIN', value: 'asin' }, { label: 'Subcategory', value: 'subcategory' }, { label: 'Segment One', value: 'segment_one' }, { label: 'Segment Two', value: 'segment_two' }, { label: 'Segment Three', value: 'segment_three' }, { label: 'Segment Four', value: 'segment_four' }, { label: 'Segment Five', value: 'segment_five' }],
      filterOption: { label: 'NONE', value: '' },
      filterOptions: [{ label: 'NONE', value: '' }],
      subcategoryOption: {},
      subcategoryOptions: [],
      asinOption: {},
      asinOptions: [],
      segmentOneOption: {},
      segmentOneOptions: [],
      segmentTwoOption: {},
      segmentTwoOptions: [],
      segmentThreeOption: {},
      segmentThreeOptions: [],
      segmentFourOption: {},
      segmentFourOptions: [],
      segmentFiveOption: {},
      segmentFiveOptions: [],
      dateViewOption: { label: 'Day', value: 'day' },
      dateViewOptions: [{ label: 'Day', value: 'day' }, { label: 'Week', value: 'week' }, { label: 'Month', value: 'month' }],
    };
  }

  componentDidMount() {
    this.getDropdownData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getDropdownData();
    }
  }

  onDateChange = (startDate, endDate) => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    this.setState({
      reportStartDate: start,
      reportEndDate: end,
    }, this.getData);
  }

  getDropdownData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate,
    } = this.state;
    const salesRankAsinAvgByDateUrl = `${apiUrl}/customers/sales-rank/asin-avg-by-date?accountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
    });

    fetch(salesRankAsinAvgByDateUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const dataSort = data.sort((a, b) => {
          const checkPos = (parseInt(b.sales_rank_avg, 10) > parseInt(a.sales_rank_avg, 10)) ? -1 : 0;
          return (parseInt(b.sales_rank_avg, 10) < parseInt(a.sales_rank_avg, 10)) ? 1 : checkPos;
        });

        const uniqueAsins = new Set();
        const asinOptions = [{ label: 'ALL ASINS', value: '' }];
        dataSort.forEach((item) => {
          if (item && !uniqueAsins.has(item.asin)) {
            uniqueAsins.add(item.asin);
            asinOptions.push({ label: `${item.asin} - ${item.title}`, value: item.asin });
          }
        });

        const uniqueCategories = new Set();
        const uniqueCategoriesSorted = dataSort.sort((a, b) => {
          const checkPos = (b.refinded_category_name > a.refinded_category_name) ? -1 : 0;
          return (b.refinded_category_name < a.refinded_category_name) ? 1 : checkPos;
        });
        const subcategoryOptions = [{ label: 'ALL SUBCATEGORIES', value: '' }];
        uniqueCategoriesSorted.forEach((category) => {
          if (category && !uniqueCategories.has(category.refinded_category_name)) {
            uniqueCategories.add(category.refinded_category_name);
            subcategoryOptions.push({ label: category.refinded_category_name, value: category.refinded_category_name });
          }
        });

        // Build Segment options
        const segmentOneSort = data.sort((a, b) => {
          const checkPos = (b.segment_one > a.segment_one) ? -1 : 0;
          return (b.segment_one < a.segment_one) ? 1 : checkPos;
        });

        const uniqueSegmentOne = new Set();
        const segmentOneOptions = [{ label: 'ALL OF SEGMENT ONE', value: '' }];
        segmentOneSort.forEach((item) => {
          if (item && !uniqueSegmentOne.has(item.segment_one)) {
            uniqueSegmentOne.add(item.segment_one);
            segmentOneOptions.push({ label: item.segment_one, value: item.segment_one });
          }
        });

        const segmentTwoSort = data.sort((a, b) => {
          const checkPos = (b.segment_two > a.segment_two) ? -1 : 0;
          return (b.segment_two < a.segment_two) ? 1 : checkPos;
        });

        const uniqueSegmentTwo = new Set();
        const segmentTwoOptions = [{ label: 'ALL OF SEGMENT TWO', value: '' }];
        segmentTwoSort.forEach((item) => {
          if (item && !uniqueSegmentTwo.has(item.segment_two)) {
            uniqueSegmentTwo.add(item.segment_two);
            segmentTwoOptions.push({ label: item.segment_two, value: item.segment_two });
          }
        });

        const segmentThreeSort = data.sort((a, b) => {
          const checkPos = (b.segment_three > a.segment_three) ? -1 : 0;
          return (b.segment_three < a.segment_three) ? 1 : checkPos;
        });

        const uniqueSegmentThree = new Set();
        const segmentThreeOptions = [{ label: 'ALL OF SEGMENT THREE', value: '' }];
        segmentThreeSort.forEach((item) => {
          if (item && !uniqueSegmentThree.has(item.segment_three)) {
            uniqueSegmentThree.add(item.segment_three);
            segmentThreeOptions.push({ label: item.segment_three, value: item.segment_three });
          }
        });

        const segmentfourSort = data.sort((a, b) => {
          const checkPos = (b.segment_four > a.segment_four) ? -1 : 0;
          return (b.segment_four < a.segment_four) ? 1 : checkPos;
        });

        const uniqueSegmentFour = new Set();
        const segmentFourOptions = [{ label: 'ALL OF SEGMENT FOUR', value: '' }];
        segmentfourSort.forEach((item) => {
          if (item && !uniqueSegmentFour.has(item.segment_four)) {
            uniqueSegmentFour.add(item.segment_four);
            segmentFourOptions.push({ label: item.segment_four, value: item.segment_four });
          }
        });

        const segmentFiveSort = data.sort((a, b) => {
          const checkPos = (b.segment_five > a.segment_five) ? -1 : 0;
          return (b.segment_five < a.segment_five) ? 1 : checkPos;
        });

        const uniqueSegmentFive = new Set();
        const segmentFiveOptions = [{ label: 'ALL OF SEGMENT FIVE', value: '' }];
        segmentFiveSort.forEach((item) => {
          if (item && !uniqueSegmentFive.has(item.segment_five)) {
            uniqueSegmentFive.add(item.segment_five);
            segmentFiveOptions.push({ label: item.segment_five, value: item.segment_five });
          }
        });

        this.setState({
          subcategoryOptions,
          subcategoryOption: subcategoryOptions[0],
          asinOptions,
          asinOption: asinOptions[0],
          segmentOneOptions,
          segmentOneOption: segmentOneOptions[0],
          segmentTwoOptions,
          segmentTwoOption: segmentTwoOptions[0],
          segmentThreeOptions,
          segmentThreeOption: segmentThreeOptions[0],
          segmentFourOptions,
          segmentFourOption: segmentFourOptions[0],
          segmentFiveOptions,
          segmentFiveOption: segmentFiveOptions[0],
          dateViewOption: { label: 'Day', value: 'day' },
          filterTypeOption: { label: 'Select Type', value: '' },
          filterTypeOptions: [{ label: 'Select Type', value: '' }, { label: 'ASIN', value: 'asin' }, { label: 'Subcategory', value: 'subcategory' }, { label: 'Segment One', value: 'segment_one' }, { label: 'Segment Two', value: 'segment_two' }, { label: 'Segment Three', value: 'segment_three' }, { label: 'Segment Four', value: 'segment_four' }, { label: 'Segment Five', value: 'segment_five' }],
          filterOption: { label: 'NONE', value: '' },
          filterOptions: [{ label: 'NONE', value: '' }],
        }, () => {
          this.getData();
        });
      });
  };

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate, dateViewOption, filterOption, filterTypeOption,
    } = this.state;

    let additinalParamemters = '';
    if (filterOption && filterOption.value && filterOption.value !== '') {
      if (filterTypeOption.value === 'asin') {
        additinalParamemters += `&asin=${filterOption.value}`;
      }
      if (filterTypeOption.value === 'subcategory') {
        additinalParamemters += `&category=${encodeURI(filterOption.value).replaceAll('&', '%26')}`;
      }
      if (filterTypeOption.value.startsWith('segment_')) {
        additinalParamemters += `&segment=${filterTypeOption.value}&segmentValue=${filterOption.value}`;
      }
    }

    const salesRankRankGroupUrl = `${apiUrl}/customers/sales-rank/groups?accountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}&dataGroup=${dateViewOption.value}${additinalParamemters}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      initalLoad: true,
    });

    fetch(salesRankRankGroupUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        this.setState({
          initalLoad: false,
          salesRankRankGroup: data,
        });
      });
  };

  setFilters = (filterOption, filterOptions) => {
    this.setState({
      filterOption,
      filterOptions,
    }, () => {
      this.getData();
    });
  }

  handleChange = (option, select) => {
    this.setState({
      [select.name]: option,
    }, () => {
      if (select.name === 'filterTypeOption') {
        // Set the Filters
        if (option.value === 'asin') {
          const { asinOption, asinOptions } = this.state;
          this.setFilters(asinOption, asinOptions);
        }
        if (option.value === 'subcategory') {
          const { subcategoryOption, subcategoryOptions } = this.state;
          this.setFilters(subcategoryOption, subcategoryOptions);
        }
        if (option.value === 'segment_one') {
          const { segmentOneOption, segmentOneOptions } = this.state;
          this.setFilters(segmentOneOption, segmentOneOptions);
        }
        if (option.value === 'segment_two') {
          const { segmentTwoOption, segmentTwoOptions } = this.state;
          this.setFilters(segmentTwoOption, segmentTwoOptions);
        }
        if (option.value === 'segment_three') {
          const { segmentThreeOption, segmentThreeOptions } = this.state;
          this.setFilters(segmentThreeOption, segmentThreeOptions);
        }
        if (option.value === 'segment_four') {
          const { segmentFourOption, segmentFourOptions } = this.state;
          this.setFilters(segmentFourOption, segmentFourOptions);
        }
        if (option.value === 'segment_five') {
          const { segmentFiveOption, segmentFiveOptions } = this.state;
          this.setFilters(segmentFiveOption, segmentFiveOptions);
        }
        if (option.value === '') {
          this.setState({
            filterOption: { label: 'NONE', value: '' },
            filterOptions: [{ label: 'NONE', value: '' }],
          }, () => {
            this.getData();
          });
        }
      } else {
        this.getData();
      }
    });
  }

  render() {
    const {
      salesRankRankGroup, initalLoad, filterOption, filterOptions, reportStartDate, reportEndDate,
      dateViewOption, dateViewOptions, filterTypeOption, filterTypeOptions,
    } = this.state;

    const { theme } = this.props;
    const themeColors = style(theme, DROPDOWN_COLORS);

    const salesRanks = salesRankRankGroup.map(a => a.sales_rank_avg);
    const salesRankMax = Math.max(...salesRanks);
    const domainMax = salesRankMax * 1.5;

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <DateRangePickerPanel
              reportStartDate={reportStartDate}
              reportEndDate={reportEndDate}
              onDateChange={this.onDateChange}
            />
          </Row>
        </Col>
        <Panel
          lg={12}
          md={12}
          title="Filters"
          subhead="NOTE: Filters and View only use data in the date range selected.  If your date range doesn't match your view, you could be seeing partial data on the ends of your view."
          parentRefresh={initalLoad}
        >
          <Row>
            <Col lg={4}>
              <p>Filter Type:</p>
              <div className="topbar__dynamicDropdownWidth">
                <Select
                  value={filterTypeOption}
                  name="filterTypeOption"
                  onChange={this.handleChange}
                  options={filterTypeOptions}
                  theme={selectTheme => ({
                    ...selectTheme,
                    colors: {
                      ...selectTheme.colors,
                      primary: `${themeColors.colorPrimary}`,
                      neutral0: `${themeColors.colorBackground}`,
                      neutral80: `${themeColors.colorText}`,
                    },
                  })}
                  isDisabled={initalLoad}
                />
              </div>
            </Col>
            <Col lg={4}>
              <p>Filters:</p>
              <div className="topbar__dynamicDropdownWidth">
                <Select
                  value={filterOption}
                  name="filterOption"
                  onChange={this.handleChange}
                  options={filterOptions}
                  theme={selectTheme => ({
                    ...selectTheme,
                    colors: {
                      ...selectTheme.colors,
                      primary: `${themeColors.colorPrimary}`,
                      neutral0: `${themeColors.colorBackground}`,
                      neutral80: `${themeColors.colorText}`,
                    },
                  })}
                  isDisabled={initalLoad}
                />
              </div>
            </Col>
            <Col lg={4}>
              <p>Date View:</p>
              <div className="topbar__dynamicDropdownWidth">
                <Select
                  value={dateViewOption}
                  name="dateViewOption"
                  onChange={this.handleChange}
                  options={dateViewOptions}
                  theme={selectTheme => ({
                    ...selectTheme,
                    colors: {
                      ...selectTheme.colors,
                      primary: `${themeColors.colorPrimary}`,
                      neutral0: `${themeColors.colorBackground}`,
                      neutral80: `${themeColors.colorText}`,
                    },
                  })}
                  isDisabled={initalLoad}
                />
              </div>
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title=""
          subhead=""
          parentRefresh={initalLoad}
        >
          <Row>
            <Col md={12}>
              <ResponsiveContainer height={400} className="dashboard__area">
                <ComposedChart
                  width={600}
                  height={400}
                  data={salesRankRankGroup}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid stroke={style(theme).colorText} />
                  <XAxis dataKey="rankDate" stroke={style(theme).colorText} />
                  <YAxis yAxisId="left" tickFormatter={number => number} stroke={style(theme).colorText} />
                  <YAxis yAxisId="right" orientation="right" tickFormatter={number => number} domain={[0, domainMax]} stroke={style(theme).colorText} />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="ten_group" name="Products at Ranks 1-10" stackId="a" yAxisId="left" barSize={20} fill="#03c03c" />
                  <Bar dataKey="hundred_group" name="Products at Ranks 11-100" stackId="a" yAxisId="left" barSize={20} fill="#36c9c9" />
                  <Bar dataKey="five_hundred_group" name="Products at Ranks 101-500" stackId="a" yAxisId="left" barSize={20} fill="#d04960" />
                  <Bar dataKey="thousand_group" name="Products at Ranks 501-1000" stackId="a" yAxisId="left" barSize={20} fill="#e85097" />
                  <Bar dataKey="rest_group" name="Products at Ranks 1001+" stackId="a" yAxisId="left" barSize={20} fill="#9a9a9a" />
                  <Line type="monotone" dataKey="sales_rank_avg" name="Sales Rank Avg" yAxisId="right" stroke="#FF9966" />
                  {/* ['#03c03c', '#36c9c9', '#fbb054', '#df7252', '#d04960', '#e85097', '#9a9a9a', '#e850e3']; */}
                </ComposedChart>
              </ResponsiveContainer>
            </Col>
          </Row>
        </Panel>

        <Panel
          lg={12}
          md={12}
          title=""
          subhead=""
          parentRefresh={initalLoad}
        >
          <div style={{
            marginLeft: '200px',
          }}
          >
            <Table responsive striped className="dashboard__table-orders" id="dataOverTime">
              <thead>
                <tr>
                  <th className="sticky-th-wide" />
                  {salesRankRankGroup.map(item => (
                    <th>{item.rankDate}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="sticky-td-color-wide" style={{ marginTop: '1px' }}>Average Sales Rank</td>
                  {salesRankRankGroup.map(item => (
                    <td>{formatNumberWhole(item.sales_rank_avg)}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-wide">Products at Ranks 1-10</td>
                  {salesRankRankGroup.map(item => (
                    <td>{item.ten_group}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-color-wide">Products at Ranks 11-100</td>
                  {salesRankRankGroup.map(item => (
                    <td>{item.hundred_group}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-wide">Products at Ranks 101-500</td>
                  {salesRankRankGroup.map(item => (
                    <td>{item.five_hundred_group}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-color-wide">Products at Ranks 501-1000</td>
                  {salesRankRankGroup.map(item => (
                    <td>{item.thousand_group}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-wide">Products at Ranks 1001+</td>
                  {salesRankRankGroup.map(item => (
                    <td>{item.rest_group}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        </Panel>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(RankDistribution);
