import { countryOptionsArray, countryOptionDefault } from '../../../containers/Onboarding/components/OnboardingConfig';

export default function useAccountMarketplace(account) {
  const { mpName } = account;
  let result = countryOptionDefault.marketplace;
  countryOptionsArray.forEach((country) => {
    if (mpName && mpName === country.value) {
      result = country.marketplace;
    }
  });
  return result;
}

export function useAccountCountry(account) {
  const { mpName } = account;
  let result = countryOptionDefault;
  countryOptionsArray.forEach((country) => {
    if (mpName && mpName === country.value) {
      result = country;
    }
  });
  return result;
}

export function formatProductLink(product, marketplace = 'amazon.com') {
  if (!product) {
    return null;
  }
  return `https://${marketplace}/dp/${product}`;
}
