import React, { PureComponent } from 'react';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

export default class UserTable extends PureComponent {
  constructor() {
    super();
    this.state = {
      columnDefs: [
        {
          field: 'customer_id',
          headerName: '#',
        },
        {
          field: 'customer_name',
          headerName: 'Customer Name',
        },
        {
          field: 'customer_email',
          headerName: 'Customer Email',
        },
        {
          field: 'customer_description',
          headerName: 'Description',
        },
        {
          field: 'customer_metadata',
          headerName: 'Meta Data',
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
      },
      showDatePicker: false,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker,
    } = this.state;

    const apiEndPoint = `${apiUrl}/customers`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
      />
    );
  }
}
