import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import TreemapModal from './TreemapModal';
// import Config from '../../../../config';
import CardInsight from '../../../../shared/components/widgets/CardInsight';

export default function InsightPerformanceCard({
  loading, title, cardData,
}) {
  // const sellerSelect = useSelector(state => state.sellerSelect);
  const theme = useSelector(state => state.theme);
  const [showPerformanceModal, setShowPerformanceModal] = useState(false);

  const changeShowPerformanceModal = () => {
    setShowPerformanceModal(!showPerformanceModal);
  };

  return (
    <>
      <CardInsight
        title={title}
        parentRefresh={loading}
        insightClass="performance"
        icon="chart-bars"
        countName={cardData.countName}
        countValue={cardData.countValue}
      >
        <div>
          <div><b>Description:</b></div>
          <div>{cardData.recommendation}</div>
        </div>
        <br />
        <br />
        <div><button type="button" className="btn btn-primary badge-btn" onClick={() => changeShowPerformanceModal()}>View Details</button></div>
        <br />
        <div className={'insight_bottom-blank'}>
          &nbsp;
        </div>
        <br />
        <div>
          <span><b>Date Range:</b> {moment(cardData.firstDate).format('MM/DD/YYYY')} - {moment(cardData.lastDate).format('MM/DD/YYYY')}</span>
        </div>
      </CardInsight>
      <TreemapModal
        title={title}
        theme={theme}
        data={cardData.data}
        labelKey="subcategory"
        sizeKey="sum_sales_30d"
        sizeName="Sales"
        showTreemapModal={showPerformanceModal}
        changeShowTreemapModal={changeShowPerformanceModal}
      />
    </>
  );
}

// InsightAdCard.defaultProps = {
//   data: null,
// };

InsightPerformanceCard.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
