import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col,
} from 'reactstrap';
import Panel from '../../../../../shared/components/Panel';
import { columnDefs, defaultColumnDefs } from './columns';
import TableBuilder from '../../../../../shared/components/table/TableBuilder';

// eslint-disable-next-line import/prefer-default-export
export function CompetitionTable({
  title, subtitle, loading, data,
}) {
  return (
    <Panel
      md={12}
      title={title}
      subhead={subtitle}
      parentRefresh={loading}
    >
      <Row>
        <Col>
          {data && data.length > 0 && (
            <TableBuilder
              columnDefs={columnDefs}
              defaultColDef={defaultColumnDefs}
              rowData={data}
              showFooter
              parentControlledLoad
            />
          )}
        </Col>
      </Row>
    </Panel>
  );
}

CompetitionTable.defaultProps = {
  loading: false,
  data: null,
};

CompetitionTable.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array),
};
