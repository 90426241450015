import React from 'react';
import {
  Col, Container, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AuthorizeSpApi from './components/AuthorizeSpApi';
import { defaultAgencyCode as DEFAULT_AGENCY } from '../../shared/components/domainSupport';

const AuthSpApiIndex = ({ agencyCode, displayLink }) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Authorize Selling Partner API</h3>
      </Col>
    </Row>
    <Row>
      <br />
    </Row>
    <Row>
      <AuthorizeSpApi agencyCode={agencyCode} displayLink={displayLink} apiType="sp-api" />
    </Row>
    <Row>
      <br />
    </Row>
  </Container>
);

AuthSpApiIndex.propTypes = {
  agencyCode: PropTypes.string,
  displayLink: PropTypes.string,
};

AuthSpApiIndex.defaultProps = {
  agencyCode: DEFAULT_AGENCY,
  displayLink: 'yes',
};

export default AuthSpApiIndex;
