import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  numberTwoPlaces, formatDecimalToFixedTwoPercent,
} from '../../../../shared/components/table/functions';
import { SellerSelect } from '../../../../shared/prop-types/MainProps';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class SellerAsinBrandPerformanceTable extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        { field: 'asin', headerName: 'ASIN' },
        { field: 'global_catalog_identifier', headerName: 'Global Catalog Identifier' },
        { field: 'title', headerName: 'Title' },
        { field: 'brand_name', headerName: 'Brand Name' },
        {
          field: 'avg_average_customer_review',
          headerName: 'Average Customer Review',
          valueFormatter: numberTwoPlaces,
        },
        {
          field: 'sum_customer_reviews_received',
          headerName: 'Customer Reviews Received',
          valueFormatter: numberTwoPlaces,
        },
        {
          field: 'avg_sales_rank',
          headerName: 'Sales Rank',
          valueFormatter: numberTwoPlaces,
        },
        {
          field: 'avg_buy_box_percentage',
          headerName: 'Buy Box Percentage',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'listings_missing_image',
          headerName: 'Listings Missing Image',
        },
        { field: 'listings_missing_description', headerName: 'Listings Missing Description' },
        { field: 'categorization_defect', headerName: 'Categorization Defect' },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showDatePicker: true,
    };
  }

  render() {
    const {
      columnDefs, defaultColDef, showDatePicker,
    } = this.state;
    const { sellerSelect } = this.props;

    const apiEndPoint = `${apiUrl}/big-query/seller-asin-brand-performance`;
    const apiQueryParams = `tableIdType=amazon_com&datasetId=seller_asin_brand_performance&sellerAccountId=${sellerSelect.value.toString()}&parentAccountId=${sellerSelect.parentAccountId}`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        showDatePicker={showDatePicker}
        apiQueryParams={apiQueryParams}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;

  return {
    sellerSelect,
  };
};

export default connect(mapStateToProps)(SellerAsinBrandPerformanceTable);
