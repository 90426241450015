/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { history } from '../../../helpers';
import SelectBoxDynamic from '../../../shared/components/widgets/SelectBoxDynamic';
import TableBuilder from '../../../shared/components/table/TableBuilder';
import config from '../../../config';
import {
  formatNumberDefaultToZero, numberValueParser, toDisplayDate, currencyFormatter, formatDecimalToFixedTwoPercent,
} from '../../../shared/components/table/functions';
import { SellerSelect, User, Theme } from '../../../shared/prop-types/MainProps';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class InvHealthPage extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      typeSelectedOptions: [],
      typeSelectedOption: {},
      dateSelectedOptions: [],
      dateSelectedOption: {},

      columnDefs: [
        { field: 'asin', headerName: 'ASIN' },
        { field: 'parent_asin', headerName: 'Parent ASIN' },
        { field: 'ean', headerName: 'EAN' },
        { field: 'isbn_13', headerName: 'ISBN_13' },
        { field: 'upc', headerName: 'UPC' },
        { field: 'product_title', headerName: 'Product Title' },
        { field: 'brand', headerName: 'Brand' },
        { field: 'brand_code', headerName: 'Brand Code' },
        { field: 'subcategory', headerName: 'Subcategory' },
        { field: 'category', headerName: 'Category' },
        { field: 'apparel_size', headerName: 'Apparel Size' },
        { field: 'apparel_size_width', headerName: 'Apparel Size Width' },
        { field: 'author_artist', headerName: 'Author_Artist' },
        { field: 'binding', headerName: 'Binding' },
        { field: 'color', headerName: 'Color' },
        {
          field: 'color_count',
          headerName: 'Color Count',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'list_price',
          headerName: 'List Price',
          valueFormatter: currencyFormatter,
        },
        { field: 'model_style_number', headerName: 'Model_Style Number' },
        { field: 'product_group', headerName: 'Product Group' },
        {
          field: 'release_date',
          headerName: 'Release Date',
          valueFormatter: toDisplayDate,
        },
        {
          field: 'net_received',
          headerName: 'Net Received',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'net_received_units',
          headerName: 'Net Received Units',
          valueFormatter: formatNumberDefaultToZero,
        },
        {
          field: 'sell_through_rate',
          headerName: 'Sell_Through Rate',
          valueFormatter: formatDecimalToFixedTwoPercent,
        },
        {
          field: 'open_purchase_order_quantity',
          headerName: 'Open Purchase Order Quantity',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'sellable_on_hand_inventory',
          headerName: 'Sellable On Hand Inventory',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'sellable_on_hand_inventory_trailing_30_day_average',
          headerName: 'Sellable On Hand Inventory_Trailing 30_day Average',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'sellable_on_hand_units',
          headerName: 'Sellable On Hand Units',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'unsellable_on_hand_inventory',
          headerName: 'Unsellable On Hand Inventory',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'unsellable_on_hand_inventory_trailing_30_day_average',
          headerName: 'Unsellable On Hand Inventory_Trailing 30_day Average',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'unsellable_on_hand_units',
          headerName: 'Unsellable On Hand Units',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'aged_90_days_sellable_inventory',
          headerName: 'Aged 90 Days Sellable Inventory',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'aged_90_days_sellable_inventory_trailing_30_day_average',
          headerName: 'Aged 90 Days Sellable Inventory_Trailing 30_day Average',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'aged_90_days_sellable_units',
          headerName: 'Aged 90 Days Sellable Units',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        {
          field: 'unhealthy_inventory',
          headerName: 'Unhealthy Inventory',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'unhealthy_inventory_trailing_30_day_average',
          headerName: 'Unhealthy Inventory_Trailing 30_day Average',
          valueFormatter: currencyFormatter,
        },
        {
          field: 'unhealthy_units',
          headerName: 'Unhealthy Units',
          valueFormatter: formatNumberDefaultToZero,
          valueParser: numberValueParser,
        },
        { field: 'replenishment_category', headerName: 'Replenishment Category' },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      apiEndPoint: `${apiUrl}/big-query/data`,
      parentControlledLoad: true,
      apiQueryParams: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  handleTypeChange = (selectedValue) => {
    this.setState({
      typeSelectedOption: selectedValue,
      dateSelectedOptions: selectedValue.dateList,
      dateSelectedOption: selectedValue.dateList[0],
    }, () => {
      this.refreshData();
    });
  }

  handleDateChange = (selectedValue) => {
    this.setState({
      dateSelectedOption: selectedValue,
    }, () => {
      this.refreshData();
    });
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const tableOptionsUrl = `${apiUrl}/big-query/table-options?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&datasetId=vendor_inventory_health`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      loadingData: true,
    });

    fetch(tableOptionsUrl, requestOptions)
      .then((results) => {
        if (results.status === 401) {
          history.push('/logout');
        }
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const typeSelectedOptions = [];
        data.forEach((item) => {
          if (item.indexOf('_M_') > 0) {
            if (!typeSelectedOptions.some(obj => obj.label === 'Retail Manufacturing')) {
              typeSelectedOptions.push({ label: 'Retail Manufacturing', value: item.substr(0, item.lastIndexOf('_')), dateList: [] });
            }
            const date = item.substr(item.lastIndexOf('_') + 1);
            typeSelectedOptions[0].dateList.unshift({ label: moment(date).format('YYYY-MM-DD'), value: date });
          }
          if (item.indexOf('_S_') > 0) {
            if (!typeSelectedOptions.some(obj => obj.label === 'Retail Sourcing')) {
              typeSelectedOptions.push({ label: 'Retail Sourcing', value: item.substr(0, item.lastIndexOf('_')), dateList: [] });
            }
            const date = item.substr(item.lastIndexOf('_') + 1);
            typeSelectedOptions[typeSelectedOptions.length - 1].dateList.unshift({ label: moment(date).format('YYYY-MM-DD'), value: date });
          }
        });

        this.setState({
          loadingData: false,
          typeSelectedOptions,
          typeSelectedOption: typeSelectedOptions[0],
          dateSelectedOptions: typeSelectedOptions[0].dateList,
          dateSelectedOption: typeSelectedOptions[0].dateList[0],
        }, () => {
          this.refreshData();
        });
      }).catch(() => {
        this.setState({
          loadingData: false,
        });
      });
  };

  refreshData = () => {
    const {
      typeSelectedOption, dateSelectedOption,
    } = this.state;
    const apiQueryParams = `tableIdType=${typeSelectedOption.value}&datasetId=vendor_inventory_health&startDate=${dateSelectedOption.value}&endDate=${dateSelectedOption.value}`;

    this.setState({
      apiQueryParams,
    });
  }

  render() {
    const { sellerSelect, theme } = this.props;
    const {
      typeSelectedOptions, typeSelectedOption, loadingData, apiEndPoint, defaultColDef, columnDefs, parentControlledLoad, apiQueryParams,
      dateSelectedOptions, dateSelectedOption,
    } = this.state;

    if (sellerSelect.type === 2) {
      return (
        <Container className="dashboard">
          <Row>
            <SelectBoxDynamic
              pageTitle="Inventory Options"
              loadingData={loadingData}
              mdWidth={6}
              selectOptions={typeSelectedOptions}
              selectedOption={typeSelectedOption}
              handleSelectChange={this.handleTypeChange}
              theme={theme}
            />
            <SelectBoxDynamic
              pageTitle="Date"
              loadingData={loadingData}
              mdWidth={6}
              selectOptions={dateSelectedOptions}
              selectedOption={dateSelectedOption}
              handleSelectChange={this.handleDateChange}
              theme={theme}
            />
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <TableBuilder
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  apiEndPoint={apiEndPoint}
                  parentControlledLoad={parentControlledLoad}
                  apiQueryParams={apiQueryParams}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <p>This is a Vendor specific report. Please select a new report or select a Vendor account.</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(InvHealthPage);
