import React, { PureComponent } from 'react';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import FullWidthFillGraph from '../../../../shared/components/widgets/FullWidthFillGraph';
import BudgetTable from './BudgetTable';
import config from '../../../../config';
import { SellerSelect, User } from '../../../../shared/prop-types/MainProps';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class BudgetGraph extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      graphData: [],
      graphLoad: false,
      checkedItem: ['spend', 'sales'],
      reportStartDate: moment().startOf('month').format('YYYYMMDD'),
      reportEndDate: moment().format('YYYYMMDD'),
      currentSales: 0,
      currentSpend: 0,
      currentById: { sales: {}, spend: {} },
      wmGraphData: [],
      wmGraphLoad: false,
      wmCurrentSales: 0,
      wmCurrentSpend: 0,
      wmCurrentById: { sales: {}, spend: {} },
      retailView: 'amazon',
    };
  }

  componentDidMount() {
    this.getData();
    this.getWmData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
      this.getWmData();
    }
  }

  getData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate,
    } = this.state;
    const budgetUrl = `${apiUrl}/customers/ppc-sales-spend?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}&accountType=${sellerSelect.type}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      graphLoad: true,
      graphData: [{}],
      currentSales: 0,
      currentSpend: 0,
      currentById: { spend: { '': 0 }, sales: { '': 0 } },
    });

    fetch(budgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const graphData = data.by_date.sort((a, b) => {
          const checkPos = (b.repot_date < a.repot_date) ? -1 : 0;
          return (b.repot_date > a.repot_date) ? 1 : checkPos;
        });

        const currentSales = data.totals.total_sales;
        const currentSpend = data.totals.total_spend;
        const currentById = data.by_id;

        this.setState({
          graphLoad: false,
          graphData,
          currentSales,
          currentSpend,
          currentById,
        });
      }).catch(() => {
        this.setState({
          graphLoad: false,
        });
      });
  };

  getWmData = () => {
    const { sellerSelect, user } = this.props;
    const {
      reportStartDate, reportEndDate,
    } = this.state;
    const statsUrl = `${apiUrl}/walmart/query/ad-items-by-dates-breakdown?accountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}&startDate=${reportStartDate}&endDate=${reportEndDate}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      wmGraphLoad: true,
      wmGraphData: [{}],
      wmCurrentSales: 0,
      wmCurrentSpend: 0,
      wmCurrentById: { spend: { '': 0 }, sales: { '': 0 } },
    });

    fetch(statsUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        const wmGraphData = data.by_date.sort((a, b) => {
          const checkPos = (b.repot_date < a.repot_date) ? -1 : 0;
          return (b.repot_date > a.repot_date) ? 1 : checkPos;
        });

        const wmCurrentSales = data.totals.total_sales;
        const wmCurrentSpend = data.totals.total_spend;
        const wmCurrentById = data.by_id;

        this.setState({
          wmGraphLoad: false,
          wmGraphData,
          wmCurrentSales,
          wmCurrentSpend,
          wmCurrentById,
        });
      }).catch(() => {
        this.setState({
          wmGraphLoad: false,
        });
      });
  };

  graphChange = (e) => {
    let checkedItem = ['spend', 'sales', 'sp_spend', 'sp_sales', 'sd_spend', 'sd_sales', 'sb_spend', 'sb_sales', 'sbv_spend', 'sbv_sales'];
    if (e.target.value === 'total') {
      checkedItem = ['spend', 'sales'];
    }
    if (e.target.value === 'sp') {
      checkedItem = ['sp_spend', 'sp_sales'];
    }
    if (e.target.value === 'sd') {
      checkedItem = ['sd_spend', 'sd_sales'];
    }
    if (e.target.value === 'sb') {
      checkedItem = ['sb_spend', 'sb_sales'];
    }
    if (e.target.value === 'sbv') {
      checkedItem = ['sbv_spend', 'sbv_sales'];
    }
    this.setState({
      checkedItem,
    });
  };

  retailerChange = (e) => {
    this.setState({
      retailView: e.target.value,
    });
  };

  render() {
    const {
      graphData, graphLoad, checkedItem, currentSpend, currentSales, currentById, wmCurrentSpend, wmCurrentSales, wmCurrentById, wmGraphData, wmGraphLoad, retailView,
    } = this.state;

    return (
      <Container className="dashboard">
        <Col md={12}>
          <Row>
            <h4>
              Retailer: &nbsp;
              <select onChange={this.retailerChange} className="select-options">
                <option value="amazon">Amazon</option>
                <option value="walmart">Walmart</option>
              </select>
            </h4>
          </Row>
        </Col>
        {retailView === 'amazon' && (
          <>
            <Col md={12}>
              <Row>
                <h5 style={{ width: '100%' }}>
                  Amazon Graph Data: &nbsp;
                  <select onChange={this.graphChange} className="select-options">
                    <option value="total">Total</option>
                    <option value="sp">Sponsored Product</option>
                    <option value="sd">Sponsored Display</option>
                    <option value="sb">Sponsored Brand</option>
                    <option value="sbv">Sponsored Brand Video</option>
                  </select>
                </h5>
              </Row>
              <br />
              <Row>
                <FullWidthFillGraph
                  data={graphData}
                  dataType="asin"
                  title="Amazon Budget Graph"
                  initalLoad={graphLoad}
                  lineList={checkedItem}
                  colorOptions={['#70bbfd', '#ffb153', '#f47b50', '#c88ffa', '#4ce1b6', '#66CCFF', '#FF9966', '#FF6666', '#9999CC', '#33CCCC']}
                />
              </Row>
            </Col>
            <Col md={12}>
              <Row>
                <BudgetTable
                  title="Amazon Budget Table"
                  currentSpend={currentSpend}
                  currentSales={currentSales}
                  currentById={currentById}
                  retailer="Amazon"
                />
              </Row>
            </Col>
          </>
        )}
        {retailView === 'walmart' && (
          <>
            <br />
            <br />
            <Col md={12}>
              <Row>
                <FullWidthFillGraph
                  data={wmGraphData}
                  dataType="item"
                  title="Walmart Budget Graph"
                  initalLoad={wmGraphLoad}
                  lineList={['spend', 'sales']}
                  colorOptions={['#70bbfd', '#ffb153', '#f47b50', '#c88ffa', '#4ce1b6', '#66CCFF', '#FF9966', '#FF6666', '#9999CC', '#33CCCC']}
                />
              </Row>
            </Col>
            <Col md={12}>
              <Row>
                <BudgetTable
                  title="Walmart Budget Table"
                  currentSpend={wmCurrentSpend}
                  currentSales={wmCurrentSales}
                  currentById={wmCurrentById}
                  retailer="Walmart"
                />
              </Row>
            </Col>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(BudgetGraph);
