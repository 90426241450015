import React from 'react';
import { useSelector } from 'react-redux';
import {
  Table,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {
  ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area,
} from 'recharts';
import {
  formatNumberWhole, formatDecimalToFixedTwoPercent,
} from '../../../../shared/components/table/functions';
import style from '../../../../shared/components/themeSupport';
import { currencyFormatter } from '../../../../shared/functions';
import CardPanel from '../../../../shared/components/widgets/CardPanel';

function calcSubHeading(dateView) {
  let result = 'Daily';
  if (dateView && dateView.value === 'week') {
    result = 'Weekly';
  } else if (dateView && dateView.value === 'month') {
    result = 'Monthly';
  }
  return `${result} for Selected Date Range`;
}

function getItemDate(item) {
  return item.rankDate ? item.rankDate : item.displayDate;
}

export default function ExecutiveView({
  title, loading, account, data, dateView, isWalmart,
}) {
  const theme = useSelector(state => state.theme);
  const isVendor = account && account.type === 2;
  const dateDataKey = isWalmart ? 'displayDate' : 'rankDate';

  return (
    <CardPanel
      title={title}
      parentRefresh={loading}
      showButtons={false}
      subhead={calcSubHeading(dateView)}
    >
      {!loading && data && (
        <>
          <div style={{
            marginTop: '30px',
          }}
          >
            <ResponsiveContainer height={400} className="dashboard__area">
              <ComposedChart
                width={600}
                height={400}
                data={data}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}
              >
                <CartesianGrid stroke={style(theme).colorText} />
                <XAxis dataKey={dateDataKey} stroke={style(theme).colorText} />
                <YAxis dataKey="sales" yAxisId="left" stroke={style(theme).colorText} />
                <Tooltip />
                <Legend />
                <Area type="monotone" dataKey="sales" yAxisId="left" formatter={value => currencyFormatter({ value, account })} stroke="green" name="Ad Sales" fill="#82ca9d" fillOpacity={0.3} />
                <Area type="monotone" dataKey="spend" yAxisId="left" formatter={value => currencyFormatter({ value, account })} stroke="purple" name="Ad Spend" fill="#8884d8" fillOpacity={0.3} />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div style={{
            marginTop: '30px',
            marginLeft: '100px',
          }}
          >
            <Table responsive striped className="dashboard__table-orders" id="executiveSummary">
              <thead>
                <tr>
                  <th className="sticky-th" />
                  {data.map(item => (
                    <th key={getItemDate(item)}>{getItemDate(item)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="sticky-td-color">Ad Spend</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{currencyFormatter({ value: item.spend, account })}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td">Ad Sales</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{currencyFormatter({ value: item.sales, account })}</td>
                  ))}
                </tr>
                {isVendor && (
                <tr>
                  <td className="sticky-td-color">Ord Rev</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{currencyFormatter({ value: item.ordered_revenue, account })}</td>
                  ))}
                </tr>
                )}
                {!isVendor && !isWalmart && (
                <tr>
                  <td className="sticky-td-color">Gross Sales</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{currencyFormatter({ value: item.gross_sales, account })}</td>
                  ))}
                </tr>
                )}
                <tr>
                  <td className="sticky-td">PPC ACoS</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{formatDecimalToFixedTwoPercent({ value: item.acos })}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-color">PPC ROAS</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{item.roas}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td">Clicks</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{formatNumberWhole(item.clicks)}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-color" style={{ marginTop: '1px' }}>Impressions</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{formatNumberWhole(item.impressions)}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td">Orders</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{formatNumberWhole(item.orders)}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td-color">CVR</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{formatDecimalToFixedTwoPercent({ value: item.cvr })}</td>
                  ))}
                </tr>
                <tr>
                  <td className="sticky-td">CTR</td>
                  {data.map(item => (
                    <td key={getItemDate(item)}>{formatDecimalToFixedTwoPercent({ value: (item.clicks / item.impressions) })}</td>
                  ))}
                </tr>
              </tbody>
            </Table>
          </div>
        </>
      )}
    </CardPanel>
  );
}

ExecutiveView.defaultProps = {
  title: 'Executive Summary Data',
  data: null,
  dateView: { value: 'day' },
  isWalmart: false,
};

ExecutiveView.propTypes = {
  loading: PropTypes.bool.isRequired,
  account: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string,
  data: PropTypes.instanceOf(Array),
  dateView: PropTypes.instanceOf(Object),
  isWalmart: PropTypes.bool,
};
