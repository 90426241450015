import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Recaptcha from 'reaptcha';
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';
import Alert from '../../../../shared/components/Alert';
import { userActions } from '../../../../redux/actions';
import { SiteName, getCorprateUrl } from '../../../../shared/components/domainSupport';
import LoadingSpinner from '../../../../shared/components/widgets/LoadingSpinner';
import { getLocalStorage } from '../../../../shared/storage';

class LogInForm extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    // loggingIn: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      email: '',
      password: '',
      rememberMe: false,
      submitted: false,
      recaptcha: '',
      recaptchaIsLoaded: false,
    };

    // reset login status
    const { dispatch } = this.props;
    dispatch(userActions.logout());

    this.showPassword = this.showPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReCaptcha = this.handleReCaptcha.bind(this);
    this.handleRemember = this.handleRemember.bind(this);
  }

  componentDidMount() {
    const rememberEmail = getLocalStorage('rememberMe');
    if (rememberEmail) {
      this.setState({ email: rememberEmail });
    }
  }

  onLoad() {
    this.setState({ recaptchaIsLoaded: true });
  }

  handleReCaptcha(response) {
    this.setState({ recaptcha: response });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleRemember(e) {
    if (e.target && e.target.checked) {
      this.setState({ rememberMe: e.target.checked });
    } else {
      this.setState({ rememberMe: false });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    localStorage.removeItem('loginMessage'); // clear message

    this.setState({ submitted: true });
    const {
      email, password, recaptcha, rememberMe,
    } = this.state;
    const { dispatch } = this.props;
    if (email && password) {
      dispatch(userActions.login(email, password, recaptcha, rememberMe));
    }
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  render() {
    // userActions.logout(); // We should not have a user on the login page.
    // eslint-disable-next-line
    const { loggingIn, alert } = this.props;

    // This can be cleaned up if we can figure out how to set the error at the App level (connect(map)(App))
    // But I was having issues with the hot loader.  Also needed to refresh for Captcha
    const loginMessage = JSON.parse(getLocalStorage('loginMessage'));

    const {
      showPassword, email, password, submitted, recaptchaIsLoaded,
    } = this.state;

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          {alert && alert.message && (
            <Alert className={alert.type}>{alert.message}</Alert>
          )}
        </div>
        <div className="form__form-group">
          {loginMessage && loginMessage.message && (
            <Alert className={loginMessage.type}>{loginMessage.message}</Alert>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Username</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <MailRuIcon />
            </div>
            <Field
              autoFocus
              name="email"
              component="input"
              type="email"
              placeholder="example@mail.com"
              value={email}
              onChange={this.handleChange}
            />
          </div>
          {submitted && !email && (
            <div className="form__form-group-error">Email is required</div>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={this.handleChange}
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
          </div>
          {submitted && !password && (
            <div className="form__form-group-error">Password is required</div>
          )}
          <div className="account__forgot-password">
            <a href="/forgot">Forgot a password?</a>
          </div>
        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="rememberMe"
              component={renderCheckBoxField}
              label="Remember me"
              onChange={this.handleRemember}
            />
          </div>
        </div>
        <div className="form__form-group form__center">
          <div className="form__inline">
            <Recaptcha sitekey="6LdYQuAbAAAAAD-jRnBlWEzdtu7poJfrLQ72h_mZ" onVerify={this.handleReCaptcha} onload={this.onLoad} />
          </div>
        </div>
        <div className="form__form-group form__center">
          <div className="form__inline">
            <p>By logging into this site you agree to {SiteName()}&apos;s <a href={`${getCorprateUrl()}/terms-of-use`} target="blank">Terms &amp; Conditions</a></p>
          </div>
        </div>
        <LoadingSpinner
          active={loggingIn}
        />
        <div className="form__form-group">
          <button className="btn btn-primary account__btn" type="submit" disabled={(recaptchaIsLoaded || loggingIn)}>Login</button>
          <Link className="btn btn-outline-primary account__btn" to="/register">Create Account</Link>
        </div>
      </form>
    );
  }
}

const logInForm = reduxForm({
  form: 'login_form',
})(LogInForm);

const mapStateToProps = (state) => {
  const { alert } = state;
  const { loggingIn } = state.authentication;

  return {
    alert,
    loggingIn,
  };
};

export default connect(mapStateToProps)(logInForm);
