import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Col, Container, Row,
} from 'reactstrap';
import { SiteName, defaultAgencyCode as DEFAULT_AGENCY } from '../../shared/components/domainSupport';

const Onboarding = ({ agencyCode }) => {
  const authAdApiStart = (agencyCode === '' || agencyCode === 'anine' || agencyCode === 'limitless') ? '/onboarding/ad-api/start' : `/onboarding/${agencyCode}/ad-api/start`;
  const authSpApiStart = (agencyCode === '' || agencyCode === 'anine' || agencyCode === 'limitless') ? '/onboarding/sp-api/start' : `/onboarding/${agencyCode}/sp-api/start`;
  const supportText = SiteName() === 'Limitless' ? <h4>For onboarding support, please contact <a href="mailto:adteam@limitless.tech">adteam@limitless.tech</a></h4> : '';

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Onboarding Your Account:</h3>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
      <Row>
        <Col md={6}>
          <div className="account__wrapper">
            <h4>In order to onboard, <b>Amazon has two APIs that you must authorize {SiteName()} to use</b>.  The Advertising process will give you a link on the success page that will allow you to authorize the Selling Partner API. Both steps must be completed in order to successfully onboard your account.</h4>
            <br />
            <p>
              <br /><br />
              <div className="form__form-group">
                <h4>Step 1: Authorize Amazon Advertising API</h4>
                <div style={{ margin: '50px' }}>
                  <Link className="btn btn-outline-primary account__btn" to={authAdApiStart}>Authorize Advertising</Link>
                </div>
                <h4>Step 2: Authorize Selling Partner API</h4>
                <div style={{ margin: '50px' }}>
                  <Link className="btn btn-outline-primary account__btn" to={authSpApiStart}>Authorize Selling Partner (Requires Login or Link)</Link>
                </div>
              </div>
            </p>
            {supportText}
            <br />
            <h4>Welcome aboard!</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <br />
      </Row>
    </Container>
  );
};

Onboarding.defaultProps = {
  agencyCode: DEFAULT_AGENCY,
};

Onboarding.propTypes = {
  agencyCode: PropTypes.string,
};

export default Onboarding;
