import React, { PureComponent } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import OneThreeBrandMarketShare from './OneThreeBrandMarketShare';
import config from '../../../../config';
import { history } from '../../../../helpers';
import PieBreakdown from '../../../../shared/components/widgets/PieBreakdown';
import HorizontalTwoBar from '../../../../shared/components/widgets/HorizontalTwoBar';
import { SellerSelect, User, Theme } from '../../../../shared/prop-types/MainProps';
import style, { DROPDOWN_COLORS } from '../../../../shared/components/themeSupport';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class OneThreeBrandMarketShareMain extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      loadingDataWidgets: false,
      multi: false,
      marketplaceSelectOptions: [],
      marketplaceSelectedOption: undefined,
      typeSelectOptions: [],
      typeSelectedOption: undefined,
      nodeSelectOptions: [],
      nodeSelectedOption: undefined,
      dateSelectOptions: [],
      dateSelectedOption: undefined,
      tableData: [],
      pieData: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  getData = () => {
    const { user, sellerSelect } = this.props;
    // const { nodeSelectedOption, typeSelectedOption } = this.state;
    const headerWithAuth = { authorization: `JWT ${user.jwtToken}` };

    // Clear previous settings
    this.setState({
      marketplaceSelectOptions: [],
      marketplaceSelectedOption: undefined,
      typeSelectOptions: [],
      typeSelectedOption: undefined,
      nodeSelectOptions: [],
      nodeSelectedOption: undefined,
      dateSelectOptions: [],
      dateSelectedOption: undefined,
      loadingData: true,
      loadingDataWidgets: true,
      noData: false,
    });

    fetch(`${apiUrl}/vendors/market-share-options?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}`, { headers: headerWithAuth })
      .then((resultsMarketShareOptions) => {
        if (resultsMarketShareOptions.status === 401) {
          history.push('/logout');
        }
        return resultsMarketShareOptions.json();
      })
      .then((data) => {
        if (Object.keys(data).length === 0 && data.constructor === Object) {
          this.setState({
            tableData: [],
            pieData: [],
            marketplaceSelectedOption: {},
            typeSelectedOption: {},
            nodeSelectedOption: {},
            dateSelectedOption: {},
            loadingData: false,
            loadingDataWidgets: false,
            noData: true,
          });
        }
        const marketShareOptionsJsonArr = data.marketplaces;

        if (marketShareOptionsJsonArr && marketShareOptionsJsonArr.length > 0) {
          const marketplaceSelectOptions = marketShareOptionsJsonArr;
          const [marketplaceSelectedOption] = marketShareOptionsJsonArr;

          if (marketplaceSelectedOption.types && marketplaceSelectedOption.types.length > 0) {
            const typeSelectOptions = marketplaceSelectedOption.types;
            const [typeSelectedOption] = marketplaceSelectedOption.types;

            if (typeSelectedOption.nodes && typeSelectedOption.nodes.length > 0) {
              const nodeSelectOptions = typeSelectedOption.nodes;
              const [nodeSelectedOption] = typeSelectedOption.nodes;

              if (nodeSelectedOption.dates && nodeSelectedOption.dates.length > 0) {
                const dateSelectOptions = nodeSelectedOption.dates;
                const [dateSelectedOption] = nodeSelectedOption.dates;

                this.setState({
                  marketplaceSelectOptions,
                  marketplaceSelectedOption,
                  typeSelectOptions,
                  typeSelectedOption,
                  nodeSelectOptions,
                  nodeSelectedOption,
                  dateSelectOptions,
                  dateSelectedOption,
                  loadingData: false,
                  noData: false,
                });
              }
            }
          }
        }
      }).catch((error) => {
        console.log(error);
        this.setState({
          loadingData: false,
        });
      });
  }

  handleMarketplaceChange = (selectedValue) => {
    const { marketplaceSelectedOption } = this.state;
    let typeSelectOptions = [];
    let typeSelectedOption = {};
    let nodeSelectOptions = [];
    let nodeSelectedOption = {};
    let dateSelectOptions = [];
    let dateSelectedOption = {};
    let loadingData = false;
    if (marketplaceSelectedOption !== selectedValue) {
      loadingData = true;
    }


    if (selectedValue.types && selectedValue.types.length > 0) {
      typeSelectOptions = selectedValue.types;
      [typeSelectedOption] = selectedValue.types;

      if (typeSelectedOption.nodes && typeSelectedOption.nodes.length > 0) {
        nodeSelectOptions = typeSelectedOption.nodes;
        [nodeSelectedOption] = typeSelectedOption.nodes;

        if (nodeSelectedOption.dates && nodeSelectedOption.dates.length > 0) {
          dateSelectOptions = nodeSelectedOption.dates;
          [dateSelectedOption] = nodeSelectedOption.dates;
        }
      }
    }
    this.setState({
      marketplaceSelectedOption: selectedValue,
      typeSelectOptions,
      typeSelectedOption,
      nodeSelectOptions,
      nodeSelectedOption,
      dateSelectOptions,
      dateSelectedOption,
      loadingData,
      loadingDataWidgets: loadingData,
    });
  }

  handleTypeChange = (selectedValue) => {
    const { typeSelectedOption } = this.state;
    let nodeSelectOptions = [];
    let nodeSelectedOption = {};
    let dateSelectOptions = [];
    let dateSelectedOption = {};
    let loadingData = false;
    if (typeSelectedOption !== selectedValue) {
      loadingData = true;
    }

    if (selectedValue.nodes && selectedValue.nodes.length > 0) {
      nodeSelectOptions = selectedValue.nodes;
      [nodeSelectedOption] = selectedValue.nodes;

      if (nodeSelectedOption.dates && nodeSelectedOption.dates.length > 0) {
        dateSelectOptions = nodeSelectedOption.dates;
        [dateSelectedOption] = nodeSelectedOption.dates;
      }
    }
    this.setState({
      typeSelectedOption: selectedValue,
      nodeSelectOptions,
      nodeSelectedOption,
      dateSelectOptions,
      dateSelectedOption,
      loadingData,
      loadingDataWidgets: loadingData,
    });
  }

  handleNodeChange = (selectedValue) => {
    const { nodeSelectedOption } = this.state;
    let dateSelectOptions = [];
    let dateSelectedOption = {};
    let loadingData = false;
    if (nodeSelectedOption !== selectedValue) {
      loadingData = true;
    }

    if (selectedValue.dates && selectedValue.dates.length > 0) {
      dateSelectOptions = selectedValue.dates;
      [dateSelectedOption] = selectedValue.dates;
    }
    this.setState({
      nodeSelectedOption: selectedValue,
      dateSelectOptions,
      dateSelectedOption,
      loadingData,
      loadingDataWidgets: loadingData,
    });
  }

  handleDateChange = (selectedValue) => {
    const { dateSelectedOption } = this.state;
    if (dateSelectedOption !== selectedValue) {
      this.setState({
        dateSelectedOption: selectedValue,
        loadingData: true,
        loadingDataWidgets: true,
      });
    }
  }

  setData = (inData) => {
    const { noData } = this.state;
    const COLORS = ['#03c03c', '#36c9c9', '#fbb054', '#df7252', '#d04960', '#e85097'];
    const outPieData = [{
      name: 'AMZ',
      total: 0,
      fill: COLORS[1],
    }, {
      name: 'FBM',
      total: 0,
      fill: COLORS[2],
    }, {
      name: 'FBA',
      total: 0,
      fill: COLORS[3],
    }];

    if (inData) {
      inData.forEach((row) => {
        outPieData.forEach((pieItem) => {
          // eslint-disable-next-line
          pieItem.total += row[pieItem.name];
        });
      });
    }
    if (!noData) {
      this.setState({
        tableData: inData,
        pieData: outPieData,
        loadingData: false,
        loadingDataWidgets: false,
      });
    }
  }

  render() {
    const {
      nodeSelectedOption, nodeSelectOptions, typeSelectedOption, typeSelectOptions, dateSelectedOption, multi, dateSelectOptions, loadingData, tableData, pieData,
      marketplaceSelectedOption, marketplaceSelectOptions, noData, loadingDataWidgets,
    } = this.state;
    const { theme, sellerSelect } = this.props;
    const themColors = style(theme, DROPDOWN_COLORS);

    let title = '';
    let subtitle = '';
    if (nodeSelectedOption && typeSelectedOption) {
      title = nodeSelectedOption.label;
      subtitle = typeSelectedOption.label;
    }

    return (
      <Container className="dashboard">
        <Row>
          <Col md={3}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Marketplace</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={marketplaceSelectedOption}
                    onChange={this.handleMarketplaceChange}
                    options={marketplaceSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themColors.colorPrimary}`,
                        neutral0: `${themColors.colorBackground}`,
                        neutral80: `${themColors.colorText}`,
                        neutral10: `${themColors.colorPrimary}`,
                      },
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Market Share Type</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={typeSelectedOption}
                    onChange={this.handleTypeChange}
                    options={typeSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themColors.colorPrimary}`,
                        neutral0: `${themColors.colorBackground}`,
                        neutral80: `${themColors.colorText}`,
                        neutral10: `${themColors.colorPrimary}`,
                      },
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={5}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Available Reports</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={nodeSelectedOption}
                    onChange={this.handleNodeChange}
                    options={nodeSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themColors.colorPrimary}`,
                        neutral0: `${themColors.colorBackground}`,
                        neutral80: `${themColors.colorText}`,
                        neutral10: `${themColors.colorPrimary}`,
                      },
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Report Date</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={dateSelectedOption}
                    onChange={this.handleDateChange}
                    options={dateSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themColors.colorPrimary}`,
                        neutral0: `${themColors.colorBackground}`,
                        neutral80: `${themColors.colorText}`,
                        neutral10: `${themColors.colorPrimary}`,
                      },
                    })}
                    isMulti={multi}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <PieBreakdown
            data={pieData}
            content={{
              title,
              subtitle,
              outerDataKey: 'total',
              innerDataKey: '',
            }}
            loadingData={loadingDataWidgets}
          />
          <HorizontalTwoBar
            data={tableData}
            content={{
              title: '1p vs 3p',
              subtitle: 'by Brand',
              dataKey: 'brand',
              firstDataKey: 'percentOneP',
              secondDataKey: 'percentThreeP',
              firstTitle: '1p',
              secondTitle: '3p',
            }}
            loadingData={loadingDataWidgets}
          />
        </Row>
        {!noData && marketplaceSelectedOption && typeSelectedOption && nodeSelectedOption && dateSelectedOption && (
          <Row>
            <OneThreeBrandMarketShare
              tableId={`${marketplaceSelectedOption.value}_${typeSelectedOption.value}_${nodeSelectedOption.value}_${dateSelectedOption.value}`}
              sellerAccountId={sellerSelect.value}
              parentAccountId={sellerSelect.parentAccountId}
              setPageData={this.setData}
            />
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    user,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(OneThreeBrandMarketShareMain);
