import { useEffect, useState } from 'react';
import moment from 'moment';
import useProductMetadataRankInRange from './useProductMetadataRankInRange';
import { withArray } from '../../functions';

export default function useRankForDaysEnding(account, numberDays, endDate) {
  const rankStart = moment(endDate).subtract(numberDays - 1, 'days');
  const rankEnd = moment(endDate);
  const [rankLoading, rankResults, rankError] = useProductMetadataRankInRange(account, rankStart, rankEnd);
  const [value, setValue] = useState(null);

  useEffect(() => {
    const rankData = withArray(rankResults, () => {
      const rankMap = new Map();
      rankResults.forEach(item => rankMap.set(item.report_date, item));
      const output = [];
      for (let m = moment(rankStart); m.isSameOrBefore(rankEnd); m.add(1, 'days')) {
        const rd = m.format('YYYYMMDD');
        const result = rankMap.get(rd);
        const rank = result && result.rank;
        const obj = {
          rankDate: rd,
          rank: rank || 0,
        };
        output.push(obj);
      }
      return output;
    }, () => []);

    setValue(rankData);
  }, [rankResults, setValue]);

  return [rankLoading, value, rankError];
}
