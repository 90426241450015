import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Row, Col } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import Select from 'react-select';
import { BasicNotification } from '../../../../shared/components/Notification';
import config from '../../../../config';
import Panel from '../../../../shared/components/Panel';
import {
  currencyFormatter, currencyDisplay, formatDecimalToFixedTwoPercentDirect,
} from '../../../../shared/components/table/functions';
import { SellerSelect, User } from '../../../../shared/prop-types/MainProps';
import { getPlatform } from '../../../../shared/components/domainSupport';


let notificationLU = null;
let notificationRU = null;
let notificationTC = null;

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class BudgetTable extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    currentSpend: PropTypes.number.isRequired,
    currentSales: PropTypes.number.isRequired,
    // eslint-disable-next-line
    currentById: PropTypes.object.isRequired,
    retailer: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      budgetItems: [],
      panelLoad: false,
      monthlyBudgetCurrent: 0,
      monthlyBudgetCarryover: 0,
      monthlyBudgetSupplemental: 0,
      monthlyPacos: 0,
      monthlyTacos: 0,
      groupCurrentTotal: 0,
      groupReallocationTotal: 0,
      groupCarryoverTotal: 0,
      groupSupplementalTotal: 0,
      groupPacosAvg: 0,
      groupTacosAvg: 0,
    };
  }

  componentDidMount() {
    this.getData();
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationLU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationRU = n);
    // eslint-disable-next-line
    NotificationSystem.newInstance({}, n => notificationTC = n);
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (notificationLU) {
      notificationLU.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
    if (notificationTC) {
      notificationTC.destroy();
    }
  }

  // eslint-disable-next-line
  show = (color, title, msg) => {
    return config.showNotification({
      notification: <BasicNotification
        color={color}
        title={title}
        message={msg}
      />,
      position: 'right-up',
      notificationLU,
      notificationRU,
      notificationTC,
    });
  };

  getData = () => {
    const { sellerSelect, user, retailer } = this.props;
    let retailerPath = '';
    if (retailer.toLowerCase() !== 'amazon') {
      retailerPath = `${retailer.toLowerCase()}/`;
    }
    const budgetUrl = `${apiUrl}/accounts/${retailerPath}budget?accountId=${sellerSelect.value}&platform=${getPlatform().nameKey}`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'GET',
      headers: headerWithAuth,
    };

    this.setState({
      panelLoad: true,
      budgetItems: [],
      groupCurrentTotal: 0,
      groupReallocationTotal: 0,
      groupCarryoverTotal: 0,
      groupSupplementalTotal: 0,
      groupPacosAvg: 0,
      groupTacosAvg: 0,
    });

    fetch(budgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then((data) => {
        let groupCurrentTotal = 0;
        let groupReallocationTotal = 0;
        let groupCarryoverTotal = 0;
        let groupSupplementalTotal = 0;
        let groupPacosTotal = 0;
        let groupTacosTotal = 0;
        let groupPacosAvg = 0;
        let groupTacosAvg = 0;
        let nonZeroItemPacosCount = 0;
        let nonZeroItemTacosCount = 0;

        data.budgetGroupData.forEach((item) => {
          if (parseFloat(item.pacos) > 0) {
            nonZeroItemPacosCount += 1;
          }
          if (parseFloat(item.tacos) > 0) {
            nonZeroItemTacosCount += 1;
          }
          groupCurrentTotal += parseFloat(item.current) || 0;
          groupReallocationTotal += parseFloat(item.reallocation) || 0;
          groupCarryoverTotal += parseFloat(item.carryover) || 0;
          groupSupplementalTotal += parseFloat(item.supplemental) || 0;
          groupPacosTotal += parseFloat(item.pacos) || 0;
          groupTacosTotal += parseFloat(item.tacos) || 0;
          if (nonZeroItemPacosCount > 0) {
            groupPacosAvg = (groupPacosTotal / nonZeroItemPacosCount).toPrecision(4);
          }
          if (nonZeroItemTacosCount > 0) {
            groupTacosAvg = (groupTacosTotal / nonZeroItemTacosCount).toPrecision(4);
          }
        });
        this.setState({
          panelLoad: false,
          budgetItems: data.budgetGroupData,
          monthlyBudgetCurrent: data.monthlyBudgetCurrent || 0,
          monthlyBudgetCarryover: data.monthlyBudgetCarryover || 0,
          monthlyBudgetSupplemental: data.monthlyBudgetSupplemental || 0,
          monthlyPacos: data.monthlyPacos || 0,
          monthlyTacos: data.monthlyTacos || 0,
          groupCurrentTotal,
          groupReallocationTotal,
          groupCarryoverTotal,
          groupSupplementalTotal,
          groupPacosAvg,
          groupTacosAvg,
        });
      }).catch(() => {
        this.setState({
          panelLoad: false,
        });
      });
  };

  submitBudget = () => {
    const {
      monthlyBudgetCurrent, monthlyBudgetCarryover, monthlyBudgetSupplemental, monthlyPacos, monthlyTacos, budgetItems,
    } = this.state;
    const { sellerSelect, user, retailer } = this.props;
    const budgetUrl = `${apiUrl}/accounts/budget`;

    const headerWithAuth = { ...config.jsonHeader, authorization: `JWT ${user.jwtToken}` };

    const requestOptions = {
      method: 'POST',
      headers: headerWithAuth,
      body: JSON.stringify({
        accountId: sellerSelect.value,
        monthlyBudgetCurrent,
        monthlyBudgetCarryover,
        monthlyBudgetSupplemental,
        monthlyPacos,
        monthlyTacos,
        currency: (sellerSelect.currencyCode || 'USD'),
        budgetItems,
        retailer,
      }),
    };

    this.setState({
      panelLoad: true,
    });

    fetch(budgetUrl, requestOptions)
      .then((results) => {
        if (!results.ok) {
          throw Error(results.statusText);
        }
        return results.json();
      }).then(() => {
        this.show('primary', 'Success', `${retailer} Budgets have been updated!`);
        this.setState({
          panelLoad: false,
        });
      }).catch(() => {
        this.show('danger', 'Error', `We were unable to update your ${retailer} budgets.`);
        this.setState({
          panelLoad: false,
        });
      });
  };

  updateBudget = (e) => {
    const stateName = e.target.name;
    this.setState({
      [stateName]: e.target.value.replace(/[^\-0-9.]/g, ''),
    });
  }

  updateBudgetGroupDropdown = (selectedOption, e) => {
    const { budgetItems } = this.state;
    const budgetItemsUpdates = [...budgetItems]; // clone array
    const { name: groupName } = e;
    const itemName = 'limitlessBudgetFlag';

    const objIndex = budgetItemsUpdates.findIndex((obj => obj.name === groupName));

    budgetItemsUpdates[objIndex][itemName] = selectedOption.value;
    this.setState({
      budgetItems: budgetItemsUpdates,
    });
  }

  updateBudgetGroup = (e) => {
    const { budgetItems } = this.state;
    const budgetItemsUpdates = [...budgetItems]; // clone array
    const { name, value, dataset } = e.target;
    const { groupName } = dataset;
    const objIndex = budgetItemsUpdates.findIndex((obj => obj.name === groupName));
    const itemName = name;
    const regex = name === 'portfolioIdsCsv' ? /[^\-0-9.,]/g : /[^\-0-9.]/g;
    budgetItemsUpdates[objIndex][itemName] = value.replace(regex, '');
    this.setState({
      budgetItems: budgetItemsUpdates,
    }, () => {
      // recalc totals
      let newTotal = 0;
      let newAvg = 0;
      let nonZeroItemCount = 0;
      budgetItemsUpdates.forEach((data) => {
        if (parseFloat(data[itemName]) > 0) {
          nonZeroItemCount += 1;
        }
        newTotal += parseFloat(data[itemName]) || 0;
        if (nonZeroItemCount > 0) {
          newAvg = (newTotal / nonZeroItemCount).toPrecision(4);
        }
      });
      if (name === 'current') {
        this.setState({
          groupCurrentTotal: newTotal,
        });
      }
      if (name === 'reallocation') {
        this.setState({
          groupReallocationTotal: newTotal,
        });
      }
      if (name === 'carryover') {
        this.setState({
          groupCarryoverTotal: newTotal,
        });
      }
      if (name === 'supplemental') {
        this.setState({
          groupSupplementalTotal: newTotal,
        });
      }
      if (name === 'pacos') {
        this.setState({
          groupPacosAvg: newAvg,
        });
      }
      if (name === 'tacos') {
        this.setState({
          groupTacosAvg: newAvg,
        });
      }
    });
  }

  getGroupValue = (groupName, dataType) => {
    const { budgetItems } = this.state;
    const filteredObj = budgetItems.filter((obj => obj.name === groupName));
    if (filteredObj[0][dataType] || filteredObj[0][dataType] === 0) {
      return filteredObj[0][dataType].toString();
    }
    return '';
  };

  render() {
    const {
      budgetItems, panelLoad, monthlyBudgetCurrent, monthlyBudgetCarryover, monthlyBudgetSupplemental, monthlyPacos, monthlyTacos, subhead,
      groupCurrentTotal, groupReallocationTotal, groupCarryoverTotal, groupSupplementalTotal, groupPacosAvg, groupTacosAvg,
    } = this.state;

    const {
      currentSpend, currentSales, currentById, title, retailer,
    } = this.props;

    const limitlessBudgetOptions = [
      { label: 'True', value: 1 },
      { label: 'False', value: 0 },
    ];

    const findOption = (value, options) => {
      const foundObject = options.find(item => item.value === value);
      if (foundObject) {
        return foundObject;
      }
      return options[1];
    };

    return (
      <Panel
        lg={12}
        md={12}
        title={title}
        subhead={subhead}
        parentRefresh={panelLoad}
        getData={this.getData}
      >
        <div className="dashboard__sales-report">
          <div className="progress-wrap progress-wrap--small">
            <p className="dashboard__sales-report-title">Monthly Budget Settings</p>
            <Row>
              <Col lg={3}>
                <div className="dashboard__bypage-chart-number">
                  <p style={{ whiteSpace: 'nowrap' }}>
                    Budget<br />
                    {currencyDisplay()} <input type="text" name="monthlyBudgetCurrent" onChange={this.updateBudget} value={monthlyBudgetCurrent} style={{ width: '90%', color: '#000000' }} />
                  </p>
                </div>
              </Col>
              <Col lg={3}>
                <div className="dashboard__bypage-chart-number">
                  <p style={{ whiteSpace: 'nowrap' }}>
                    Carryover<br />
                    {currencyDisplay()} <input type="text" name="monthlyBudgetCarryover" onChange={this.updateBudget} value={monthlyBudgetCarryover} style={{ width: '90%', color: '#000000' }} />
                  </p>
                </div>
              </Col>
              <Col lg={3}>
                <div className="dashboard__bypage-chart-number">
                  <p style={{ whiteSpace: 'nowrap' }}>
                    Supplemental<br />
                    {currencyDisplay()} <input type="text" name="monthlyBudgetSupplemental" onChange={this.updateBudget} value={monthlyBudgetSupplemental} style={{ width: '90%', color: '#000000' }} />
                  </p>
                </div>
              </Col>
              <Col lg={1}>
                <div className="dashboard__bypage-chart-number">
                  <p style={{ whiteSpace: 'nowrap' }}>
                    PACoS<br />
                    <input type="text" name="monthlyPacos" onChange={this.updateBudget} value={monthlyPacos} style={{ width: '90%', color: '#000000' }} /> %
                  </p>
                </div>
              </Col>
              <Col lg={1}>
                <div className="dashboard__bypage-chart-number">
                  <p style={{ whiteSpace: 'nowrap' }}>
                    TACoS<br />
                    <input type="text" name="monthlyTacos" onChange={this.updateBudget} value={monthlyTacos} style={{ width: '90%', color: '#000000' }} /> %
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <br />
        <br />
        <Table responsive striped className="dashboard__table-orders" id="top10">
          <thead>
            <tr>
              <th>Budget Item Group</th>
              <th>Group Current</th>
              <th>Group Reallocation</th>
              <th>Group Carryover</th>
              <th>Group Supplemental</th>
              <th>Group PACoS</th>
              <th>Group TACoS</th>
              <th>Portfolio ID</th>
              <th>Limitless Budget Flag</th>
              <th>MTD PPC Spend</th>
              <th>MTD PPC Sales</th>
              <th>MTD PPC ACoS</th>
            </tr>
          </thead>
          <tbody>
            {budgetItems.map(budgetItem => (
              <tr key={budgetItem.name}>
                <td>{budgetItem.name}</td>
                <td>{currencyDisplay()} <input type="text" name="current" data-group-name={budgetItem.name} onChange={this.updateBudgetGroup} value={this.getGroupValue(budgetItem.name, 'current')} style={{ width: '100px', color: '#000000' }} /></td>
                <td>{currencyDisplay()} <input type="text" name="reallocation" data-group-name={budgetItem.name} onChange={this.updateBudgetGroup} value={this.getGroupValue(budgetItem.name, 'reallocation')} style={{ width: '100px', color: '#000000' }} /></td>
                <td>{currencyDisplay()} <input type="text" name="carryover" data-group-name={budgetItem.name} onChange={this.updateBudgetGroup} value={this.getGroupValue(budgetItem.name, 'carryover')} style={{ width: '100px', color: '#000000' }} /></td>
                <td>{currencyDisplay()} <input type="text" name="supplemental" data-group-name={budgetItem.name} onChange={this.updateBudgetGroup} value={this.getGroupValue(budgetItem.name, 'supplemental')} style={{ width: '100px', color: '#000000' }} /></td>
                <td><input type="text" name="pacos" data-group-name={budgetItem.name} onChange={this.updateBudgetGroup} value={this.getGroupValue(budgetItem.name, 'pacos')} style={{ width: '100px', color: '#000000' }} /> %</td>
                <td><input type="text" name="tacos" data-group-name={budgetItem.name} onChange={this.updateBudgetGroup} value={this.getGroupValue(budgetItem.name, 'tacos')} style={{ width: '100px', color: '#000000' }} /> %</td>
                <td><input type="text" name="portfolioIdsCsv" data-group-name={budgetItem.name} onChange={this.updateBudgetGroup} value={this.getGroupValue(budgetItem.name, 'portfolioIdsCsv')} style={{ width: '100px', color: '#000000' }} /></td>
                <td>
                  <Select
                    name={budgetItem.name}
                    className="react-select"
                    classNamePrefix="react-select"
                    onChange={this.updateBudgetGroupDropdown}
                    options={limitlessBudgetOptions}
                    defaultValue={findOption(budgetItem.limitlessBudgetFlag, limitlessBudgetOptions)}
                    // clearable={false}
                  />
                </td>
                <td>{currencyFormatter({ value: (currentById.spend[(budgetItem.name || '')] || 0) })}</td>
                <td>{currencyFormatter({ value: (currentById.sales[(budgetItem.name || '')] || 0) })}</td>
                <td>{formatDecimalToFixedTwoPercentDirect((currentById.sales[(budgetItem.name || '')] || 0) > 0 ? (currentById.spend[(budgetItem.name || '')] / currentById.sales[(budgetItem.name || '')]) : 0)}</td>
              </tr>
            ))}
            <tr>
              <td>TOTALS/AVG</td>
              <td>{currencyFormatter({ value: groupCurrentTotal })}</td>
              <td>{currencyFormatter({ value: groupReallocationTotal })}</td>
              <td>{currencyFormatter({ value: groupCarryoverTotal })}</td>
              <td>{currencyFormatter({ value: groupSupplementalTotal })}</td>
              <td>{groupPacosAvg}%</td>
              <td>{groupTacosAvg}%</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>{currencyFormatter({ value: currentSpend })}</td>
              <td>{currencyFormatter({ value: currentSales })}</td>
              <td>{formatDecimalToFixedTwoPercentDirect(currentSales > 0 ? (currentSpend / currentSales) : 0)}</td>

            </tr>
          </tbody>
        </Table>
        <br />
        <br />
        <div className="progress-wrap progress-wrap--small progress-wrap--pink">
          <button type="button" className="btn btn-primary account__btn" onClick={this.submitBudget}>Update {retailer} Budgets</button>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  const { sellerSelect } = state;
  const { user } = state.authentication;

  return {
    sellerSelect,
    user,
  };
};

export default connect(mapStateToProps)(BudgetTable);
