import { useFetchReturnsArray as useFetch } from './useFetch';
import config from '../../../configWrapper';

function formatUrl(account, start, end, view) {
  if (account && account.value && start && end && view) {
    const url = `${config.serverUrl}/vendors/sales-manufacturing-over-time?sellerAccountId=${account.value}&parentAccountId=${account.parentAccountId}&currencyCode=${account.currencyCode}&startDate=${start.format('YYYYMMDD')}&endDate=${end.format('YYYYMMDD')}&dataGroup=${view.value}`;
    return url;
  }
  return undefined;
}

export default function useVendorSalesManufacturingOverTime(account, start, end, view) {
  return useFetch(formatUrl(account, start, end, view));
}
