/* eslint-disable react/no-children-prop */
import React, { PureComponent } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
// import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
// import PaintIcon from 'mdi-react/PaintIcon';
import renderSelectField from '../../../../shared/components/form/Select';
import { userActions } from '../../../../redux/actions';
import { changeThemeToDark, changeThemeToLight, changeThemeToLimitless } from '../../../../redux/actions/themeActions';
import { SiteName } from '../../../../shared/components/domainSupport';
import LoadingSpinner from '../../../../shared/components/widgets/LoadingSpinner';

class ProfileSettings extends PureComponent {
  static propTypes = {
    reset: PropTypes.func.isRequired,
    // eslint-disable-next-line
    user: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line
    initialValues: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      updateUserData: {
        first_name: '',
        last_name: '',
        theme: { value: 'light', label: 'Light Theme' },
        email: '',
        updatePassword: '',
        confirmPassword: '',
      },
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // updateUserData init
    const { initialValues } = this.props;
    const { updateUserData } = this.state;
    updateUserData.first_name = initialValues.first_name;
    updateUserData.last_name = initialValues.last_name;
    updateUserData.theme = initialValues.theme;
    updateUserData.email = initialValues.email;
    this.setState({ updateUserData });
  }

  handleSelectChange = (selectedTheme) => {
    const { dispatch } = this.props;
    const { updateUserData } = this.state;
    updateUserData.theme = selectedTheme;
    if (selectedTheme.value === 'light') {
      dispatch(changeThemeToLight());
    } else if (selectedTheme.value === 'dark') {
      dispatch(changeThemeToDark());
    } else if (selectedTheme.value === 'limitless') {
      dispatch(changeThemeToLimitless());
    }
    this.setState({
      updateUserData: {
        ...updateUserData,
        theme: selectedTheme,
      },
    });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { updateUserData } = this.state;
    this.setState({
      updateUserData: {
        ...updateUserData,
        [name]: value,
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { updateUserData } = this.state;
    const { user } = this.props;

    const { dispatch } = this.props;
    if (updateUserData.email && updateUserData.first_name && updateUserData.last_name && (updateUserData.updatePassword === updateUserData.confirmPassword)) {
      dispatch(userActions.updateProfile(user.id, user.jwtToken, updateUserData));
    }
  }

  render() {
    const {
      reset,
    } = this.props;

    const { updateUserData, submitted } = this.state;

    function resetFields() {
      reset(); // redux function
    }

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          <span className="form__form-group-label">First Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="first_name"
              component="input"
              type="text"
              placeholder="First Name"
              onChange={this.handleChange}
            />
          </div>
          {submitted && !updateUserData.first_name && (
            <div className="form__form-group-error">First Name is required</div>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Last Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="last_name"
              component="input"
              type="text"
              placeholder="Last Name"
              onChange={this.handleChange}
            />
          </div>
          {submitted && !updateUserData.last_name && (
            <div className="form__form-group-error">Last Name is required</div>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">E-mail</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <MailRuIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="email"
              placeholder="example@mail.com"
              autoComplete="new-email"
              onChange={this.handleChange}
            />
          </div>
          {submitted && !updateUserData.email && (
            <div className="form__form-group-error">Email is required</div>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Preferred Theme</span>
          <div className="form__form-group-field">
            <Field
              name="theme"
              component={renderSelectField}
              onChange={this.handleSelectChange}
              options={[
                { value: 'light', label: 'Light Theme' },
                { value: 'dark', label: 'Dark Theme' },
                { value: 'limitless', label: `${SiteName()} Theme` },
              ]}
            />
          </div>
        </div>
        <div className="form__form-group form__form-group--forgot">
          <span className="form__form-group-label">Update Password (optional)</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="updatePassword"
              component="input"
              type="password"
              placeholder="Update Password"
              autoComplete="new-password"
              onChange={this.handleChange}
            />
          </div>
          {submitted && (updateUserData.confirmPassword && !updateUserData.updatePassword) && (
            <div className="form__form-group-error">Confirm Passsword is required if you have a Upadate Password</div>
          )}
          <br />
          <span className="form__form-group-label">Confirm Updated Password (only needed for password update)</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="confirmPassword"
              component="input"
              type="password"
              placeholder="Confirm Password"
              autoComplete="new-password"
              onChange={this.handleChange}
            />
          </div>
          {submitted && (!updateUserData.confirmPassword && updateUserData.updatePassword) && (
            <div className="form__form-group-error">Confirm Passsword is required if you have a Upadate Password</div>
          )}
        </div>
        <LoadingSpinner
          active={submitted}
        />
        <div className="form__form-group-field">
          <p>NOTE: Updating your profile will require you to login again.</p>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" disable={submitted} type="submit">Update profile and Logout</Button>
          <Button type="button" onClick={resetFields}>
            Cancel
          </Button>
        </ButtonToolbar>
      </form>
    );
  }
}

const profileSettings = reduxForm({
  form: 'profile_settings_form',
})(ProfileSettings);

const mapStateToProps = (state) => {
  const { alert } = state;
  const { user } = state.authentication;

  return {
    alert,
    user,
  };
};

export default connect(mapStateToProps)(profileSettings);
