import {
  useState, useEffect,
} from 'react';
import config from '../../../../config';
import { useFetchReturnsArray as useFetch } from '../../../../shared/components/hooks/useFetch';
import { when } from '../../../../shared/functions';

function getCountUrl(account, path) {
  if (account && account.value) {
    const url = `${config.serverUrl}/${path}?accountId=${account.value}&parentAccountId=${account.parentAccountId}`;
    return url;
  }

  return undefined;
}

export default function usePerformanceData(
  account,
) {
  const [subcatPerformanceLoading, subcatPerformanceResults, subcatPerformanceError] = useFetch(getCountUrl(account, 'insights/subcat-performance-data'));
  const loading = subcatPerformanceLoading === 'true'; // Check all the loadings here
  const [accountId, setAccountId] = useState(account.value);
  const [results, setResults] = useState({ performanceCount: 0, performanceCards: [] });
  const [error, setError] = useState('');

  // Elegibility Effects
  useEffect(() => {
    if (subcatPerformanceResults?.subcatCount > 0) {
      const data = [
        {
          title: 'Sales',
          children: subcatPerformanceResults.subcatPerfData.filter(row => row.sum_sales_30d > 0),
        },
      ]

      const performanceCard = {
        title: 'Subcategory Performance',
        countName: 'Subcatgory Count',
        countValue: subcatPerformanceResults.subcatCount,
        platform: 'Amazon',
        recommendation: 'Subcatogry sales performance over the last 30 days.',
        data,
        rollupDate: subcatPerformanceResults.rollupDate,
        metadataDate: subcatPerformanceResults.metadataDate,
      }

      setResults({
        performanceCount: results.performanceCount + 1,
        performanceCards: [
          ...results.performanceCards,
          performanceCard,
        ],
      });
    }
  }, [subcatPerformanceResults, setResults]);

  useEffect(() => {
    when(subcatPerformanceError, () => {
      setError(subcatPerformanceError);
    });
  }, [subcatPerformanceError, setError]);

  useEffect(() => {
    if (account.value !== accountId) {
      setResults({ performanceCount: 0, performanceCards: [] });
      setAccountId(account.value);
    }
  }, [account]);

  return [loading, results, error];
}
