import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col,
} from 'reactstrap';
import moment from 'moment';
import { useDashboardContext } from './components/DashboardContext';
import useSellerData from './components/useSellerData';
import CardPanel from '../../../shared/components/widgets/CardPanel';
import DateRangePicker from '../../../shared/components/widgets/DateRangePicker'
import AccountSelect from './components/AccountSelect';
import ExecutiveView from './components/ExecutiveView';
import DateViewFilter from '../../../shared/components/widgets/DateViewFilter';
import Spend from './components/Spend';
import Sales from './components/Sales';
import PPCAcos from './components/PPCAcos';
import SalesGraph from './components/SalesGraph';
import GrossSales from './components/GrossSales';
import ProductPerformance from './components/ProductPerformance';
import { when } from '../../../shared/functions';
import SpendGraph from './components/SpendGraph';
import AverageSalesRank from './components/AverageSalesRank';
import TotalAcos from './components/TotalACoS';


function SellerDashboardPage({
  loading, data, pieData, topAsinsAcos, topAsinsRank, rankData, state, actions,
}) {
  const {
    accountSelect, startSelectionDate, endSelectionDate, dateView,
  } = state;
  const {
    setDates, setDateView, setAccount,
  } = actions;

  return (
    <Container className="dashboard">
      <Row>
        <Col md={6} lg={4}>
          <AverageSalesRank
            data={rankData}
            loading={loading}
            dateView={dateView}
            lastDay={endSelectionDate}
          />
        </Col>
        <Col md={6} lg={4}>
          <GrossSales
            data={data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
          />
        </Col>
        <Col md={6} lg={4}>
          <TotalAcos
            data={data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
          />
        </Col>
        <Col md={6} lg={4}>
          <Spend
            data={data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
          />
        </Col>
        <Col md={6} lg={4}>
          <Sales
            data={data}
            loading={loading}
            dateView={dateView}
            account={accountSelect}
          />
        </Col>
        <Col md={6} lg={4}>
          <PPCAcos
            data={data}
            loading={loading}
            dateView={dateView}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <SalesGraph
            title="PPC Sales"
            subhead="By Campaign Type"
            loading={loading}
            data={pieData}
            account={accountSelect}
          />
        </Col>
        <Col md={6}>
          <SpendGraph
            title="PPC Spend"
            subhead="By Campaign Type"
            loading={loading}
            data={pieData}
            account={accountSelect}
          />
        </Col>
        <Col md={6}>
          <ProductPerformance
            loading={loading}
            title="Best Performers -  ACoS"
            subtitle={'By Average ACoS for Date Range'}
            data={topAsinsAcos}
            field={'acos'}
          />
        </Col>
        <Col md={6}>
          <ProductPerformance
            loading={loading}
            title="Best Performers - Rank"
            subtitle={'By Average Rank for Date Range'}
            data={topAsinsRank}
            field={'rank'}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <CardPanel
            showButtons={false}
          >
            <Row>
              <Col xs={12} lg={3} xl={7}>
                <DateRangePicker
                  label="Select Dates:"
                  startDate={startSelectionDate.toDate()}
                  endDate={endSelectionDate.toDate()}
                  onDateChange={(start, end) => setDates(moment(start), moment(end))}
                />
              </Col>
              <Col xs={12} lg={3} xl={3}>
                <AccountSelect
                  label="Dashboard Account:"
                  accountSelect={accountSelect}
                  onChange={account => setAccount(account)}
                />
              </Col>
              <Col xs={12} lg={3} xl={2}>
                <DateViewFilter
                  initialValue={dateView}
                  onChange={view => setDateView(view)}
                />
              </Col>
            </Row>
          </CardPanel>
        </Col>
      </Row>
      <Row>
        <Col>
          <ExecutiveView
            title="Ad Sales vs Ad Spend"
            loading={loading}
            account={accountSelect}
            data={data}
            dateView={dateView}
          />
        </Col>
      </Row>
    </Container>
  );
}

SellerDashboardPage.defaultProps = {
  loading: false,
};

SellerDashboardPage.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.instanceOf(Array).isRequired,
  pieData: PropTypes.instanceOf(Array).isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
};

export default function SellerDashboard() {
  const { state, actions } = useDashboardContext();
  const {
    accountSelect, startSelectionDate, endSelectionDate, dateView,
  } = state;
  const [loading, results, error] = useSellerData(accountSelect, startSelectionDate, endSelectionDate, dateView);

  useEffect(() => {
    when(error, () => {
      console.error(error);
    });
  }, [error]);

  return (
    <SellerDashboardPage
      data={results && results.data}
      pieData={results && results.pieData}
      topAsinsAcos={results && results.topAsinsAcos}
      topAsinsRank={results && results.topAsinsRank}
      rankData={results && results.rankData}
      loading={loading}
      state={state}
      actions={actions}
    />
  );
}
