import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import LaunchSettings from './components/LaunchSettings';

const LaunchSettingsPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Launch Settings</h3>
      </Col>
    </Row>
    <Row>
      <LaunchSettings />
    </Row>
  </Container>
);

export default LaunchSettingsPage;
