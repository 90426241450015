import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Recaptcha from 'reaptcha';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PassportIcon from 'mdi-react/PassportIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { userActions } from '../../../../redux/actions';
import Alert from '../../../../shared/components/Alert';
import LoadingSpinner from '../../../../shared/components/widgets/LoadingSpinner';
import { getLocalStorage } from '../../../../shared/storage';

class RegisterForm extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        recaptcha: '',
        invitationCode: '',
      },
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.handleReCaptcha = this.handleReCaptcha.bind(this);
  }

  handleReCaptcha(response) {
    this.setState({ recaptcha: response });
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    localStorage.removeItem('loginMessage'); // clear message

    this.setState({ submitted: true });
    const { user, recaptcha } = this.state;
    const { dispatch } = this.props;
    if (user.firstName && user.lastName && user.email && user.password && user.confirmPassword
      && user.password === user.confirmPassword && user.invitationCode === 'Juggernaut') {
      dispatch(userActions.register(user, recaptcha));
    }
  }

  showPassword(e) {
    e.preventDefault();
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  render() {
    const {
      showPassword, user, submitted,
    } = this.state;
    // eslint-disable-next-line
    const { registering, alert } = this.props;
    const loginMessage = JSON.parse(getLocalStorage('loginMessage'));

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          {alert && alert.message && (
            <Alert className={alert.type}>{alert.message}</Alert>
          )}
        </div>
        <div className="form__form-group">
          {loginMessage && loginMessage.message && (
            <Alert className={loginMessage.type}>{loginMessage.message}</Alert>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">First Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="firstName"
              component="input"
              type="text"
              placeholder="First Name"
              value={user.firstName}
              onChange={this.handleChange}
            />
          </div>
          {submitted && !user.firstName && (
            <div className="form__form-group-error">First Name is required</div>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Last Name</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="lastName"
              component="input"
              type="text"
              placeholder="Last Name"
              value={user.lastName}
              onChange={this.handleChange}
            />
          </div>
          {submitted && !user.lastName && (
            <div className="form__form-group-error">Last Name is required</div>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">E-mail</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <MailRuIcon />
            </div>
            <Field
              name="email"
              component="input"
              type="email"
              placeholder="example@mail.com"
              value={user.email}
              onChange={this.handleChange}
            />
          </div>
          {submitted && !user.email && (
            <div className="form__form-group-error">Email is required</div>
          )}
        </div>
        <div className="form__form-group form__form-group--password">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component="input"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              value={user.password}
              onChange={this.handleChange}
            />
            <button
              type="button"
              className={`form__form-group-button${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
          </div>
          {submitted && !user.password && (
            <div className="form__form-group-error">Password is required</div>
          )}
          <br />
          <span className="form__form-group-label">Confirm Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="confirmPassword"
              component="input"
              type="password"
              placeholder="Confirm Password"
              value={user.confirmPassword}
              onChange={this.handleChange}
            />
          </div>
          {submitted && !user.confirmPassword && (
            <div className="form__form-group-error">Passsword Confirmation is required</div>
          )}
          {submitted && user.confirmPassword !== user.password && (
            <div className="form__form-group-error">Passsword Confirmation must match Password</div>
          )}
        </div>
        <div className="form__form-group form__form-group--code">
          <span className="form__form-group-label">Invitation Code</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <PassportIcon />
            </div>
            <Field
              name="invitationCode"
              component="input"
              type="text"
              placeholder="Invitation Code"
              value={user.invitationCode}
              onChange={this.handleChange}
            />
          </div>
          {submitted && !user.invitationCode && (
            <div className="form__form-group-error">Invitation Code is required</div>
          )}
        </div>
        <div className="form__form-group form__center">
          <div className="form__inline">
            <Recaptcha sitekey="6LdYQuAbAAAAAD-jRnBlWEzdtu7poJfrLQ72h_mZ" onVerify={this.handleReCaptcha} />
          </div>
        </div>
        <LoadingSpinner
          active={registering}
        />
        <button className="btn btn-primary account__btn" type="submit" disabled={registering}>Register</button>
        <Link to="/login" className="btn btn-outline-primary account__btn">Cancel</Link>
      </form>
    );
  }
}

const registerForm = reduxForm({
  form: 'register_form',
})(RegisterForm);

const mapStateToProps = (state) => {
  const { alert } = state;
  const { registering } = state.registration;

  return {
    alert,
    registering,
  };
};

export default connect(mapStateToProps)(registerForm);

// export default reduxForm({
//   form: 'register_form', // a unique identifier for this form
// })(RegisterForm);
