import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import md5 from 'md5';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import TopbarMenuLink from './TopbarMenuLink';
import { User, SellerSelect } from '../../../shared/prop-types/MainProps';

const pjson = require('../../../../package.json');

class TopbarProfile extends PureComponent {
  static propTypes = {
    user: User.isRequired,
    sellerSelect: SellerSelect.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;
    const { user, sellerSelect } = this.props;
    const gravatarHash = md5(user.email);

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={`https://www.gravatar.com/avatar/${gravatarHash}`} alt="avatar" />
          <p className="topbar__avatar-name">{user.first_name} {user.last_name}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Home" icon="inbox" path="/" toggle={this.toggle} />
            {user.access === 'admin' && (
              <TopbarMenuLink title="Status" icon="flag" path="/admin/account/status" toggle={this.toggle} />
            )}
            {sellerSelect && sellerSelect.value > 0 && sellerSelect.platform === 'adrev' && (
              <TopbarMenuLink title="My Plan" icon="arrow-up-circle" path="/subscription/plan" toggle={this.toggle} />
            )}
            {sellerSelect && sellerSelect.value > 0 && (
              <>
                <TopbarMenuLink title="Add Marketplace" icon="earth" path="/customer/onboarding/ad-api/start" toggle={this.toggle} />
                <TopbarMenuLink title="Authorize SP-API" icon="plus-circle" path="/customer/onboarding/sp-api/start" toggle={this.toggle} />
              </>
            )}
            <div className="topbar__menu-divider" />
            <TopbarMenuLink title="Profile" icon="user" path="/account/profile" toggle={this.toggle} />
            <TopbarMenuLink title="About" icon="link" path="/about" toggle={this.toggle} />
            {sellerSelect && sellerSelect.value > 0 && sellerSelect.platform === 'adrev' && (
              <TopbarMenuLink title="FAQ" icon="question-circle" path="/subscription/faq" toggle={this.toggle} />
            )}
            <TopbarMenuLink title="Log Out" icon="exit" path="/logout" />
            <p style={{ textAlign: 'center' }}>v{pjson.version}</p>
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.authentication;
  const { sellerSelect } = state;
  return {
    user,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(TopbarProfile);
