import React from 'react';
import {
  Container, Row,
} from 'reactstrap';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';

function getDayPercent(node, key, value) {
  let percent = 0;
  node.forEach(row => {
    // This is not exact, just matching the number with the number in the cell, wasn't sure how to find the pivot cell exactally, but this should directly be correct.
    if (row.data[key] === value) {
      percent = row.data[`${key}_percent`];
    }
  });
  return percent + 0.4; // Add some weight all all columns are a shade of green.
}

export default function ProductMetricsTable({ metric }) {
  const columnDefs = [
    {
      field: 'pivot_name',
      headerName: 'Product',
      rowGroup: true,
    },
    {
      field: 'report_date',
      headerName: 'Date',
      pivot: true,
    },
    {
      field: metric.value,
      headerName: metric.label,
      aggFunc: 'sum',
      cellStyle: (params) => {
        if (params.value > 0.00) {
          return { backgroundColor: `rgba(76, 175, 80, ${getDayPercent(params.node.childrenAfterAggFilter, metric.value, params.value)})` };
        }
        return { backgroundColor: 'red' };
      },
    },
    {
      field: `${metric.key}_1d_percent`,
      headerName: 'Percent',
    },
  ];

  const defaultColDef = {
    flex: 1,
    minWidth: 50,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true,
    filter: true,
    resizable: true,
    sortable: true,
  };

  const showDatePicker = true;
  const showExportOptions = true;
  const apiEndPoint = `${config.serverUrl}/customers/report/product/metric?metric=${metric.key}`;
  const groupDefaultExpanded = 3;
  const groupHideOpenParents = true;
  const autoGroupColumnDef = {
    cellRendererParams: {
      width: 200,
      suppressCount: true,
    },
  }

  return (
    <Container>
      <Row>
        <TableBuilder
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          apiEndPoint={apiEndPoint}
          showDatePicker={showDatePicker}
          showExportOptions={showExportOptions}
          pivotMode
          animateRows
          groupHideOpenParents={groupHideOpenParents}
          groupDisplayType="multipleColumns"
          groupDefaultExpanded={groupDefaultExpanded}
          autoGroupColumnDef={autoGroupColumnDef}
        />
      </Row>
    </Container>
  );
}
