import React, { PureComponent } from 'react';
// import crypto from 'crypto';
import { Field, reduxForm } from 'redux-form';
import WebIcon from 'mdi-react/WebIcon';
import StoreIcon from 'mdi-react/StoreIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { connect } from 'react-redux';
import Recaptcha from 'reaptcha';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Theme, User, SellerSelect } from '../../../shared/prop-types/MainProps';
import Alert from '../../../shared/components/Alert';
import { userActions } from '../../../redux/actions';
import { countryOptionsArray, countryOptionDefault } from './OnboardingConfig';
import { defaultAgencyCode as DEFAULT_AGENCY, platform as PLATFORM } from '../../../shared/components/domainSupport';
import style, { DROPDOWN_COLORS } from '../../../shared/components/themeSupport';
import LoadingSpinner from '../../../shared/components/widgets/LoadingSpinner';
import { history } from '../../../helpers';

class AuthorizeForm extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    // eslint-disable-next-line
    countryObj: PropTypes.object,
    theme: Theme.isRequired,
    loggedIn: PropTypes.bool,
    loading: PropTypes.bool,
    // eslint-disable-next-line
    alert: PropTypes.object,
    user: User,
    sellerSelect: SellerSelect.isRequired,
    agencyCode: PropTypes.string,
    apiType: PropTypes.string.isRequired,
    nameMsg: PropTypes.string,
  };

  static defaultProps = {
    loggedIn: false,
    loading: false,
    alert: {},
    user: {},
    agencyCode: DEFAULT_AGENCY,
    nameMsg: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      submitted: false,
      recaptcha: '',
      recaptchaIsLoaded: false,
      countryOption: countryOptionDefault,
      accountTypeOption: { label: 'Seller', value: '1' },
      accountTypeOptions: [{ label: 'Seller', value: '1' }, { label: 'Vendor', value: '2' }],
      accountId: null,
      platform: null,
      isTest: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReCaptcha = this.handleReCaptcha.bind(this);
  }

  componentDidUpdate(prevState) {
    const { countryObj } = this.props;
    if (countryObj !== prevState.countryObj) {
      // eslint-disable-next-line
      this.setState({ countryOption: countryObj });
    }
  }

  onLoad() {
    this.setState({ recaptchaIsLoaded: true });
  }

  handleCountryChange = (countryOption) => {
    this.setState({
      countryOption,
    });
  };

  handleAccountTypeChange = (accountTypeOption) => {
    this.setState({
      accountTypeOption,
    });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value.trim() });
  }

  handleReCaptcha(response) {
    this.setState({ recaptcha: response });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const {
      name, email, recaptcha, countryOption, accountTypeOption, accountId, isTest,
    } = this.state;
    const {
      dispatch, agencyCode, loggedIn, sellerSelect, apiType,
    } = this.props;
    const useAgency = loggedIn ? JSON.parse(sellerSelect.agency).code : agencyCode || DEFAULT_AGENCY;
    const useRecaptcha = (loggedIn && recaptcha === '') ? 'Logged In User' : recaptcha;
    let platform = loggedIn ? sellerSelect.platform : PLATFORM;
    if (platform === 'adjuggernaut') {
      platform = 'limitless'; // Temp workaround until all the APIs are deployed to be multi-platform.  AdRev is not live, so it's OK to onboard as-is.
    }
    if (name && email) {
      dispatch(userActions.authorizeApi(name, email, useRecaptcha, countryOption, useAgency, platform, accountTypeOption.value, accountId, apiType.toUpperCase(), isTest));
    }
  }

  render() {
    // eslint-disable-next-line
    const {
      loading, alert, theme, loggedIn, user, sellerSelect, apiType, nameMsg,
    } = this.props;

    const {
      name, email, submitted, recaptchaIsLoaded, countryOption, accountTypeOption, accountTypeOptions, platform, isTest,
    } = this.state;
    const themeColors = style(theme, DROPDOWN_COLORS);

    let defaultForm = loggedIn && apiType.toLowerCase() === 'sp-api';
    const forceEmail = loggedIn && apiType.toLowerCase() === 'ad-api' && window.location.pathname.startsWith('/customer/onboarding/ad-api/start');

    const urlParams = new URLSearchParams(window.location.search);
    const spApiParams = urlParams.get('edata');
    const adminParam = urlParams.get('admin');
    const isTestParam = urlParams.get('isTest')?.toLowerCase();

    const adminAccountTypeOptions = [{ label: 'Seller', value: '1' }, { label: 'Vendor', value: '2' }, { label: 'Agency', value: '3' }];
    if (adminParam && adminParam === 'true' && adminAccountTypeOptions.length !== accountTypeOptions.length) {
      try {
        this.setState({
          accountTypeOptions: [{ label: 'Seller', value: '1' }, { label: 'Vendor', value: '2' }, { label: 'Agency', value: '3' }],
        });
      } catch (err) {
        console.error(err);
      }
    }

    if (isTestParam === 'true') {
      this.setState({
        isTest: 'true',
      });
    }

    if (loggedIn && window.location.pathname.startsWith('/onboarding/sp-api/start')) {
      history.push('/customer/onboarding/sp-api/start');
    } else if (loggedIn && window.location.pathname.startsWith('/onboarding/ad-api/start')) {
      history.push('/customer/onboarding/ad-api/start');
    } else if (defaultForm) {
      this.setState({
        email: user.email,
        name: sellerSelect.label,
        countryOption: countryOptionsArray.find(option => option.label === sellerSelect.mpName.replace('GB', 'UK')),
        accountTypeOption: accountTypeOptions.find(option => option.value === sellerSelect.type.toString()),
        accountId: sellerSelect.value,
        platform: sellerSelect.platform,
      });
    } else if (spApiParams && apiType.toLowerCase() === 'sp-api') {
      try {
        const spApiParamsDecoded = decodeURIComponent(spApiParams);
        const spApiParamsString = Buffer.from(spApiParamsDecoded, 'base64').toString('utf-8');
        const spApiParamsJson = JSON.parse(spApiParamsString);
        defaultForm = true;
        this.setState({
          email: spApiParamsJson.email,
          name: spApiParamsJson.name,
          countryOption: countryOptionsArray.find(option => option.marketplaceId === spApiParamsJson.marketpalceId),
          accountTypeOption: accountTypeOptions.find(option => option.value === spApiParamsJson.accountType.toString()),
          accountId: spApiParamsJson.accountId,
          platform: spApiParamsJson.platform,
        });
      } catch (err) {
        return (
          <div className="form__form-group">
            <Alert className="INFO">You are required to be logged in or have a starting link to use the Selling Partner API authorization process.</Alert>
            <br />
            Error Reference: Bad Link
          </div>
        );
      }
    } else if (apiType.toLowerCase() === 'sp-api') {
      return (
        <div className="form__form-group">
          <Alert className="INFO">You are required to be logged in or have a starting link to use the Selling Partner API authorization process.</Alert>
        </div>
      );
    } else if (apiType.toLowerCase() === 'ad-api' && forceEmail) {
      this.setState({
        email: user.email,
      });
    }

    const customStyles = {
      container: provided => ({
        ...provided,
        width: '100%',
      }),
    };

    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="form__form-group">
          {alert && alert.message && (
            <Alert className={alert.type}>{alert.message}</Alert>
          )}
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Amazon Account Type</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <StoreIcon />
            </div>
            <div className="form__form-group-field">
              {!defaultForm && (
                <Select
                  value={accountTypeOption}
                  onChange={this.handleAccountTypeChange}
                  options={accountTypeOptions}
                  styles={customStyles}
                  theme={selectTheme => ({
                    ...selectTheme,
                    colors: {
                      ...selectTheme.colors,
                      primary: `${themeColors.colorPrimary}`,
                      neutral0: `${themeColors.colorBackground}`,
                      neutral80: `${themeColors.colorText}`,
                    },
                  })}
                />
              )}
              {defaultForm && (
                <p>&nbsp;&nbsp;{accountTypeOption.label}</p>
              )}
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Country</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <WebIcon />
            </div>
            <div className="form__form-group-field">
              {!defaultForm && (
                <Select
                  value={countryOption}
                  onChange={this.handleCountryChange}
                  options={countryOptionsArray}
                  styles={customStyles}
                  theme={selectTheme => ({
                    ...selectTheme,
                    colors: {
                      ...selectTheme.colors,
                      primary: `${themeColors.colorPrimary}`,
                      neutral0: `${themeColors.colorBackground}`,
                      neutral80: `${themeColors.colorText}`,
                    },
                  })}
                />
              )}
              {defaultForm && (
                <p>&nbsp;&nbsp;{countryOption.label}</p>
              )}
            </div>
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Account Name {nameMsg}</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            {!defaultForm && (
              <Field
                autoFocus
                name="name"
                component="input"
                type="text"
                placeholder={`Account Name - ${countryOption.label}`}
                value={name}
                onChange={this.handleChange}
              />
            )}
            {defaultForm && (
              <p>&nbsp;&nbsp;{name}</p>
            )}
          </div>
          {submitted && !name && (
            <div className="form__form-group-error">Account name is required</div>
          )}
        </div>
        {!defaultForm && !forceEmail && (
          <div className="form__form-group">
            <span className="form__form-group-label">Email</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <MailRuIcon />
              </div>
              <Field
                autoFocus
                name="email"
                component="input"
                type="email"
                placeholder="example@mail.com"
                value={email}
                onChange={this.handleChange}
              />
            </div>
            {submitted && !email && (
              <div className="form__form-group-error">Primary email is required</div>
            )}
          </div>
        )}
        {!loggedIn && (
          <div className="form__form-group">
            <Recaptcha sitekey="6LdYQuAbAAAAAD-jRnBlWEzdtu7poJfrLQ72h_mZ" onVerify={this.handleReCaptcha} onLoad={this.onLoad} />
          </div>
        )}
        <LoadingSpinner
          active={loading}
        />
        {isTest === 'true' && (
          <div className="form__form-group">
            <p>************************************************************ YOU ARE IN TEST MODE ************************************************************</p>
          </div>
        )}
        <div className="form__form-group">
          <button className="btn btn-primary account__btn" type="submit" disabled={recaptchaIsLoaded}>Authorize</button>
        </div>
        {platform === 'dan' && (
          <p>.</p>
        )}
        {/* {(platform === (sellerSelect.platform || PLATFORM) || apiType.toLowerCase() === 'ad-api' || (sellerSelect.platform || PLATFORM) === 'adjuggernaut') && (
          <div className="form__form-group">
            <button className="btn btn-primary account__btn" type="submit" disabled={recaptchaIsLoaded}>Authorize</button>
          </div>
        )}
        {platform !== (sellerSelect.platform || PLATFORM) && apiType.toLowerCase() !== 'ad-api' && (sellerSelect.platform || PLATFORM) !== 'adjuggernaut' && (
          <div className="form__form-group">
            <p>Platform error: Unable to Authorize.  Please contact your account manager.</p>
          </div>
        )} */}
      </form>
    );
  }
}

const authorizeForm = reduxForm({
  form: 'authorize_form',
})(AuthorizeForm);

const mapStateToProps = (state) => {
  const { alert, theme, sellerSelect } = state;
  const { loading } = state.users;
  const { loggedIn, user } = state.authentication;

  return {
    alert,
    theme,
    loading,
    loggedIn,
    user,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(authorizeForm);
