import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import config from '../../../../config';
import TableBuilder from '../../../../shared/components/table/TableBuilder';
import {
  formatDecimalToFixedTwoPercent, formatNumberDefaultToZero,
} from '../../../../shared/components/table/functions';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class JtsrAsinByPage extends PureComponent {
  static propTypes = {
    // asin: PropTypes.string.isRequired,
    // marketplace: PropTypes.string.isRequired,
    // selectedDates: PropTypes.string.isRequired,
    setPageData: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    // const {
    //   asin, marketplace, selectedDates, sellerSelect,
    // } = this.props;

    this.state = {
      columnDefs: [
        {
          field: 'page',
          headerName: 'Page',
          sort: 'asc',
          chartDataType: 'category',
        },
        {
          headerName: 'First Date',
          children: [
            {
              headerName: 'Search Term Phrases',
              colId: 'aaa',
              valueGetter: (params) => {
                if (params.data.dateData[1]) {
                  return params.data.dateData[1].search_term_count;
                }
                return '-';
              },
              headerTooltip: 'The total number of unique search term phrases that product appears on a given page.',
              aggFunc: 'sum',
              chartDataType: 'series',
            },
            {
              headerName: 'Search Volume',
              valueGetter: (params) => {
                if (params.data.dateData[1]) {
                  return params.data.dateData[1].rstp_search_volume;
                }
                return '-';
              },
              headerTooltip: 'The estimated total number of monthly searches by page on a given date.',
              aggFunc: 'sum',
            },
            {
              headerName: 'Rank',
              valueGetter: (params) => {
                if (params.data.dateData[1]) {
                  return params.data.dateData[1].rstp_serp_organic_rank_avg;
                }
                return '-';
              },
              valueFormatter: formatNumberDefaultToZero,
              headerTooltip: 'The average organic rank for all phrases found on a given page by date.',
              aggFunc: 'avg',
            },
          ],
        },
        {
          headerName: 'Second Date',
          children: [
            {
              headerName: 'Search Term Phrases',
              colId: 'secondStp',
              valueGetter: params => params.data.dateData[0].search_term_count,
              headerTooltip: 'The total number of unique search term phrases that product appears on a given page.',
              aggFunc: 'sum',
            },
            {
              headerName: 'Search Volume',
              valueGetter: params => params.data.dateData[0].rstp_search_volume,
              headerTooltip: 'The estimated total number of monthly searches by page on a given date.',
              aggFunc: 'sum',
            },
            {
              headerName: 'Rank',
              valueGetter: params => params.data.dateData[0].rstp_serp_organic_rank_avg,
              valueFormatter: formatNumberDefaultToZero,
              headerTooltip: 'The average organic rank for all phrases found on a given page by date.',
              aggFunc: 'avg',
            },
          ],
        },
        {
          headerName: 'Delta',
          children: [
            {
              field: 'calcData.rstp_search_term_count_diff',
              headerName: 'Search Term Change',
              aggFunc: 'sum',
            },
            {
              field: 'calcData.rstp_search_term_count_avg',
              headerName: 'Search Term %',
              valueFormatter: formatDecimalToFixedTwoPercent,
            },
            {
              field: 'calcData.rstp_search_volume_diff',
              headerName: 'Seach Volumn Diff',
              aggFunc: 'sum',
            },
          ],
        },
      ],
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: (params) => {
          if (params.value < 0.00) {
            return { color: 'red' };
          }
          return null;
        },
      },
      showDatePicker: false,
      showFooter: false,
      enableCharts: true,
      enableRangeSelection: true,
      apiQueryParams: null,
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.asin && props.marketplace && props.sellerSelect.value) {
      return {
        apiQueryParams: `asin=${props.asin}&marketplace=${props.marketplace}&selectedDates=${props.selectedDates}&sellerAccountId=${props.sellerSelect.value}&parentAccountId=${props.sellerSelect.parentAccountId}`,
      };
    }
    return null;
  }

  render() {
    const {
      columnDefs, defaultColDef, showFooter, enableCharts, enableRangeSelection, apiQueryParams, showDatePicker,
    } = this.state;

    const { setPageData } = this.props;

    const apiEndPoint = `${apiUrl}/jtsr/page-pivot`;

    return (
      <TableBuilder
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        apiEndPoint={apiEndPoint}
        apiQueryParams={apiQueryParams}
        showFooter={showFooter}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        showDatePicker={showDatePicker}
        parentSetData={setPageData}
        tableHeight="300px"
      />
    );
  }
}

export default JtsrAsinByPage;
