import React, { PureComponent } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Container, Row, Col, Card, CardBody,
} from 'reactstrap';
import JtsrAsinByPage from './JtsrAsinByPage';
import JtsrSearchTermRank from './JtsrSearchTermRank';
// import JtsrAsinByPageTotal from './JtsrAsinByPageTotal';
import config from '../../../../config';
import { history } from '../../../../helpers';
import DeltaCompare from '../../../../shared/components/widgets/DeltaCompare';
import PieBreakdown from '../../../../shared/components/widgets/PieBreakdown';
import HorizontalTwoBar from '../../../../shared/components/widgets/HorizontalTwoBar';
import {
  formatNumberDefaultToZero,
} from '../../../../shared/components/table/functions';
import { SellerSelect, User, Theme } from '../../../../shared/prop-types/MainProps';
import style, { DROPDOWN_COLORS } from '../../../../shared/components/themeSupport';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class JtsrAsinByPageMain extends PureComponent {
  static propTypes = {
    sellerSelect: SellerSelect.isRequired,
    user: User.isRequired,
    theme: Theme.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      marketplaceSelectedOption: {},
      marketplaceSelectOptions: [],
      asinSelectedOption: {},
      asinSelectOptions: [],
      dateSelectOptions: [],
      multi: false,
      optionsPayload: {},
      pieData: null,
      lineData: null,
      totalData: {},
      loadingData: true,
      dateSelectedOption: undefined,
      dateSecondSelectedOption: undefined,
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevState) {
    const { sellerSelect } = this.props;
    if (sellerSelect.value !== prevState.sellerSelect.value) {
      this.getData();
    }
  }

  getData = () => {
    const { user, sellerSelect } = this.props;
    const headerWithAuth = { authorization: `JWT ${user.jwtToken}` };


    fetch(`${apiUrl}/big-query/customer-asin-rstp-rank-options?sellerAccountId=${sellerSelect.value}&parentAccountId=${sellerSelect.parentAccountId}`, { headers: headerWithAuth })
      .then((resultsJtsrAsinOptions) => {
        if (resultsJtsrAsinOptions.status === 401) {
          history.push('/logout');
        }
        return resultsJtsrAsinOptions.json();
      })
      .then((data) => {
        const marketplaceSelectOptions = [];
        const asinSelectOptions = [];
        const dateSelectOptions = [];

        Object.keys(data).forEach((key) => {
          marketplaceSelectOptions.push({ label: key, value: key });
        });

        this.setState({
          optionsPayload: data,
          marketplaceSelectOptions,
          marketplaceSelectedOption: {},
          asinSelectOptions: [],
          asinSelectedOption: {},
          dateSelectOptions: [],
        });

        if (marketplaceSelectOptions.length > 0) {
          Object.keys(data[marketplaceSelectOptions[0].value]).forEach((key) => {
            asinSelectOptions.push({ label: `${key} - ${data[marketplaceSelectOptions[0].value][key].product_title || ''}`, value: key });
          });
          if (asinSelectOptions.length > 0) {
            Object.keys(data[marketplaceSelectOptions[0].value][asinSelectOptions[0].value].dates).forEach((key) => {
              const dateValue = data[marketplaceSelectOptions[0].value][asinSelectOptions[0].value].dates[key];
              dateSelectOptions.push({ label: moment(dateValue).format('YYYY-MM-DD'), value: dateValue });
            });
            if (dateSelectOptions.length > 0) {
              const dateSelectedOption = dateSelectOptions[0];
              const dateSecondSelectedOption = dateSelectOptions[dateSelectOptions.length - 1];
              this.setState({
                marketplaceSelectedOption: marketplaceSelectOptions[0],
                asinSelectOptions,
                asinSelectedOption: asinSelectOptions[0],
                dateSelectOptions,
                dateSelectedOption,
                dateSecondSelectedOption,
              });
            }
          }
        }
      }).catch((error) => {
        this.setState({
          loadingData: false,
        });
        console.log(error);
      });
  }

  handleMarketplaceChange = (selectedValue) => {
    const { optionsPayload, marketplaceSelectedOption } = this.state;
    const asinSelectOptions = [];
    const dateSelectOptions = [];
    let loadingData = false;
    if (marketplaceSelectedOption !== selectedValue) {
      loadingData = true;
    }

    Object.keys(optionsPayload[selectedValue.value]).forEach((key) => {
      asinSelectOptions.push({ label: `${key} - ${optionsPayload[selectedValue.value][key].product_title || ''}`, value: key });
    });
    if (asinSelectOptions.length > 0) {
      Object.keys(optionsPayload[selectedValue.value][asinSelectOptions[0].value].dates).forEach((key) => {
        const dateValue = optionsPayload[selectedValue.value][asinSelectOptions[0].value].dates[key];
        dateSelectOptions.push({ label: moment(dateValue).format('YYYY-MM-DD'), value: dateValue });
      });
      if (dateSelectOptions.length > 0) {
        const dateSelectedOption = dateSelectOptions[0];
        const dateSecondSelectedOption = dateSelectOptions[dateSelectOptions.length - 1];

        this.setState({
          marketplaceSelectedOption: selectedValue,
          asinSelectOptions,
          asinSelectedOption: asinSelectOptions[0],
          dateSelectOptions,
          dateSelectedOption,
          dateSecondSelectedOption,
          loadingData,
        });
      }
    }
  }

  handleAsinChange = (selectedValue) => {
    const { optionsPayload, marketplaceSelectedOption, asinSelectedOption } = this.state;
    const dateSelectOptions = [];
    let loadingData = false;
    if (asinSelectedOption !== selectedValue) {
      loadingData = true;
    }

    Object.keys(optionsPayload[marketplaceSelectedOption.value][selectedValue.value].dates).forEach((key) => {
      const dateValue = optionsPayload[marketplaceSelectedOption.value][selectedValue.value].dates[key];
      dateSelectOptions.push({ label: moment(dateValue).format('YYYY-MM-DD'), value: dateValue });
    });
    if (dateSelectOptions.length > 0) {
      const dateSelectedOption = dateSelectOptions[0];
      const dateSecondSelectedOption = dateSelectOptions[dateSelectOptions.length - 1];

      this.setState({
        asinSelectedOption: selectedValue,
        dateSelectOptions,
        dateSelectedOption,
        dateSecondSelectedOption,
        loadingData,
      });
    }
  }

  handleDateChange = (selectedValue) => {
    const { dateSelectedOption } = this.state;
    if (dateSelectedOption !== selectedValue) {
      this.setState({
        dateSelectedOption: selectedValue,
        loadingData: true,
      });
    }
  }

  handleSecondDateChange = (selectedValue) => {
    const { dateSecondSelectedOption } = this.state;
    if (dateSecondSelectedOption !== selectedValue) {
      this.setState({
        dateSecondSelectedOption: selectedValue,
        loadingData: true,
      });
    }
  }

  setLineData = (inLineData) => {
    const rankPull = 5;

    const defaultMap = new Map([
      [1, { name: 1, firstRank: 0, secondRank: 0 }],
      [2, { name: 2, firstRank: 0, secondRank: 0 }],
      [3, { name: 3, firstRank: 0, secondRank: 0 }],
      [4, { name: 4, firstRank: 0, secondRank: 0 }],
      [5, { name: 5, firstRank: 0, secondRank: 0 }],
    ]);

    let outLineData = [];

    if (inLineData.length > 0) {
      try {
        const outLineMap = inLineData.reduce((list, row) => {
          if (list.size === 0) {
            // eslint-disable-next-line
            list = defaultMap; // initalize the map
          }
          if (row.dateData[0].rstp_serp_organic_rank <= rankPull) {
            const secondObj = list.get(row.dateData[0].rstp_serp_organic_rank);
            if (secondObj) {
              secondObj.secondRank += 1;
              list.set(row.dateData[0].rstp_serp_organic_rank, secondObj);
            } else {
              const newSecondObj = {
                name: row.dateData[0].rstp_serp_organic_rank,
                secondRank: 1,
                firstRank: 0,
              };
              list.set(row.dateData[0].rstp_serp_organic_rank, newSecondObj);
            }
          }
          if (row.dateData[1] && row.dateData[1].rstp_serp_organic_rank <= rankPull) {
            const firstObj = list.get(row.dateData[1].rstp_serp_organic_rank);
            if (firstObj) {
              firstObj.firstRank += 1;
              list.set(row.dateData[1].rstp_serp_organic_rank, firstObj);
            } else {
              const newFirstObj = {
                name: row.dateData[1].rstp_serp_organic_rank,
                secondRank: 0,
                firstRank: 1,
              };
              list.set(row.dateData[1].rstp_serp_organic_rank, newFirstObj);
            }
          }
          return list;
        }, new Map());

        outLineData = Array.from(outLineMap.values());
      } catch (error) {
        console.log(error);
      }
    }
    const lineData = outLineData.sort((a, b) => {
      const checkPos = (a.name < b.name) ? -1 : 0;
      return (a.name > b.name) ? 1 : checkPos;
    });

    let loadingData = true;
    if (lineData.length > 0) {
      loadingData = false;
    }

    this.setState({
      lineData,
      loadingData,
    });
  }

  setPieData = (inPieData) => {
    const COLORS = ['#03c03c', '#36c9c9', '#fbb054', '#df7252', '#d04960', '#e85097', '#9a9a9a', '#e850e3'];

    let firstDate = '';
    let secondDate = '';
    let firstDateSearchVolumeTotal = 0;
    let secondDateSearchVolumeTotal = 0;
    let firstDateOrganicRankTotal = 0;
    let secondDateOrganicRankTotal = 0;
    let firstDateSearchTermCountTotal = 0;
    let secondDateSearchTermCountTotal = 0;
    const outPieData = [];
    if (inPieData) {
      try {
        // sort early so colors match
        const sortedPageData = inPieData.sort((a, b) => {
          const checkPos = (a.name < b.name) ? -1 : 0;
          return (a.name > b.name) ? 1 : checkPos;
        });

        sortedPageData.forEach((row) => {
          firstDate = row.dateData[1].report_date;
          secondDate = row.dateData[0].report_date;
          firstDateSearchVolumeTotal += (row.dateData[1].rstp_search_volume || 0);
          secondDateSearchVolumeTotal += (row.dateData[0].rstp_search_volume || 0);
          firstDateOrganicRankTotal += (row.dateData[1].rstp_serp_organic_rank_count || 0);
          secondDateOrganicRankTotal += (row.dateData[0].rstp_serp_organic_rank_count || 0);
          firstDateSearchTermCountTotal += (row.dateData[1].search_term_count || 0);
          secondDateSearchTermCountTotal += (row.dateData[0].search_term_count || 0);

          const rowData = {
            name: row.page,
            firstDate: (row.dateData[1].report_date || ''),
            secondDate: (row.dateData[0].report_date || ''),
            firstDateSearchVolume: (row.dateData[1].rstp_search_volume || 0),
            secondDateSearchVolume: (row.dateData[0].rstp_search_volume || 0),
            firstDateSearchTermCount: (row.dateData[1].search_term_count || 0),
            secondDateSearchTermCount: (row.dateData[0].search_term_count || 0),
            fill: COLORS[outPieData.length],
          };

          outPieData.push(rowData);
        });
      } catch (error) {
        console.log(error);
      }
    }

    const totalData = {
      firstDate,
      secondDate,
      firstDateSearchVolumeTotal,
      secondDateSearchVolumeTotal,
      firstDateOrganicRankTotal,
      secondDateOrganicRankTotal,
      firstDateSearchTermCountTotal,
      secondDateSearchTermCountTotal,
    };

    const pieData = outPieData.sort((a, b) => {
      const checkPos = (a.name < b.name) ? -1 : 0;
      return (a.name > b.name) ? 1 : checkPos;
    });
    let loadingData = true;
    if (pieData.length > 0) {
      loadingData = false;
    }

    this.setState({
      pieData,
      totalData,
      loadingData,
    });
  }


  render() {
    const {
      marketplaceSelectedOption, marketplaceSelectOptions, asinSelectedOption, asinSelectOptions, dateSelectOptions, multi,
      pieData, lineData, totalData, loadingData, dateSelectedOption, dateSecondSelectedOption,
    } = this.state;
    const { theme, sellerSelect } = this.props;
    const themeColors = style(theme, DROPDOWN_COLORS);

    let twoDates = '';
    if (dateSelectedOption && dateSecondSelectedOption) {
      twoDates = dateSelectedOption.value.concat(',', dateSecondSelectedOption.value);
    }

    return (
      <Container className="dashboard">
        <h3 className="page-title">ASIN Placement By Page</h3>
        <Row>
          <Col md={3}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Marketplace</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={marketplaceSelectedOption}
                    onChange={this.handleMarketplaceChange}
                    options={marketplaceSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themeColors.colorPrimary}`,
                        neutral0: `${themeColors.colorBackground}`,
                        neutral80: `${themeColors.colorText}`,
                      },
                    })}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">ASIN</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <Select
                    value={asinSelectedOption}
                    onChange={this.handleAsinChange}
                    options={asinSelectOptions}
                    theme={selectTheme => ({
                      ...selectTheme,
                      colors: {
                        ...selectTheme.colors,
                        primary: `${themeColors.colorPrimary}`,
                        neutral0: `${themeColors.colorBackground}`,
                        neutral80: `${themeColors.colorText}`,
                      },
                    })}
                    menuPlacement="auto"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <CardBody className="panel__body">
                {(loadingData) ? <div className="panel__refresh" /> : ''}
                <h3 className="page-title">Report Dates</h3>
                <div className="topbar__dynamicDropdownWidth">
                  <p>Date 1:
                    <Select
                      value={dateSelectedOption}
                      onChange={this.handleDateChange}
                      options={dateSelectOptions}
                      theme={selectTheme => ({
                        ...selectTheme,
                        colors: {
                          ...selectTheme.colors,
                          primary: `${themeColors.colorPrimary}`,
                          neutral0: `${themeColors.colorBackground}`,
                          neutral80: `${themeColors.colorText}`,
                          neutral10: `${themeColors.colorPrimary}`,
                        },
                      })}
                      isMulti={multi}
                    />
                  </p>
                  <p>Date 2:
                    <Select
                      value={dateSecondSelectedOption}
                      onChange={this.handleSecondDateChange}
                      options={dateSelectOptions}
                      theme={selectTheme => ({
                        ...selectTheme,
                        colors: {
                          ...selectTheme.colors,
                          primary: `${themeColors.colorPrimary}`,
                          neutral0: `${themeColors.colorBackground}`,
                          neutral80: `${themeColors.colorText}`,
                          neutral10: `${themeColors.colorPrimary}`,
                        },
                      })}
                      isMulti={multi}
                    />
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <DeltaCompare
            content={{
              title: 'Count Phrases',
              left_header: moment(totalData.firstDate).format('MMMM D, YYYY'),
              left_data: formatNumberDefaultToZero({ value: totalData.firstDateSearchTermCountTotal }),
              middle_header: 'Delta',
              middle_data: totalData.secondDateSearchTermCountTotal - totalData.firstDateSearchTermCountTotal,
              right_header: moment(totalData.secondDate).format('MMMM D, YYYY'),
              right_data: formatNumberDefaultToZero({ value: totalData.secondDateSearchTermCountTotal }),
            }}
            loadingData={loadingData}
          />
          <DeltaCompare
            content={{
              title: 'Search Volume',
              left_header: moment(totalData.firstDate).format('MMMM D, YYYY'),
              left_data: formatNumberDefaultToZero({ value: totalData.firstDateSearchVolumeTotal }),
              middle_header: 'Delta',
              middle_data: totalData.secondDateSearchVolumeTotal - totalData.firstDateSearchVolumeTotal,
              right_header: moment(totalData.secondDate).format('MMMM D, YYYY'),
              right_data: formatNumberDefaultToZero({ value: totalData.secondDateSearchVolumeTotal }),
            }}
            loadingData={loadingData}
          />
        </Row>
        <Row>
          <PieBreakdown
            data={pieData}
            content={{
              title: 'Total number of Organic Search Term Phrases by page',
              subtitle: moment(totalData.secondDate).format('MMMM D, YYYY'),
              outerDataKey: 'secondDateSearchVolume',
              innerDataKey: 'secondDateSearchTermCount',
            }}
            loadingData={loadingData}
          />
          <HorizontalTwoBar
            data={lineData}
            content={{
              title: 'Top Search Terms Position',
              subtitle: 'by rank',
              dataKey: 'name',
              firstDataKey: 'firstRank',
              secondDataKey: 'secondRank',
              firstTitle: moment(totalData.firstDate).format('MMMM D, YYYY'),
              secondTitle: moment(totalData.secondDate).format('MMMM D, YYYY'),
            }}
            loadingData={loadingData}
          />
        </Row>
        {/* <Row>
          <JtsrAsinByPageTotal
            data={pieData}
            subtitle="First Date "
            reportDate={totalData.firstDate}
            organicRankTotal={totalData.firstDateOrganicRankTotal}
            searchVolumeTotal={totalData.firstDateSearchVolumeTotal}
            searchVolumeKey="firstDateSearchVolume"
            stCountKey="firstDateSearchTermCount"
          />
          <JtsrAsinByPageTotal
            data={pieData}
            subtitle="Second Date "
            reportDate={totalData.secondDate}
            organicRankTotal={totalData.secondDateOrganicRankTotal}
            searchVolumeTotal={totalData.secondDateSearchVolumeTotal}
            searchVolumeKey="secondDateSearchVolume"
            stCountKey="secondDateSearchTermCount"
          />
        </Row> */}
        {asinSelectedOption && marketplaceSelectedOption && (
          <Row>
            <JtsrAsinByPage
              asin={asinSelectedOption.value}
              marketplace={marketplaceSelectedOption.value}
              selectedDates={twoDates}
              sellerSelect={sellerSelect}
              setPageData={this.setPieData}
            />
          </Row>
        )}
        {asinSelectedOption && marketplaceSelectedOption && (
          <Row>
            <JtsrSearchTermRank
              asin={asinSelectedOption.value}
              marketplace={marketplaceSelectedOption.value}
              selectedDates={twoDates}
              sellerSelect={sellerSelect}
              setPageData={this.setLineData}
            />
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme, sellerSelect } = state;
  const { user } = state.authentication;

  return {
    theme,
    user,
    sellerSelect,
  };
};

export default connect(mapStateToProps)(JtsrAsinByPageMain);
