import React from 'react';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import PropTypes from 'prop-types';
import AuthorizeForm from './AuthorizeForm';
import { SiteName, getCorprateUrl, defaultAgencyCode as DEFAULT_AGENCY } from '../../../shared/components/domainSupport';

const AuthorizeAdApi = ({ agencyCode, apiType }) => (
  <Col md={12}>
    <Card>
      <Row>
        <Col md={5}>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Authorizing Advertising API access for {SiteName()}</h5>
            </div>
            <AuthorizeForm agencyCode={agencyCode} apiType={apiType} nameMsg="" />
          </CardBody>
        </Col>
        <Col md={5}>
          <CardBody>
            <br />
            <br />
            <p>PLEASE NOTE:<br /><br /></p>
            <blockquote><p>By Clicking &apos;Authorize&apos;, you agree to our <a href={`${getCorprateUrl()}/terms-of-use`} target="blank">Terms &amp; Conditions</a></p></blockquote>
            <br />
            <blockquote><p>After you click &apos;Authorize&apos;, you will be directed to Amazon to complete the authorization.  When you are finished, Amazon will redirect back to {SiteName()} where you will recieve a success message.</p></blockquote>
          </CardBody>
        </Col>
      </Row>
    </Card>
  </Col>
);

AuthorizeAdApi.propTypes = {
  agencyCode: PropTypes.string,
  apiType: PropTypes.string.isRequired,
};

AuthorizeAdApi.defaultProps = {
  agencyCode: DEFAULT_AGENCY,
};

export default AuthorizeAdApi;
