import React from 'react';
import PropTypes from 'prop-types';
import 'ag-charts-enterprise';
import 'ag-charts-community';
import { AgChartsReact } from 'ag-charts-react';

// Add when we get a license
// LicenseManager.setLicenseKey(config.agGridKey);

const ChartBuilder = ({
  chartOptions,
}) => (
  <AgChartsReact options={chartOptions} />
);


ChartBuilder.propTypes = {
  chartOptions: PropTypes.shape().isRequired,
};

export default ChartBuilder;
