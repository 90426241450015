import {
  currencyFormatter, formatDecimalToFixedTwoPercent,
} from '../../../../../shared/components/table/functions';
import { imageFieldColumn } from '../../../../../shared/components/table/ImageCellRenderer';

function compareNumber(valueA, valueB) {
  return valueA - valueB;
}

function compareString(valueA, valueB) {
  if (valueA === valueB) return 0;
  return (valueA > valueB) ? 1 : -1;
}

export const columnDefs = [
  imageFieldColumn,
  {
    field: 'product_ad_asin',
    headerName: 'ASIN',
    headerTooltip: 'Amazon Standard Identification Number. The unique identifier given to all products sold on Amazon.',
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'brand',
    headerName: 'Brand',
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'product_title',
    headerName: 'Product Title',
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'category',
    headerName: 'Category',
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'subcategory',
    headerName: 'Subcategory',
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'segment_one',
    headerName: 'Segment 1',
    valueGetter: function calcSegmentValueGetter(params) {
      if (params && params.data && params.data.segment_one) {
        return params.data.segment_one.trim();
      }
      return '';
    },
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'segment_two',
    headerName: 'Segment 2',
    valueGetter: function calcSegmentValueGetter(params) {
      if (params && params.data && params.data.segment_two) {
        return params.data.segment_two.trim();
      }
      return '';
    },
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'segment_three',
    headerName: 'Segment 3',
    valueGetter: function calcSegmentValueGetter(params) {
      if (params && params.data && params.data.segment_three) {
        return params.data.segment_three.trim();
      }
      return '';
    },
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'segment_four',
    headerName: 'Segment 4',
    valueGetter: function calcSegmentValueGetter(params) {
      if (params && params.data && params.data.segment_four) {
        return params.data.segment_four.trim();
      }
      return '';
    },
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'segment_five',
    headerName: 'Segment 5',
    valueGetter: function calcSegmentValueGetter(params) {
      if (params && params.data && params.data.segment_five) {
        return params.data.segment_five.trim();
      }
      return '';
    },
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'parent_asin',
    headerName: 'Parent ASIN',
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'number_of_campaigns',
    headerName: '# Of Campaigns',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'spend',
    headerName: 'PPC Spend',
    valueFormatter: currencyFormatter,
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'sales',
    headerName: 'PPC Sales',
    valueFormatter: currencyFormatter,
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'orders',
    headerName: 'Orders',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'units',
    headerName: 'Units',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  // {
  //   field: 'aov',
  //   headerName: 'AOV',
  //   valueFormatter: currencyFormatter,
  //   headerTooltip: 'Average Order Value',
  //   valueGetter: function calcValueGetter(params) {
  //     console.log('Value Getter params', params);
  //     if (params && params.data && params.data.sales && params.data.orders && params.data.orders > 0) {
  //       return params.data.sales / params.data.orders;
  //     }
  //     return 0;
  //   },
  //   sortable: true,
  //   comparator: (valueA, valueB) => compareNumber(valueA, valueB),
  //   resizable: true,
  // },
  {
    field: 'CALCPacos',
    headerName: 'PPC ACoS',
    valueFormatter: formatDecimalToFixedTwoPercent,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.spend && params.data.sales && params.data.sales !== 0) {
        return params.data.spend / params.data.sales;
      }
      return 0;
    },
    headerTooltip: 'PPC ACoS',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'CALCCpc',
    headerName: 'CPC',
    valueFormatter: currencyFormatter,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.spend && params.data.clicks && params.data.clicks !== 0) {
        return params.data.spend / params.data.clicks;
      }
      return 0;
    },
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'CR',
    headerName: 'Conv. Rate',
    valueFormatter: formatDecimalToFixedTwoPercent,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.orders && params.data.clicks && params.data.clicks !== 0) {
        return params.data.orders / params.data.clicks;
      }
      return 0;
    },
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'roas',
    headerName: 'PPC ROAS',
    valueFormatter: currencyFormatter,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.sales && params.data.spend && params.data.spend !== 0) {
        return params.data.sales / params.data.spend;
      }
      return 0;
    },
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'CALCSpendPercentOfTotal',
    headerName: 'Spend % of total',
    valueFormatter: formatDecimalToFixedTwoPercent,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.spend && params.data.total_spend && params.data.total_spend !== 0) {
        return (params.data.spend / params.data.total_spend);
      }
      return 0;
    },
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  // TODO: add logic that is able to use valueGetter Sum PPC Units/Shippped Unit calculation in Pivot table.
  // {
  //   field: 'sourcing_shipped_units',
  //   headerName: 'Sourcing Shipped Units',
  //   aggFunc: 'sum',
  // },
];

export const simpleColumnDefs = [
  imageFieldColumn,
  {
    field: 'product_ad_asin',
    headerName: 'ASIN',
    headerTooltip: 'Amazon Standard Identification Number. The unique identifier given to all products sold on Amazon.',
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'brand',
    headerName: 'Brand',
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'product_title',
    headerName: 'Product Title',
    sortable: true,
    comparator: (valueA, valueB) => compareString(valueA, valueB),
    resizable: true,
  },
  {
    field: 'number_of_campaigns',
    headerName: '# Of Campaigns',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'impressions',
    headerName: 'Impressions',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'spend',
    headerName: 'PPC Spend',
    valueFormatter: currencyFormatter,
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'sales',
    headerName: 'PPC Sales',
    valueFormatter: currencyFormatter,
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'orders',
    headerName: 'Orders',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'units',
    headerName: 'Units',
    aggFunc: 'sum',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'CALCPacos',
    headerName: 'PPC ACoS',
    valueFormatter: formatDecimalToFixedTwoPercent,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.spend && params.data.sales && params.data.sales !== 0) {
        return params.data.spend / params.data.sales;
      }
      return 0;
    },
    headerTooltip: 'PPC ACoS',
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'CALCCpc',
    headerName: 'CPC',
    valueFormatter: currencyFormatter,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.spend && params.data.clicks && params.data.clicks !== 0) {
        return params.data.spend / params.data.clicks;
      }
      return 0;
    },
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'CR',
    headerName: 'Conv. Rate',
    valueFormatter: formatDecimalToFixedTwoPercent,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.orders && params.data.clicks && params.data.clicks !== 0) {
        return params.data.orders / params.data.clicks;
      }
      return 0;
    },
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'roas',
    headerName: 'PPC ROAS',
    valueFormatter: currencyFormatter,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.sales && params.data.spend && params.data.spend !== 0) {
        return params.data.sales / params.data.spend;
      }
      return 0;
    },
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
  {
    field: 'CALCSpendPercentOfTotal',
    headerName: 'Spend % of total',
    valueFormatter: formatDecimalToFixedTwoPercent,
    valueGetter: function calcValueGetter(params) {
      if (params && params.data && params.data.spend && params.data.total_spend && params.data.total_spend !== 0) {
        return (params.data.spend / params.data.total_spend);
      }
      return 0;
    },
    sortable: true,
    comparator: (valueA, valueB) => compareNumber(valueA, valueB),
    resizable: true,
  },
];

export const defaultColumnDefs = {
  filter: true,
  resizable: true,
  cellStyle: (params) => {
    if (params.value < 0.00) {
      return { color: 'red' };
    }
    return null;
  },
};

export const autoGroupColumnDefs = {
  headerName: ' Breakdown ',
  cellRendererParams: {
    suppressCount: true,
  },
};
