import { useFetchReturnsArray as useFetch } from './useFetch';
import config from '../../../configWrapper';

function formatUrl(account, start, end, view, set) {
  if (account && account.value && start && end && view && set) {
    const url = `${config.serverUrl}/customers/account-over-time-totals?sellerAccountId=${account.value}&parentAccountId=${account.parentAccountId}&startDate=${start.format('YYYYMMDD')}&endDate=${end.format('YYYYMMDD')}&dataGroup=${view.value}&dataSet=${set.value}`;
    return url;
  }
  return undefined;
}

export default function useAccountOverTime(account, start, end, view, set) {
  return useFetch(formatUrl(account, start, end, view, set));
}
