import React, { PureComponent } from 'react';
import {
  Card, Col,
} from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Theme } from '../../../shared/prop-types/MainProps';
import config from '../../../config';
import { SiteName, isLimitless, getDashboardUrl } from '../../../shared/components/domainSupport';
import style, { DROPDOWN_COLORS } from '../../../shared/components/themeSupport';
import LoadingSpinner from '../../../shared/components/widgets/LoadingSpinner';
import { countryOptionsArray } from './OnboardingConfig';

const apiUrl = config.isProdEnv ? config.serverProdUrl : config.serverDevUrl;

class OnboardingStatus extends PureComponent {
  static propTypes = {
    theme: Theme.isRequired,
    accountTypeName: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      statusStep: 'checking_amazon',
      statusMessage: '',
      profiles: [],
      authApiLog: [],
      submitted: false,
      state: null,
      selectedAccount: { label: 'Please Select an Account...', key: 'blank', value: '' },
      edata: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramCode = urlParams.get('code');
    const paramState = urlParams.get('state');

    this.setState({ state: paramState });

    if (paramCode && paramState) {
      const authGrantUrl = `${apiUrl}/onboarding/auth-ad-api?code=${paramCode}&state=${paramState}&site=${SiteName()}`;

      fetch(authGrantUrl)
        .then((results) => {
          if (!results.ok) {
            throw results;
          }
          return results.json();
        }).then((data) => {
          this.setState({
            statusStep: 'account_select',
            statusMessage: 'To finish your authorization, please select the account you are onboarding.',
            profiles: data.profiles,
            authApiLog: JSON.parse(data.authApiLog),
          });
        }).catch((error) => {
          try {
            error.json().then((data) => {
              this.setState({
                statusStep: 'amamzon_grant_failure',
                statusMessage: JSON.stringify(data),
              });
            }).catch(() => {
              this.setState({
                statusStep: 'amamzon_grant_failure',
                statusMessage: `Generic Error: ${error}`,
              });
            });
          } catch (err) {
            this.setState({
              statusStep: 'amamzon_grant_failure',
              statusMessage: `Generic Error: ${err}`,
            });
          }
        });
    } else {
      this.setState({
        statusStep: 'amazon_parameter_failure',
        statusMessage: 'Parameters missing or incorrect.',
      });
    }
  }

  handleChange = (selectedAccount) => {
    this.setState({ selectedAccount });
  }

  handleSubmit(e) {
    e.preventDefault();

    const {
      profiles, state, selectedAccount,
    } = this.state;

    if (selectedAccount && selectedAccount.value !== '') {
      this.setState({ submitted: true });
      const accountData = profiles.filter(profile => profile.profileId.toString() === selectedAccount.value.toString());

      const requestOptions = {
        method: 'POST',
        headers: config.jsonHeader,
        body: JSON.stringify({ state, accountData }),
      };

      const postUrl = `${apiUrl}/onboarding/auth-ad-api-complete`;

      fetch(postUrl, requestOptions)
        .then((results) => {
          if (!results.ok) {
            throw results;
          }
          return results.json();
        }).then((data) => {
          this.setState({
            submitted: false,
            statusStep: 'success',
            edata: data.spApiParams,
            statusMessage: data.statusMessage,
          });
        }).catch((error) => {
          error.json().then((data) => {
            this.setState({
              submitted: false,
              statusStep: 'select_failure',
              statusMessage: data.statusMessage,
            });
          }).catch(() => {
            this.setState({
              submitted: false,
              statusStep: 'select_failure',
              statusMessage: 'Generic Error',
            });
          });
        });
    }
  }

  displayError = (errorString) => {
    try {
      const errorJson = JSON.parse(errorString);
      if (errorJson.code) {
        return `${errorJson.code} - ${Buffer.from(errorString).toString('base64')}`;
      }
      if (errorJson.statusMessage) {
        return `${errorJson.statusMessage} - ${Buffer.from(errorString).toString('base64')}`;
      }
      return Buffer.from(errorString).toString('base64');
    } catch {
      return Buffer.from(errorString).toString('base64');
    }
  }

  render() {
    const {
      statusStep, statusMessage, profiles, submitted, selectedAccount, authApiLog, edata,
    } = this.state;
    const { theme, accountTypeName } = this.props;
    const themeColors = style(theme, DROPDOWN_COLORS);

    const onboardingCountryJson = countryOptionsArray.filter((countryData) => {
      if (Object.keys(authApiLog).length > 0) {
        return countryData.marketplaceId === authApiLog.marketplace_id;
      }
      return false;
    });

    const optionItems = profiles.filter(profile => profile.accountInfo.type.toLowerCase() === accountTypeName.toLowerCase() && profile.countryCode.toUpperCase() === (onboardingCountryJson[0].label || '').toUpperCase()).map(profile => (
      {
        label: `${profile.accountInfo.name} (${profile.accountInfo.id}) - ${profile.countryCode}`,
        key: profile.profileId,
        value: profile.profileId,
      }
    ));
    optionItems.unshift({ label: `Please Select a ${accountTypeName} Account...`, key: 'blank', value: '' });

    return (
      <Col md={12} lg={12} xl={8}>
        <Card>
          <div className="profile__card tabs tabs--bordered-bottom">
            <div className="tabs__wrap">
              {statusStep === 'success' && (
                <div className="card-body">
                  <h3>You have successfully completed the Advertising authorization process.</h3>
                  <p>Our team now has access to launch Ads for you, now we need to get access to more data to help us launch those Ads and report results to you.</p>
                  <p><a href={`${getDashboardUrl()}/onboarding/sp-api/start?edata=${edata}`}><button type="button" className="btn btn-primary btn-lg">Link Selling Parter API</button></a></p>
                  <p>or copy it for later:</p>
                  <p>{`${getDashboardUrl()}/onboarding/sp-api/start?edata=${edata}`}</p>
                  <br />
                  <p>{statusMessage}</p>
                </div>
              )}
              {statusStep === 'account_select' && (
                <div className="card-body">
                  <h3>You are almost there, you just need to select which account you are authorizing.</h3>
                  <br />
                  <br />
                  <p>{statusMessage}</p>
                  <br />
                  <form className="form" onSubmit={this.handleSubmit}>
                    <div className="form__form-group">
                      <Select
                        value={selectedAccount}
                        onChange={this.handleChange}
                        options={optionItems}
                        theme={selectTheme => ({
                          ...selectTheme,
                          colors: {
                            ...selectTheme.colors,
                            primary: `${themeColors.colorPrimary}`,
                            neutral0: `${themeColors.colorBackground}`,
                            neutral80: `${themeColors.colorText}`,
                          },
                        })}
                      />
                      {submitted && !selectedAccount && (
                        <div className="form__form-group-error">Account Selection is required</div>
                      )}
                    </div>
                    <br /><br /><br /><br /><br /><br />
                    <br /><br /><br /><br /><br /><br />
                    <div className="form__form-group">
                      {submitted && (
                        <LoadingSpinner
                          active={submitted}
                        />
                      )}
                    </div>
                    <div>
                      <div className="form__form-group">
                        <button className="btn btn-primary account__btn" type="submit">Finishing Authorizing Selected Account</button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              {statusStep === 'checking_amazon' && (
                <div className="card-body">
                  <p>Performing authorization of your amazon account with {SiteName()}.  This page will get updated when the authorization is complete.</p>
                  <br />
                  <br />
                  <div style={{ textAlign: 'center', width: '100%' }}>
                    <LoadingSpinner
                      active={statusStep === 'checking_amazon'}
                    />
                  </div>
                </div>
              )}
              {(statusStep === 'amazon_parameter_failure' || statusStep === 'amamzon_grant_failure' || statusStep === 'select_failure') && (
                <div className="card-body">
                  <p>There was an error with the data Amazon passed back to {SiteName()}.  Please try again.</p>
                  {isLimitless() && (
                    <p> If this is the second time you hit this error, please contact us at info&#64;limitless.tech</p>
                  )}
                  <br />
                  <br />
                  <a href="/onboarding"><button type="button" className="btn btn-primary btn-lg">Onboarding Home</button></a>
                  <br />
                  <br />
                  <p> Please copy/paste the refrence error below to help identify any onboarding issues.</p>
                  <p> <b>Reference error:</b> {this.displayError(statusMessage)}</p>
                  <br />
                  <p> - Your {SiteName()} Team</p>
                  <br />
                </div>
              )}
            </div>
          </div>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  const { theme } = state;

  return {
    theme,
  };
};


export default connect(mapStateToProps)(OnboardingStatus);
